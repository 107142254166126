import get from "lodash.get";
import React from "react";

import { Button, Form } from "@au-re/mosaik-elements";
import { passwordMatch } from "../../../helpers/validation";

const hasRequiredValues = ({ values }: any) => values.password && values.repeatPassword;

const ResetPasswordForm = (props: any) => (
  <Form id="reset-password-form" autoComplete="off" {...props}>
    {({ formState }: any) => (
      <>
        <Form.Label htmlFor="password">New Password</Form.Label>
        <Form.Text
          withErrorMessage
          fluid
          icon="lock"
          field="password"
          id="password"
          type="password"
          placeholder="please enter a password"
          errors={get(formState, "errors.password")}
          validateOnChange
          validate={passwordMatch("repeatPassword")}
          notify={["repeatPassword"]}
        />
        <Form.Label htmlFor="repeatPassword">Repeat Password</Form.Label>
        <Form.Text
          withErrorMessage
          fluid
          icon="lock"
          type="password"
          field="repeatPassword"
          id="repeatPassword"
          errors={get(formState, "errors.repeatPassword")}
          validateOnChange
          validate={passwordMatch("password")}
          notify={["password"]}
          placeholder="please repeat your password"
        />
        <Button
          disabled={formState.invalid || !hasRequiredValues(formState)}
          style={{ width: "288px", maxWidth: "100%", margin: "0 auto" }}
          nature="primary"
          type="submit"
        >
          Reset
        </Button>
      </>
    )}
  </Form>
);

export default ResetPasswordForm;
