import React from "react";
import { Dimmer, Loader } from "@au-re/mosaik-elements";

import noop from "lodash.noop";
import { useGraphData } from "@au-re/weld";

import useMosaikConnection from "../../../../hooks/useMosaikConnection";
import useRemoteAuth from "../../../../hooks/useRemoteAuth";
import { onGraphState } from "../../../../state-management/selectors/graph.selectors";
import { Iframe, DimmerContainer } from "../../../elements/Tile/Tile.styles";

/**
 * The Tile component wraps the iframe with the module code.
 * It manages the communication to and from the tile.
 *
 * @param {*} props
 */
function IframeTile(props: any) {
  const { id, projectId, dimmed } = props;
  const { onConnection = noop, onPublish = noop, onStateChange = noop } = props;

  const iframe = React.useRef(null);
  const [loading, setLoading] = React.useState(true);
  const [url, setURL] = React.useState("");

  // when our iframe receives data
  const onData = (data: any) => {
    if (data.url) {
      setURL(data.url);
    }
  };

  const { setAuth } =
    useMosaikConnection(iframe.current, onConnection, onPublish, onStateChange);

  useGraphData(id, onData, onGraphState);

  useRemoteAuth(id, setAuth, projectId);

  return (
    <DimmerContainer dimmed>
      <Dimmer inverted active={loading || dimmed} />
      <Loader active={loading} />
      <Iframe
        onLoad={() => setLoading(false)}
        ref={iframe}
        sandbox="allow-same-origin allow-scripts allow-forms"
        title="tile"
        src={url}
      />
    </DimmerContainer>
  );
}

export default IframeTile;
