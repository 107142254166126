import { Page, Container, Loader } from "@au-re/mosaik-elements";
import React from "react";

function PagePlaceholder(props: any) {
  return (
    <Page {...props}>
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <Loader
          style={{ alignSelf: "center" }}
          active
          indeterminate
        >
          mounting
        </Loader>
      </Container>
    </Page>
  );
}

export default PagePlaceholder;
