import React from "react";
import { Header, Button, colors } from "@au-re/mosaik-elements";
import { Link, NavLink } from "react-router-dom";
import { isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";

import { makeLocation } from "../../../helpers/utils";
import paths from "../../../constants/paths";
import UserAvatar from "../UserAvatar/UserAvatar";
import { getAuth } from "../../../state-management/selectors/auth.selectors";
import { getCurrentUser } from "../../../state-management/selectors/user.selectors";
import assetTypes from "../../../constants/types/assetTypes";

const activeStyle = {
  opacity: 1,
  color: colors.font,
  borderBottom: "2px solid #f26d6f",
};

const ProjectLink = (props: any) =>
  (
    <Header.Item
      exact
      activeStyle={activeStyle}
      as={NavLink}
      to={makeLocation(paths.PROJECTS, { assetType: assetTypes.PROJECTS })}
      inverted={props.inverted ? 1 : 0}
    >
      Projects
    </Header.Item>
  );

const TileLink = (props: any) =>
  (
    <Header.Item
      exact
      activeStyle={activeStyle}
      as={NavLink}
      to={makeLocation(paths.TILES, { assetType: assetTypes.TILES })}
      inverted={props.inverted ? 1 : 0}
    >
      Tiles
    </Header.Item>
  );

const DocsLink = (props: any) =>
  (
    <Header.Item
      exact
      activeStyle={activeStyle}
      as="a"
      href={paths.DOCUMENTATION_URL}
      inverted={props.inverted ? 1 : 0}
    >
      Docs
    </Header.Item>
  );

const InternalHeader = (props: any) => {
  const { inverted, user, title = "mosaik" } = props;
  return (
    <Header style={{ marginTop: "1rem" }}>
      <Header.Title inverted={inverted ? 1 : 0}>{title}</Header.Title>
      <Header.Menu>
        <ProjectLink inverted={inverted} />
        <TileLink inverted={inverted} />
        <DocsLink inverted={inverted} />
        <Header.Separator />
        <UserAvatar user={user} />
      </Header.Menu>
    </Header>
  );
};

const InternalEditorHeader = (props: any) => {
  const { inverted, user, goToEditor, title = "mosaik" } = props;
  return (
    <Header style={{ margin: "1rem 0" }}>
      <Header.Title inverted={inverted ? 1 : 0}>{title}</Header.Title>
      <Header.Menu>
        <ProjectLink inverted={inverted} />
        <TileLink inverted={inverted} />
        <DocsLink inverted={inverted} />
        <Header.Separator />
        <Button primary onClick={goToEditor}>Editor</Button>
        <UserAvatar user={user} />
      </Header.Menu>
    </Header>
  );
};

const PublicHeader = (props: any) => {
  const { inverted, title = "mosaik" } = props;
  return (
    <Header style={{ marginTop: "1rem" }}>
      <Header.Title inverted={inverted ? 1 : 0}>{title}</Header.Title>
      <Header.Menu>
        <ProjectLink inverted={inverted} />
        <TileLink inverted={inverted} />
        <DocsLink inverted={inverted} />
        <Header.Separator />
        <Link to={paths.SIGN_IN}>
          <Header.Item inverted={inverted ? 1 : 0}>Sign In</Header.Item>
        </Link>
        <Link to={paths.REGISTER}>
          <Button primary>Sign Up</Button>
        </Link>
      </Header.Menu>
    </Header>
  );
};

function MosaikHeader(props: any) {
  const { withEditor = false, ...rest } = props;

  const auth = useSelector(getAuth);
  const user = useSelector(getCurrentUser);

  if (!isEmpty(auth) && withEditor) return <InternalEditorHeader {...rest} user={user} />;
  if (!isEmpty(auth)) return <InternalHeader {...rest} user={user} />;
  return <PublicHeader {...rest} />;
}

export default MosaikHeader;
