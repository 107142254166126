import * as Sentry from "@sentry/browser";
import React, { Component } from "react";
import { Button, Message } from "@au-re/mosaik-elements";


class ErrorBoundary extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = { error: this.props.error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => { scope.setExtra(key, errorInfo[key]); });
      Sentry.captureException(error);
    });
  }

  clear() {
    this.setState({ error: null });
  }

  render() {
    if (this.state.error) {
      return (
        <Message negative>
          <Message.Header>Something went wrong</Message.Header>
          {this.props.message && <p>{this.props.message}</p>}
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2rem" }}>
            <Button size="small" onClick={() => Sentry.showReportDialog()}>Report feedback</Button>
            <Button size="small" onClick={() => this.clear()}>Retry</Button>
          </div>
        </Message>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
