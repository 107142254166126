import { lifeCycleTriggers } from "@au-re/weld";

import actionTypes from "./actionTypes";
import nodeDataTypes, { nodeDataTypesControls } from "./nodeDataTypes";
import integratedTiles from "./integratedTiles";

const nodeTypes = {
  LIFE_CYCLE: "LIFE_CYCLE",
  BASE_NODE: "BASE_NODE",
  CUSTOM_NODE: "CUSTOM_NODE",

  // node types for built-in lifecycle triggers
  [lifeCycleTriggers.ON_LOAD]: lifeCycleTriggers.ON_LOAD,

  // node types for built-in actions
  [actionTypes.HTTP_REQUEST_ACTION]: actionTypes.HTTP_REQUEST_ACTION,

  // node types for built-in tiles
  [integratedTiles.IFRAME_TILE]: integratedTiles.IFRAME_TILE,
};

const httpRequestNode = {
  icon: "play",
  name: "HTTP Request",
  fields: [
    {
      id: "mosaik_io",
      name: "Run",
      dataType: nodeDataTypes.object,
      input: true,
    },
    {
      id: "url",
      name: "url",
      dataType: nodeDataTypes.string,
      controlType: nodeDataTypesControls[nodeDataTypes.string].controlType,
      controlProps: nodeDataTypesControls[nodeDataTypes.string].controlProps,
      input: true,
    },
    {
      id: "method",
      name: "method",
      dataType: nodeDataTypes.string,
      controlType: nodeDataTypesControls[nodeDataTypes.string].controlType,
      controlProps: nodeDataTypesControls[nodeDataTypes.string].controlProps,
      input: true,
    },
    {
      id: "parameters",
      name: "parameters",
      dataType: nodeDataTypes.string,
      input: true,
    },
    {
      id: "headers",
      name: "headers",
      dataType: nodeDataTypes.string,
      input: true,
    },
    {
      id: "body",
      name: "body",
      dataType: nodeDataTypes.object,
      input: true,
    },
    {
      id: "response",
      name: "response",
      dataType: nodeDataTypes.object,
      output: true,
    },
  ],
};

const embeddedWebsiteNode = {
  icon: "world",
  name: "Embedded Website",
  fields: [
    {
      id: "url",
      name: "url",
      dataType: nodeDataTypes.string,
      input: true,
    },
  ],
};

const onProjectLoadNode = {
  icon: "play",
  name: "On Project Load",
  fields: [
    {
      id: "mosaik_io",
      name: "Run",
      dataType: nodeDataTypes.object,
      output: true,
    },
  ],
};

export const baseNode = {
  fields: [
    {
      id: "mosaik_io",
      name: "Run",
      dataType: nodeDataTypes.object,
      input: true,
    },
    {
      id: "mosaik_io",
      name: "Run",
      dataType: nodeDataTypes.object,
      output: true,
    },
  ],
};

export const nodeTypeDefaults: any = {
  [nodeTypes[lifeCycleTriggers.ON_LOAD]]: onProjectLoadNode,
  [nodeTypes[actionTypes.HTTP_REQUEST_ACTION]]: httpRequestNode,
  [nodeTypes[integratedTiles.IFRAME_TILE]]: embeddedWebsiteNode,
};

export default nodeTypes;
