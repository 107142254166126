import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import get from "lodash.get";

import { Button, Form, Checkbox } from "@au-re/mosaik-elements";
import { exists, isValidEmail } from "../../../helpers/validation";
import paths from "../../../constants/paths";

const hasRequiredValues = ({ values }: any) => values.password && values.email;

// TODO: fix this in elements
const SignInCheckbox: any = Checkbox;
const FormLink: any = Form.Link;

const SignInForm = ({ loading, ...rest }: any) =>
  (
    <Form id="signin-form" {...rest}>
      {
        ({ formState }: any) => {
          return (
            <>
              <Form.Label htmlFor="email">Email</Form.Label>
              <Form.Text
                withErrorMessage
                fluid
                icon="mail"
                field="email"
                id="email"
                placeholder="please enter your email"
                errors={get(formState, "errors.email")}
                validateOnChange
                validate={isValidEmail}
              />
              <Form.Label htmlFor="password">Password</Form.Label>
              <Form.Text
                withErrorMessage
                fluid
                icon="lock"
                type="password"
                field="password"
                id="password"
                placeholder="please enter your password"
                errors={get(formState, "errors.password")}
                validateOnChange
                validate={exists}
              />
              <Form.Section>
                <SignInCheckbox field="remember" id="remember" label="Remember me" />
                <FormLink id="forgot" to={paths.REQUEST_NEW_PASSWORD} as={Link}>
                  Forgot Password?
                </FormLink>
              </Form.Section>
              <Button
                loading={loading}
                disabled={formState.invalid || !hasRequiredValues(formState)}
                style={{ width: "288px", maxWidth: "100%", margin: "0 auto" }}
                primary
                type="submit"
              >
                Sign in
              </Button>
              <Form.Section id="register">
                or
                <FormLink to={paths.REGISTER} as={Link}>
                  Register Now
                </FormLink>
              </Form.Section>
            </>
          );
        }
      }
    </Form>
  );

export default styled(SignInForm)`
  #forgot {
    float: right;
  }
  #register {
    text-align: center;
    margin: 1rem 0;
  }
  #remember-label {
    margin-left: 4px;
  }
`;
