import React from "react";
import { PageHeader, ImageUpload, TextField, Icon, Button } from "@au-re/mosaik-elements";
import { useSelector } from "react-redux";

import UserAvatar from "../../../../elements/UserAvatar/UserAvatar";
import useNavigation from "../../../../../hooks/useNavigation";
import { uploadAssetThumbnail, updateAssetTitle } from "../../../../../state-management/requests/asset.requests";
import ShareAssetModal from "../../../../modals/ShareAssetModal/ShareAssetModal";
// import { publishTile } from "../../../../../state-management/requests/tiles.requests";
// import { getTileEditorSource } from "../../../../../state-management/selectors/tileEditor.selectors";
import assetTypes from "../../../../../constants/types/assetTypes";
import editorTypes from "../../../../../constants/types/editorTypes";
import { getCurrentTile } from "../../../../../state-management/selectors/tiles.selectors";
import { getCurrentUser, getCurrentUserId } from "../../../../../state-management/selectors/user.selectors";
import useAssetUsers from "../../../../../hooks/useAssetUsers";
import AssetVersionModal from "../../../../modals/AssetVersionModal/AssetVersionModal";
import permissions from "../../../../../constants/types/permissionTypes";

function TileEditorHeader() {
  const tile = useSelector(getCurrentTile);
  const tileUsers = useAssetUsers(assetTypes.TILES, tile);
  const user = useSelector(getCurrentUser);
  const userId = useSelector(getCurrentUserId);
  const { editor, goToTileSource, goToTileDocs, goToTilePreview } = useNavigation();
  const [shareModalOpen, setShareModalOpen] = React.useState(false);
  const [versionModalOpen, setVersionModalOpen] = React.useState(false);

  // TODO: replace with selector
  const isOwner =
    userId && tile.permissions &&
    (tile.permissions[userId] === permissions.OWNER);

  return (
    <PageHeader id="header">
      <ShareAssetModal
        readOnly={!isOwner}
        assetType={assetTypes.TILES}
        asset={tile}
        assetUsers={tileUsers}
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
      />
      <AssetVersionModal
        assetType={assetTypes.TILES}
        open={versionModalOpen}
        asset={tile}
        onClose={() => setVersionModalOpen(false)}
      />
      <ImageUpload
        withIcon
        src={tile.thumbnailURL}
        size="mini"
        onUpload={(val: any) => uploadAssetThumbnail(assetTypes.TILES, tile.id, val)}
      />
      <TextField
        style={{ display: "flex" }}
        value={tile.title}
        onBlur={(val: any) => updateAssetTitle(assetTypes.TILES, tile.id, val)}
        transparent
        placeholder="click to add a title"
      >
        <PageHeader.Title>{tile.title}</PageHeader.Title>
      </TextField>
      <div style={{ flex: "1" }} />
      <PageHeader.Button
        active={editor === editorTypes.SOURCE}
        label="Tile Source"
        icon
        onClick={() => {
          goToTileSource(tile.id);
        }}
      >
        <Icon name="code" />
      </PageHeader.Button>
      <PageHeader.Button
        active={editor === editorTypes.DOCS}
        label="Documentation Editor"
        selected
        icon
        onClick={() => {
          goToTileDocs(tile.id);
        }}
      >
        <Icon name="file text" />
      </PageHeader.Button>
      <div style={{ flex: "1" }} />
      <Button size="tiny" secondary content="preview" icon="eye" onClick={() => goToTilePreview(tile.id)} />
      <Button size="tiny" primary content="share" icon="share alternate" onClick={() => setShareModalOpen(true)} />
      <Button
        size="tiny"
        icon
        labelPosition="left"
        primary
        onClick={() => {
          setVersionModalOpen(true);
        }}
      >
        <Icon name="cloud upload" />
        Publish Tile
      </Button>
      <UserAvatar user={user} />
    </PageHeader>
  );
}

export default TileEditorHeader;
