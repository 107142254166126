import React from "react";
import { Route, Switch } from "react-router-dom";

import paths from "./constants/paths";
import { makeLocation } from "./helpers/utils";

import AuthRoute from "./components/elements/AuthRoute/AuthRoute";
import AuthActions from "./components/elements/AuthActions/AuthActions";

import LandingPage from "./components/pages/LandingPage/LandingPage";
import UserSettingsPage from "./components/pages/UserSettingsPage/UserSettingsPage.loader";
import ForgotPasswordPage from "./components/pages/ForgotPasswordPage/ForgotPasswordPage.loader";
import RegistrationPage from "./components/pages/RegistrationPage/RegistrationPage.loader";
import NotFoundPage from "./components/pages/NotFoundPage/NotFoundPage.loader";
import SignInPage from "./components/pages/SignInPage/SignInPage";
import TileListPage from "./components/pages/TileListPage/TileListPage";
import TilePreviewPage from "./components/pages/TilePreviewPage/TilePreviewPage";
import TileEditorPage from "./components/pages/TileEditorPage/TileEditorPage";
import ProjectListPage from "./components/pages/ProjectListPage/ProjectListPage";
import ProjectPreviewPage from "./components/pages/ProjectPreviewPage/ProjectPreviewPage.loader";
import ProjectEditorPage from "./components/pages/ProjectEditorPage/ProjectEditorPage.loader";
import assetTypes from "./constants/types/assetTypes";

/* Application Routes */

const isLoggedIn = (user: boolean) => user;
const isLoggedOut = (user: boolean) => !user;

const Routes = () => (
  <Switch>
    <AuthRoute
      exact
      failureRedirect={makeLocation(paths.PROJECTS, { assetType: assetTypes.PROJECTS })}
      condition={isLoggedOut}
      path={paths.HOME}
      component={LandingPage}
    />
    <AuthRoute
      exact
      path={paths.SIGN_IN}
      failureRedirect={makeLocation(paths.PROJECTS, { assetType: assetTypes.PROJECTS })}
      condition={isLoggedOut}
      component={SignInPage}
    />
    <AuthRoute
      exact
      path={paths.REGISTER}
      failureRedirect={makeLocation(paths.PROJECTS, { assetType: assetTypes.PROJECTS })}
      condition={isLoggedOut}
      component={RegistrationPage}
    />
    <AuthRoute
      exact
      path={paths.REQUEST_NEW_PASSWORD}
      failureRedirect={makeLocation(paths.PROJECTS, { assetType: assetTypes.PROJECTS })}
      condition={isLoggedOut}
      component={ForgotPasswordPage}
    />
    <AuthRoute
      exact
      path={paths.RESET_PASSWORD}
      failureRedirect={makeLocation(paths.PROJECTS, { assetType: assetTypes.PROJECTS })}
      condition={isLoggedOut}
      component={AuthActions}
    />
    <AuthRoute
      path={paths.PROJECT_EDITOR}
      failureRedirect={makeLocation(paths.PROJECTS, { assetType: assetTypes.PROJECTS })}
      condition={isLoggedIn}
      component={ProjectEditorPage}
    />
    <AuthRoute
      path={paths.PROJECT_PREVIEW_PAGE}
      component={ProjectPreviewPage}
    />
    <AuthRoute
      path={paths.PROJECT_PREVIEW}
      component={ProjectPreviewPage}
    />
    <AuthRoute
      exact
      path={paths.PROJECTS}
      component={ProjectListPage}
    />
    <AuthRoute
      exact
      path={paths.TILES}
      component={TileListPage}
    />
    <AuthRoute
      exact
      path={paths.TILE_PREVIEW}
      component={TilePreviewPage}
    />
    <AuthRoute
      path={paths.TILE_EDITOR}
      failureRedirect={makeLocation(paths.TILES, { assetType: assetTypes.TILES })}
      condition={isLoggedIn}
      component={TileEditorPage}
    />
    <AuthRoute
      exact
      path={paths.SETTINGS}
      failureRedirect={paths.SIGN_IN}
      condition={isLoggedIn}
      component={UserSettingsPage}
    />
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
