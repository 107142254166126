import React from "react";
import noop from "lodash.noop";
import { Modal, Button } from "@au-re/mosaik-elements";

import AssetVersionList from "../../elements/AssetVersionList/AssetVersionList";
import { saveTileVersion, getTileVersions, setTileCurrentVersion, publishTileVersion } from "../../../state-management/requests/tiles.requests";

function AssetVersionModal(props: any) {
  const { open, asset, trigger } = props;
  const { onClose = noop } = props;

  const [versions, setVersions] = React.useState([] as any);

  React.useEffect(() => {
    if (asset.id) {
      const unsub = getTileVersions(asset.id, setVersions);
      return () => unsub && unsub();
    }
  }, [asset.id]);

  if (!asset) return null;

  function createVersion() {
    saveTileVersion(asset.id);
  }

  function publishVersion(versionId: string) {
    publishTileVersion(asset.id, versionId);
  }

  return (
    <Modal
      size="small"
      trigger={trigger}
      open={open}
      onClose={onClose}
      closeOnEscape
      closeOnDimmerClick
    >
      <Modal.Header style={{ display: "flex", justifyContent: "space-between" }}>
        {`${asset.title || asset.id || ""} versions`}
      </Modal.Header>
      <Modal.Content style={{ display: "flex", paddingTop: 0, maxHeight: "720px", overflow: "auto" }}>
        <AssetVersionList
          isTouched={asset.isTouched}
          currentVersion={asset.currentVersionId}
          onSaveDraft={createVersion}
          onSetCurrentVersion={async (versionId: string) => {
            await setTileCurrentVersion(asset.id, versionId);
            // this is a temporary fix
            // the source code currently only gets loaded on page load
            // and not when a version is fetched
            // an option would be the have the version in the url e.g. /tiles/xyz/v1/source
            window.location.reload();
          }}
          onPublishVersion={publishVersion}
          versions={versions}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>cancel</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default AssetVersionModal;
