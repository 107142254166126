import "brace/mode/javascript";
import "brace/mode/css";
import "brace/mode/html";
import "brace/theme/github";

import * as React from "react";
import { Button, CodeEditor, Icon, Divider } from "@au-re/mosaik-elements";
import { useSelector } from "react-redux";
import get from "lodash.get";

import { updateProjectAction, deployProjectAction, setCustomActionInputSchema, setCustomActionOutputSchema } from "../../../../state-management/requests/project.requests";
import { getSelectedModule } from "../../../../state-management/selectors/projectEditor.selectors";
import { getCurrentProject, getCurrentProjectModuleById } from "../../../../state-management/selectors/projects.selectors";
import CodeModal from "../../../modals/CodeModal/CodeModal";
import DependencyModal from "../../../modals/DependencyModal/DependencyModal";

function CustomActionEditor() {
  const selectedModuleId = useSelector(getSelectedModule);
  const project = useSelector(getCurrentProject);
  const selectedAction = useSelector(getCurrentProjectModuleById(selectedModuleId));

  const [_code, setCode] = React.useState(selectedAction.source);
  const [isCodeModalOpen, setCodeModalOpen] = React.useState(false);
  const [isDependencyModalOpen, setDependencyModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editInputSchema, setEditInputSchema] = React.useState(false);
  const [editOutputSchema, setEditOutputSchema] = React.useState(false);

  React.useEffect(() => {
    setCode(selectedAction.source);
  }, [selectedAction.source]);

  async function onSourceUpdate(source: string) {
    if (!selectedModuleId) return;
    setLoading(true);
    updateProjectAction(project.id, selectedModuleId, { source });
    await deployProjectAction(selectedModuleId, source, selectedAction.dependencies);
    setLoading(false);
  }

  async function onInputSchemaUpdate(schema: string) {
    if (!selectedModuleId) return;
    setLoading(true);
    await setCustomActionInputSchema(project.id, selectedModuleId, schema);
    setLoading(false);
  }

  async function onOutputSchemaUpdate(schema: string) {
    if (!selectedModuleId) return;
    setLoading(true);
    await setCustomActionOutputSchema(project.id, selectedModuleId, schema);
    setLoading(false);
  }

  async function onDependencyUpdate(dependencies: any) {
    if (!selectedModuleId) return;
    setLoading(true);
    updateProjectAction(project.id, selectedModuleId, { dependencies });
    await deployProjectAction(selectedModuleId, selectedAction.source, dependencies);
    setLoading(false);
  }

  return (
    <div>
      <DependencyModal
        close={() => setDependencyModalOpen(false)}
        open={isDependencyModalOpen}
        dependencies={selectedAction.dependencies}
        save={onDependencyUpdate}
      />
      <CodeModal
        close={() => setEditInputSchema(false)}
        open={editInputSchema}
        title={`edit input schema ${selectedAction.label}`}
        code={get(selectedAction, "inputSchema", "")}
        mode="json"
        onSave={(source: string) => onInputSchemaUpdate(source)}
      />
      <CodeModal
        close={() => setEditOutputSchema(false)}
        open={editOutputSchema}
        title={`edit output schema ${selectedAction.label}`}
        code={get(selectedAction, "outputSchema", "")}
        mode="json"
        onSave={(source: string) => onOutputSchemaUpdate(source)}
      />
      <CodeModal
        close={() => {
          setCodeModalOpen(false);
          setCode(selectedAction.source);
        }}
        open={isCodeModalOpen}
        title={`edit action ${selectedAction.label}`}
        code={_code}
        mode="javascript"
        onSave={(source: string) => onSourceUpdate(source)}
      />

      <Button.Group vertical labeled icon>
        <Button
          icon
          labelPosition="left"
          size="tiny"
          onClick={() => setDependencyModalOpen(true)}
        >
          <Icon name="linkify" />
          add dependencies
        </Button>
        <Button
          size="tiny"
          labelPosition="left"
          icon
          onClick={() => setEditInputSchema(true)}
        >
          <Icon name="file alternate" />
          edit input schema
        </Button>
        <Button
          size="tiny"
          labelPosition="left"
          icon
          onClick={() => setEditOutputSchema(true)}
        >
          <Icon name="file alternate" />
          edit output schema
        </Button>
      </Button.Group>

      <Divider style={{ margin: ".5rem 0" }} />

      <Button
        style={{ marginBottom: ".5rem" }}
        icon
        labelPosition="left"
        size="tiny"
        onClick={() => setCodeModalOpen(true)}
      >
        <Icon name="expand arrows alternate" />
        expand editor
      </Button>

      <CodeEditor
        tabSize={2}
        fontSize={14}
        height="400px"
        width="100%"
        mode="javascript"
        theme="clouds"
        value={_code}
        onChange={(code: string) => setCode(code)}
      />
      <Button
        style={{ marginTop: "1rem" }}
        loading={loading}
        onClick={() => onSourceUpdate(_code)}
        positive
        icon="checkmark"
        labelPosition="right"
        content="save"
      />
    </div>
  );
}

export default CustomActionEditor;
