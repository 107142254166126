import "@au-re/mosaik-elements/lib/styles.css";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { ThemeProvider } from "styled-components";
import { Button, Alert, AlertContainer, AlertSlide, AlertMessage } from "@au-re/mosaik-elements";
import { ConnectedRouter } from "connected-react-router";

import Routes from "./routes";
import packagejson from "../package.json";
import * as serviceWorker from "./helpers/serviceWorker";
import { Provider } from "./state-management/Provider";

import modes from "./constants/themes";
import { history } from "./state-management/configureStore";

// Error reporting: https://sentry.io/fraunhofer-chalmers-center/mosaik/
Sentry.init({
  environment: process.env.NODE_ENV,
  release: `mosaik@${packagejson.version}`,
  dsn: process.env.REACT_APP_SENTRY_DNS,
});

// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => {
    Alert.info(
      <AlertMessage
        title="A new Mosaik version is available"
        description="please close all tabs and reload the page to see the changes"
        icon="info"
      >
        <Button size="small" onClick={() => document.location.reload(true)}>reload</Button>
      </AlertMessage>);
  },
});

// Add google analytics
history.listen((location: any) => {
  (window as any).gtag("config", "UA-142231283-1", {
    page_title: document.title,
    page_location: window.location.href,
    page_path: location.pathname,
  });
});

ReactDOM.render(
  <Provider>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={{ mode: modes.LIGHT }}>
        <Routes />
        <AlertContainer
          transition={AlertSlide}
          newestOnTop
          position="bottom-center"
          closeOnClick={false}
        />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>, document.getElementById("root"));
