import firebase from "firebase/app";
import shortid from "shortid";
import request from "superagent";
import { lifeCycleTriggers } from "@au-re/weld";
import get from "lodash.get";

import { defaultProjectDetails, defaultProjectMeta, defaultDashboardLocation, defaultProjectModule, defaultProjectNode, defaultCustomAction } from "../../constants/defaults";
import { requestStart, requestSuccess, requestError, arrayToMap, readBlob } from "../../helpers/utils";
import paths from "../../constants/paths";
import collections, { asset2Collection } from "../../constants/database/collections";
import { db, auth, storage } from "../configureFirebase";
import assetTypes from "../../constants/types/assetTypes";
import actionTypes from "../../constants/types/actionTypes";
import moduleTypes from "../../constants/types/moduleTypes";
import projectLayoutTypes from "../../constants/types/projectLayoutTypes";
import nodeTypes from "../../constants/types/nodeTypes";
import actionTypeComponents from "../../constants/components/actionTypeComponents";
import buckets from "../../constants/database/buckets";
import integratedTileComponents from "../../constants/components/integratedTileComponents";

const ref = db.collection(collections.PROJECTS_DETAIL);

/**
 * Create a project
 *
 * - a cloud function is triggered that will create all needed resources
 *
 */
export async function createProject() {
  requestStart();
  try {
    if (!auth.currentUser) throw new Error("user not logged in");

    const { uid } = auth.currentUser;
    const metaCollection = asset2Collection[assetTypes.PROJECTS].meta;
    const detailsCollection = asset2Collection[assetTypes.PROJECTS].details;
    const defaultMeta = defaultProjectMeta(uid);
    const defaultDetails = defaultProjectDetails();
    const id = shortid.generate();

    await Promise.all([
      db.collection(metaCollection).doc(id).set(defaultMeta),
      db.collection(detailsCollection).doc(id).set(defaultDetails),
    ]);
    requestSuccess();
    return id;
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Delete a Project
 *
 * - a cloud function is triggered that will remove all needed resources
 *
 * @param {string} projectId
 */
export async function deleteProject(projectId: string) {
  requestStart();
  try {
    if (!auth.currentUser) throw new Error("user not logged in");
    const metaCollection = asset2Collection[assetTypes.PROJECTS].meta;
    await db.collection(metaCollection).doc(projectId).delete();
    requestSuccess({
      notification: "Project successfully deleted",
    });
  } catch (error) {
    requestError({ error });
  }
}

// PROJECT TILES

/**
 * Three things happen when you add a tile to the project:
 *
 * 1. a reference to the tile Source (TILES collection) is created
 * 2. an item is added to the current page layout (at a default position)
 * 3. a node is added to the graph
 *
 * @param {*} projectId
 * @param {*} pageId
 * @param {*} tileId
 */
export async function addProjectTile(projectId: string, pageId: string, tileId: string) {
  requestStart();
  try {
    if (!projectId || !pageId) throw new Error("missing identifiers");
    const tileInstanceId = shortid.generate();
    const tileDetailsRef = db.collection(collections.TILES_DETAIL).doc(tileId);
    const tileType: any = await db.collection(collections.TILES_META).doc(tileId).get();
    const tileTypeDetails: any = await tileDetailsRef.get();

    const versionId = tileTypeDetails.data().currentVersionId;

    // retrieve the input/outputSchema from the tile version
    const versionData: any = await tileDetailsRef
      .collection("versions")
      .doc(versionId)
      .get();

    await ref.doc(projectId).update({
      [`modules.${tileInstanceId}`]: {
        ...defaultProjectModule(tileType.data().title),
        moduleType: moduleTypes.TILE,
        typeId: tileId,
        versionId,
        typeName: tileType.data().title,
        inputSchema: versionData.data().inputSchema || "{ schema: [] }",
        outputSchema: versionData.data().outputSchema || "{ schema: [] }",
      },
      [`nodes.${tileInstanceId}`]: {
        ...defaultProjectNode(),
        isCustomNode: true,
        nodeType: `${tileId}/${versionId}`,
      },
      [`layouts.${projectLayoutTypes.DASHBOARD}.pages.${pageId}.layouts.lg.${tileInstanceId}`]: defaultDashboardLocation(tileInstanceId),
    });
    requestSuccess();
    return tileInstanceId;
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Add a built-in tile to a project
 *
 * @param {*} projectId
 * @param {*} pageId
 * @param {*} tileId
 */
export async function addProjectBuiltInTile(projectId: string, pageId: string, tileId: string) {
  requestStart();
  try {
    if (!projectId || !pageId) throw new Error("missing identifiers");
    const tileInstanceId = shortid.generate();
    const versionId = "v1";

    await ref.doc(projectId).update({
      [`modules.${tileInstanceId}`]: {
        moduleType: moduleTypes.TILE,
        typeId: tileId,
        versionId,
        typeName: integratedTileComponents[tileId].title,
        inputSchema: "{ schema: [] }",
        outputSchema: "{ schema: [] }",
      },
      [`nodes.${tileInstanceId}`]: {
        ...defaultProjectNode(),
        isCustomNode: false,
        nodeType: `${tileId}`,
      },
      [`layouts.${projectLayoutTypes.DASHBOARD}.pages.${pageId}.layouts.lg.${tileInstanceId}`]: defaultDashboardLocation(tileInstanceId),
    });
    requestSuccess();
    return tileInstanceId;
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Like in tile creation, remove tile ref in different parts of the project
 *
 * @param {*} projectId
 * @param {*} pageId
 * @param {*} tileInstanceId
 */
export async function deleteProjectTile(projectId: string, tileInstanceId: string) {
  try {
    const projectRef = ref.doc(projectId);
    const doc: any = await projectRef.get();
    const { modules, layouts } = doc.data();

    // remove all references to this tile as a subscriber
    const graphNodesUpdates: any = {};
    Object.keys(modules).forEach((node) => {
      if (modules[node].subscribers &&
        Object.keys(modules[node].subscribers).includes(tileInstanceId)) {
        graphNodesUpdates[`nodes.${node}.subscribers.${tileInstanceId}`] = firebase.firestore.FieldValue.delete();
      }
    });

    // remove all references to this tile in the dashboard
    const layoutUpdates: any = {};
    Object.keys(layouts[projectLayoutTypes.DASHBOARD].pages).forEach((pageId) => {
      if (layouts[projectLayoutTypes.DASHBOARD].pages[pageId].layouts.lg[tileInstanceId]) {
        layoutUpdates[`layouts.${projectLayoutTypes.DASHBOARD}.pages.${pageId}.layouts.lg.${tileInstanceId}`] = firebase.firestore.FieldValue.delete();
      }
    });

    await projectRef.update({
      [`modules.${tileInstanceId}`]: firebase.firestore.FieldValue.delete(),
      [`nodes.${tileInstanceId}`]: firebase.firestore.FieldValue.delete(),
      ...layoutUpdates,
      ...graphNodesUpdates,
    });
    return tileInstanceId;
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Update a tile instance label, the label is there to simplify usage
 *
 * @param {*} projectId
 * @param {*} tileInstanceId
 * @param {*} label
 */
export async function updateProjectTileLabel(
  projectId: string, tileInstanceId: string, label: string) {

  if (!projectId || !tileInstanceId) throw new Error("missing identifier");
  const projectRef = ref.doc(projectId);
  await projectRef.update({
    [`modules.${tileInstanceId}.label`]: label,
  });
  return tileInstanceId;
}

/**
 * Update a tile instance version
 *
 * @param {*} projectId
 * @param {*} tileInstanceId
 * @param {*} label
 */
export async function updateProjectTileVersion(
  projectId: string, tileInstanceId: string, versionId: string) {

  if (!projectId || !tileInstanceId) throw new Error("missing identifier");
  const projectRef = ref.doc(projectId);

  // to avoid fetching certain data too often we persist the input/output schemas in the
  // tileInstance. However this means that when we modify the version we also need to change
  // the schemas.
  // the nodeType is also updated, as it is an aggregation of moduleType and versionNumber

  const detailsCollection = asset2Collection[assetTypes.TILES].details;
  const project: any = await projectRef.get();
  const tileId = get(project.data(), `modules.${tileInstanceId}.typeId`, "");
  const tileRef = db.collection(detailsCollection).doc(tileId);
  const version: any = await tileRef.collection("versions").doc(versionId).get();

  await projectRef.update({
    [`nodes.${tileInstanceId}.nodeType`]: `${tileId}/${versionId}`,
    [`nodes.${tileInstanceId}.subscribers`]: {}, // reset all subscribers to avoid issues
    [`modules.${tileInstanceId}.versionId`]: versionId,
    [`modules.${tileInstanceId}.inputSchema`]: get(version.data(), "inputSchema", "{}"),
    [`modules.${tileInstanceId}.outputSchema`]: get(version.data(), "outputSchema", "{}"),
  });
  return tileInstanceId;
}

/**
 * Update the reference for the tile source
 *
 * @param {*} projectId
 * @param {*} tileInstanceId
 * @param {*} tile
 */
export async function updateProjectTileSource(
  projectId: string, tileInstanceId: string | null, tile: any) {

  if (!projectId || !tileInstanceId) throw new Error("missing identifier");
  const projectRef = ref.doc(projectId);
  await projectRef.update({
    [`modules.${tileInstanceId}.typeId`]: tile,
  });
  return tileInstanceId;
}

// ACTIONS

/**
 * Add an action to a project, create a node in the project graph
 *
 * @param {*} projectId
 * @param {*} actionType
 */
export async function addProjectAction(projectId: string, actionType: any) {
  requestStart();
  try {
    if (!projectId) throw new Error("missing identifier");
    const actionId = shortid.generate();
    const isCustomAction = actionType.id === actionTypes.CUSTOM_ACTION;

    let newActionModule: any = {
      ...defaultProjectModule(actionType.title),
      typeId: actionType.id,
      typeName: actionTypeComponents[actionType.id].title,
      moduleType: moduleTypes.ACTION,
    };

    const newActionNode: any = {
      ...defaultProjectNode(),
      isCustomNode: isCustomAction,
      nodeType: isCustomAction ? actionId : nodeTypes[actionType.id],
    };

    // publish the default source if it is a custom action
    if (isCustomAction) {
      newActionModule = { ...newActionModule, ...defaultCustomAction() };
      await request
        .post(`${paths.MOSAIK_ACTION_SERVICE}/${actionId}`)
        .set("Accept", "application/json")
        .send({ source: newActionModule.source });
    }

    await ref.doc(projectId).update({
      [`modules.${actionId}`]: newActionModule,
      [`nodes.${actionId}`]: newActionNode,
    });

    const notification = "action successfully created, you can view it in the graph editor";
    requestSuccess({ notification });
    return actionId;
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Update a project action
 *
 * @param {*} projectId
 * @param {*} actionId
 * @param {*} action
 */
export async function updateProjectAction(projectId: string, actionId: string, action: any) {
  if (!projectId || !actionId) throw new Error("missing identifier");
  requestStart();
  const update: any = {};

  if (action.source) update[`modules.${actionId}.source`] = action.source;
  if (action.label) update[`modules.${actionId}.label`] = action.label;

  // TODO: this call is specific to CUSTOM ACTIONS
  // move somewhere else
  if (action.dependencies) update[`nodes.${actionId}.dependencies`] = action.dependencies;

  await ref.doc(projectId).update(update);

  requestSuccess();
  return actionId;
}

/**
 * Deploy a project action
 *
 * @param {*} actionId
 * @param {*} source
 * @param {*} dependencies
 */
export async function deployProjectAction(actionId: string, source: string, dependencies: any) {
  requestStart();
  await request
    .post(`${paths.MOSAIK_ACTION_SERVICE}/${actionId}`)
    .set("Accept", "application/json")
    .send({ source, dependencies });
  requestSuccess();
}

/**
 * Remove an action from a project, and the node from the network
 *
 * @param {*} projectId
 * @param {*} actionId
 */
export async function deleteProjectAction(projectId: string, actionId: string) {
  requestStart();
  try {
    if (!projectId || !actionId) throw new Error("missing identifier");
    const projectRef = ref.doc(projectId);
    const doc: any = await projectRef.get();
    const { modules } = doc.data();
    const { typeId } = modules[actionId];
    const isCustomAction = typeId === actionTypes.CUSTOM_ACTION || !typeId;

    // remove all references to this tile as a subscriber
    const graphNodesUpdates: any = {};
    Object.keys(modules).forEach((mozModule) => {
      if (modules[mozModule].subscribers &&
        Object.keys(modules[mozModule].subscribers).includes(actionId)) {
        graphNodesUpdates[`modules.${mozModule}.subscribers.${actionId}`] = firebase.firestore.FieldValue.delete();
      }
    });

    // delete the source
    if (isCustomAction) {
      await request
        .post(`${paths.MOSAIK_ACTION_SERVICE}/${actionId}/delete`)
        .set("Accept", "application/json");
    }

    await ref.doc(projectId).update({
      [`modules.${actionId}`]: firebase.firestore.FieldValue.delete(),
      [`nodes.${actionId}`]: firebase.firestore.FieldValue.delete(),
      ...graphNodesUpdates,
    });
    requestSuccess();
    return actionId;
  } catch (error) {
    requestError({ error });
  }
}

/**
 * return all nodeIds that publish data to a given target node
 * @param targetId
 * @param nodes
 */
function getAllPublishersOfNode(targetId: string, nodes: any) {
  const publishers: string[] = [];
  Object.keys(nodes).forEach((nodeId) => {
    const isPublisherOf = get(nodes, `${nodeId}.subscribers.${targetId}`);
    if (isPublisherOf) {
      publishers.push(nodeId);
    }
  });
  return publishers;
}

/**
 * Set a custom input schema for an action
 *
 * @param projectId
 * @param actionId
 * @param schema
 */
export async function setCustomActionInputSchema(
  projectId: string, actionId: string, schema: string) {

  const update: any = {
    [`modules.${actionId}.inputSchema`]: schema,
  };

  // for all nodes remove all subscribers of type actionId
  const doc: any = await ref.doc(projectId).get();
  const { nodes } = doc.data();
  const publishers = getAllPublishersOfNode(actionId, nodes);

  publishers.forEach((publisherId: string) => {
    update[`nodes.${publisherId}.subscribers.${actionId}`] = firebase.firestore.FieldValue.delete();
  });
  // --

  try {
    JSON.parse(schema);
    await ref.doc(projectId).update(update);
  } catch (error) { /* */ }
}

/**
 * Set a custom output schema for an action
 *
 * @param projectId
 * @param actionId
 * @param schema
 */
export async function setCustomActionOutputSchema(
  projectId: string, actionId: string, schema: string) {

  await ref.doc(projectId).update({
    [`modules.${actionId}.outputSchema`]: schema,
    [`nodes.${actionId}.subscribers`]: {},
  });
}

/**
 * Update the layout type of the project
 *
 * @param {*} projectId
 * @param {*} layoutType
 */
export async function updateProjectLayout(projectId: string, layoutType: string) {
  const projectRef = ref.doc(projectId);
  return projectRef.update({ layout: layoutType });
}

/**
 * Update the project nodes when the editor state changes. The graph editor is used to create
 * connections, maps and link to triggers.
 *
 * @param {*} projectId
 * @param {*} nodeId
 * @param {*} updateMethod
 */
export async function updateProjectGraphState(projectId: string, newGraphState: any) {
  const projectRef = ref.doc(projectId);
  const doc = await projectRef.get();
  const { nodes }: any = doc.data();

  const newNodes = newGraphState.nodes.map((node: any) => {

    const subscribers = newGraphState.connections
      .filter((connection: any) => connection.originNode === node.id)
      .map((connection: any) => ({
        id: connection.targetNode,
        originField: connection.originField,
        targetField: connection.targetField,
      }))
      .reduce((acc: any, curr: any) => {
        const fieldSubscribers = get(acc, `[${curr.id}]`, { map: {} });
        const targetFields = get(acc, `[${curr.id}].map[${curr.originField}]`, {});
        acc[curr.id] = {
          ...fieldSubscribers,
          map: {
            ...fieldSubscribers.map,
            [curr.originField]: {
              ...targetFields,
              [curr.targetField]: true,
            },
          },
        };
        return acc;
      }, {});

    const triggers = newGraphState.connections
      .filter((connection: any) =>
        connection.targetNode === node.id &&
        !!(lifeCycleTriggers as any)[connection.originNode])
      .map((trigger: any) => trigger.originNode);

    return {
      ...nodes[node.id],
      id: node.id,
      location: { x: node.x, y: node.y },
      subscribers,
      triggers,
      nodeType: node.type,
    };
  });

  return projectRef.update({
    [`nodes`]: arrayToMap(newNodes),
  });
}

/** Update the persistence type of the graph
 * @param {*} projectId
 * @param {*} persistenceType
 */
export async function updateGraphPersistence(projectId: string, persistenceType: string) {
  const projectRef = ref.doc(projectId);
  return projectRef.update({ [`persistence`]: persistenceType });
}

/**
 * Update the initial state of a tile instance. The state is stringified due to limitations in
 * firestore.
 *
 * @param {*} projectId
 * @param {*} tileInstanceId
 * @param {*} defaultParams
 */
export async function updateModuleInitialState(projectId: string, moduleId: string, newState: any) {
  try {
    if (!projectId || !moduleId) throw new Error("missing identifier");
    const projectRef = ref.doc(projectId);
    await projectRef.update({ [`nodes.${moduleId}.initialState`]: JSON.stringify(newState) });
  } catch (error) {
    requestError({ error });
  }
}

/**
 * This function is used to persist project module states on firebase when cloud storage is enabled
 *
 * @param {*} projectId
 * @param {*} moduleId
 * @param {*} userId
 * @param {*} newState
 */
export async function persistProjectStateInCloud(
  projectId: string,
  userId: string,
  newState: string,
) {
  const persistedState = storage.ref(`/${buckets.PROJECTS}/${projectId}/persistence/${userId}/state.json`);
  await persistedState.putString(newState);
  const downloadURL = await persistedState.getDownloadURL();
  await ref.doc(projectId).update({
    persistenceURL: downloadURL,
  });
}

/**
 * Retrieve the persisted project state from cloud firestore
 *
 * @param {*} projectId
 * @param {*} userId
 */
export async function getProjectStateFromCloud(projectId: string, userId: string) {
  requestStart();
  try {
    const project = await ref.doc(projectId).get();
    const downloadURL = get(project.data(), "persistenceURL");
    const res = await request.get(downloadURL).responseType("blob");
    const fileContent: any = await readBlob(res.body);
    const parsedContent = JSON.parse(fileContent);
    requestSuccess();
    return parsedContent;
  } catch (error) {
    // eslint-disable-next-line no-underscore-dangle
    if (error.code_ === "storage/object-not-found") {
      requestSuccess();
      return;
    }
    requestError({ error });
  }
}
