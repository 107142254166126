import { colors, Button, Container } from "@au-re/mosaik-elements";
import React from "react";
import styled from "styled-components";

import BasePageLayout from "../../layouts/BasePageLayout/BasePageLayout";
import useNavigation from "../../../hooks/useNavigation";

const Hero = styled.div`
  background: ${colors.menuDark};
  height: calc(100% + 78px);
  min-height: 550px;
  flex: 1;
  margin-top: -78px;
  display: flex;
  flex-direction: column;
`;

const HeroContainer = styled(Container)`
  && {
    flex: 2;
    display: flex;

    p {
      font-size: 1.8rem;
      color: white;
    }
  }
`;

const HeroTitle = styled.h1`
  font-size: 3.8rem;
  color: white;
`;

function LandingPage() {
  const { goToDocumentation } = useNavigation();
  return (
    <BasePageLayout inverted>
      <Hero>
        <HeroContainer>
          <div style={{ maxWidth: "50%", alignSelf: "center" }}>
            <HeroTitle>Share your models with the world</HeroTitle>
            <p>
              Mosaik is a platform for third parties to visualize and interact with your
              proprietary algorithms.
            </p>
            <Button primary onClick={goToDocumentation}>Get Started</Button>
          </div>
        </HeroContainer>
      </Hero>
    </BasePageLayout>
  );
}

export default LandingPage;
