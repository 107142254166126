/**
 * Generate a template to test a tile locally.
 * This function should mimic as much as possible the cloud function that builds tiles.
 *
 * @param {*} css
 * @param {*} html
 * @param {*} js
 */
export const makeTemplate = (html: string, js: string, css: string) => `
  <!DOCTYPE html>
  <html>

  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>preview</title>

    <script src="https://unpkg.com/@au-re/mosaik-events/lib/mosaik-events.min.js"></script>
    <style>${css}</style>
  </head>
  <body>
    ${html}
    <script>${js}</script>
  </body>
  </html>`;
