import "brace/mode/javascript";
import "brace/mode/css";
import "brace/mode/html";
import "brace/mode/json";
import "brace/theme/clouds";
import "brace/theme/dracula";

import React from "react";
import { Formalizer } from "@au-re/formalizer";
import { CodeEditor as Editor, SplitPane, Label, Popup } from "@au-re/mosaik-elements";
import get from "lodash.get";
import { useSelector, useDispatch } from "react-redux";
import { publish, setGraph } from "@au-re/weld";

import { makeTemplate } from "./tileTemplate";
import useNavigation from "../../../../../hooks/useNavigation";
import { getTileSource, saveTileDraft, updateTileInputSchema, updateTileOutputSchema } from "../../../../../state-management/requests/tiles.requests";
import { EditorWrapper, Overlay, Preview, EditorPanel, EditorPanelWrapper, Background } from "./CodeEditor.styles";
import useTileData from "../../../../../hooks/useTileData";
import ErrorBoundary from "../../../../elements/ErrorBoundary/ErrorBoundary";
import Tile from "../../../../elements/Tile/Tile";
import { getThemeMode } from "../../../../../state-management/selectors/theme.selectors";
import { setTileSource } from "../../../../../state-management/actions/tileEditor.actions";
import { getTileEditorSource } from "../../../../../state-management/selectors/tileEditor.selectors";
import StringControl from "../../../../elements/StringControl";

const initialGraph = {
  nodes: {
    previewTile: {
      initialState: {},
      currentState: {},
    },
    previewTileEditor: {
      initialState: {},
      currentState: {},
      subscribers: {
        previewTile: {},
      },
    },
  },
};

function BaseEditor(props: any) {
  const { value, onChange, mode, ...rest } = props;
  const themeMode = useSelector(getThemeMode);
  return (
    <EditorWrapper {...rest} style={{ margin: 0, padding: 0 }}>
      <Editor
        tabSize={2}
        fontSize={14}
        mode={mode}
        theme={themeMode === "dark" ? "dracula" : "clouds"}
        height="100%"
        debounceChangePeriod={1000}
        value={value}
        onChange={onChange}
      />
    </EditorWrapper>
  );
}

function TileCodeEditor() {
  const { assetId } = useNavigation();
  const { tile } = useTileData(assetId);
  const dispatch = useDispatch();
  const { html, css, js }: any = useSelector(getTileEditorSource);
  const [isDragging, setDragging] = React.useState();
  const [connection, setConnection] = React.useState();

  const htmlSource = get(tile, "draftHTML");
  const jsSource = get(tile, "draftJS");
  const cssSource = get(tile, "draftCSS");

  // on load set a simple graph structure
  React.useEffect(() => {
    dispatch(setGraph(initialGraph));
  }, [dispatch]);

  // on load, fetch the code from the tile source files
  // this does not happen every time the files change
  // this means that the local code is not in synch with the file storage
  // coop editing of tiles does not work
  React.useEffect(() => {
    if (!htmlSource || !jsSource || !cssSource) return;

    (async () => {
      const [htmlCode, jsCode, cssCode]: any = await getTileSource({
        css: cssSource,
        html: htmlSource,
        js: jsSource,
      });

      dispatch(setTileSource({
        html: htmlCode,
        css: cssCode,
        js: jsCode,
      }));
    })();

  }, [htmlSource, jsSource, cssSource, dispatch]);

  if (!tile) return null;

  const inputSchema = get(tile, "draftInputSchema", "");
  const outputSchema = get(tile, "draftOutputSchema", "");
  const isCustomParameterEditor = get(tile, "editor.isCustom", false);
  const customParameterEditor = get(tile, "editor.tileId", "");
  const customParameterEditorVersion = get(tile, "editor.versionId", "");

  let parsedInputSchema = { schema: [] };
  try {
    parsedInputSchema = JSON.parse(inputSchema) || {};
  } catch (error) { /* */ }

  const panes = [
    {
      menuItem: "html",
      pane: (
        <BaseEditor
          key="html"
          mode="html"
          value={html}
          onChange={(data: string) => {
            dispatch(setTileSource({ html: data }));
            saveTileDraft(tile.id, { html: data });
          }}
        />
      ),
    },
    {
      menuItem: "javascript",
      pane: (
        <BaseEditor
          key="javascript"
          value={js}
          mode="javascript"
          onChange={(data: string) => {
            dispatch(setTileSource({ js: data }));
            saveTileDraft(tile.id, { js: data });
          }}
        />
      ),
    },
    {
      menuItem: "css",
      pane: (
        <BaseEditor
          key="css"
          value={css}
          mode="css"
          onChange={(data: any) => {
            dispatch(setTileSource({ css: data }));
            saveTileDraft(tile.id, { css: data });
          }}
        />
      ),
    },
    {
      menuItem: "input schema",
      pane: (
        <BaseEditor
          key="inSchema"
          value={inputSchema}
          mode="json"
          onChange={(data: any) => {
            updateTileInputSchema(tile.id, data);
          }}
        />
      ),
    },
    {
      menuItem: "output schema",
      pane: (
        <BaseEditor
          key="outSchema"
          value={outputSchema}
          mode="json"
          onChange={(data: any) => {
            updateTileOutputSchema(tile.id, data);
          }}
        />
      ),
    },
  ];

  return (
    <Background>
      <SplitPane
        style={{ position: "relative" }}
        defaultSize="40%"
        direction="vertical"
        onDragStarted={() => setDragging(true)}
        onDragFinished={() => setDragging(false)}
      >
        <EditorPanelWrapper>
          <EditorPanel renderActiveOnly={false} panes={panes} />
        </EditorPanelWrapper>
        <SplitPane
          defaultSize="70%"
          onDragStarted={() => setDragging(true)}
          onDragFinished={() => setDragging(false)}
          split="vertical"
        >
          <Preview>
            <Label attached="top right" style={{ zIndex: 1000 }}>
              <Popup
                inverted
                basic
                trigger={(
                  <Label
                    style={{ marginRight: ".5rem" }}
                    circular
                    color={connection ? "green" : "red"}
                    empty
                    size="mini"
                  />
                )}
              >
                {connection ? "connected" : "not connected"}
              </Popup>
              tile preview
            </Label>
            {isDragging && <Overlay />}
            <ErrorBoundary>
              <Tile
                id="previewTile"
                typeId={tile.id}
                withRemoteAuth={tile.withRemoteAuth}
                authorized
                onPublish={(data: any) => {
                  dispatch(publish("previewTile", data));
                }}
                onConnection={(conn: any) => {
                  setConnection(conn);
                }}
                srcDoc={makeTemplate(html, js, css)}
              />
            </ErrorBoundary>
          </Preview>
          <Preview>
            <Label attached="top right">parameter editor</Label>
            {isDragging && <Overlay />}
            {
              isCustomParameterEditor
                ? (
                  <ErrorBoundary>
                    <Tile
                      key={JSON.stringify(connection)}
                      id={customParameterEditor}
                      typeId={customParameterEditor}
                      versionId={customParameterEditorVersion}
                      authorized
                      onPublish={(data: any = {}) => {
                        dispatch(publish("previewTileEditor", data));
                      }}
                    />
                  </ErrorBoundary>
                )
                : (
                  <ErrorBoundary message="your schema is probably malformed, please change it and retry">
                    <div className="ui form">
                      <Formalizer
                        components={{
                          string: StringControl,
                        }}
                        onSubmit={(data: any) =>
                          dispatch(publish("previewTileEditor", data))}
                        onValueChange={(data: any) => {
                          dispatch(publish("previewTileEditor", data));
                        }}
                        schema={parsedInputSchema.schema || []}
                      />
                    </div>
                  </ErrorBoundary>
                )
            }
          </Preview>
        </SplitPane>
      </SplitPane>
    </Background>
  );
}

export default TileCodeEditor;
