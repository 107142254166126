import styled from "styled-components";
import { Dimmer, Message } from "@au-re/mosaik-elements";

export const UnauthorizedMessage = styled(Message)`
  &&&& {
    box-shadow: none;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    * {
      align-self: center;
    }
  }
`;

export const DimmerContainer = styled(Dimmer.Dimmable)`
  flex: 1;
`;

export const Iframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
`;
