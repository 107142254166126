import React from "react";
import noop from "lodash.noop";
import shortid from "shortid";
import { Modal, Button, Icon, Input, SortableList } from "@au-re/mosaik-elements";

import { DependencyRowStyled, DependencyList } from "./DependencyModal.styles";

function DependencyRow(props: any) {
  const { onChange = noop, onDelete = noop, value, ...rest } = props;
  return (
    <DependencyRowStyled {...rest}>
      <Icon name="bars" style={{ marginTop: "-.5rem", cursor: "grab" }} />
      <Input fluid value={value} onChange={onChange} />
      <Button icon="delete" negative onClick={onDelete} />
    </DependencyRowStyled>
  );
}

function DependencyModal(props: any) {
  const { open, dependencies = [], trigger } = props;
  const { close = noop, save = noop } = props;

  const initialDependencies = dependencies.map((url: string) => ({ id: shortid.generate(), url }));

  const [dependenciesModified, setDependencies] = React.useState(initialDependencies);

  function addDependency() {
    setDependencies([...dependenciesModified, { id: shortid.generate(), url: "" }]);
  }

  function deleteDependency(dependencyId: string) {
    setDependencies(dependenciesModified.filter((_: any, idx: number) => {
      return dependencyId !== `${idx}`;
    }));
  }

  function updateDependency(dependencyId: string, url: string) {
    const newDep: any = [...dependenciesModified];
    newDep[dependencyId] = {
      ...newDep[dependencyId],
      url,
    };
    setDependencies(newDep);
  }

  function reorder(list: any, startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  function onDrop(result: any) {
    if (!result.destination) return; // dropped outside the list
    const reorderedItems = reorder(
      dependenciesModified,
      result.source.index,
      result.destination.index);
    setDependencies(reorderedItems);
  }

  return (
    <Modal
      size="tiny"
      trigger={trigger}
      open={open}
      onClose={close}
      closeOnEscape
      closeOnDimmerClick
    >
      <Modal.Header style={{ display: "flex", justifyContent: "space-between" }}>
        Manage Dependencies
      </Modal.Header>
      <Modal.Content>
        <SortableList onDragEnd={onDrop}>
          <DependencyList>
            {
              dependenciesModified.map((dependency: any, idx: number) => (
                <SortableList.Item id={dependency.id} index={idx} key={dependency.id}>
                  <DependencyRow
                    value={dependency.url}
                    onChange={(e: any) => updateDependency(`${idx}`, e.target.value)}
                    onDelete={() => deleteDependency(`${idx}`)}
                  />
                </SortableList.Item>
              ))
            }
            <Button icon="plus" content="Add Dependency" onClick={addDependency} />
          </DependencyList>
        </SortableList>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={close}>cancel</Button>
        <Button
          onClick={() => {
            save(dependenciesModified.map((d: any) => d.url));
            close();
          }}
          positive
          icon="checkmark"
          labelPosition="right"
          content="save"
        />
      </Modal.Actions>
    </Modal>
  );
}

export default DependencyModal;
