import "brace/mode/json";
import "brace/theme/clouds";

import isEqual from "lodash.isequal";
import React from "react";
import { Input, Form, Button } from "@au-re/mosaik-elements";

import styled from "styled-components";
import { getNodeInitialState } from "@au-re/weld";
import { useSelector } from "react-redux";
import { getSelectedModule } from "../../../../state-management/selectors/projectEditor.selectors";
import { onGraphState } from "../../../../state-management/selectors/graph.selectors";
import { updateModuleInitialState } from "../../../../state-management/requests/project.requests";
import { getCurrentProjectId } from "../../../../state-management/selectors/projects.selectors";
import { isValidURL, ensureHTTPProtocol } from "../../../../helpers/validation";
import { updateTileInstanceSecret, fetchTileInstanceSecret } from "../../../../state-management/requests/secret.requests";

const Label = styled.label`
  display: block;
  margin-top: 1rem;
`;

const Field = styled.div`
  display: flex;
  margin-bottom: .45rem;
  justify-content: space-between;
  align-items: center;
`;

const ModuleOptionsButton = styled(Button)`
  &&&& {
    width: 120px;
    margin: auto;
    display: block;
  }
`;

function IframeTileEditor() {
  const projectId = useSelector(getCurrentProjectId);
  const tileId = useSelector(getSelectedModule);
  const initialState = useSelector(onGraphState(getNodeInitialState(tileId || "")));

  const defaultState = {
    url: initialState.url || "",
  };

  const [formState, setFormState] = React.useState(defaultState);
  const [showSecret, setShowSecret] = React.useState(false);
  const [authURL, setAuthURL] = React.useState(null);
  const [secret, setSecret] = React.useState(null);

  // when the formState changes update the module initial state
  React.useEffect(() => {
    if (!tileId || isEqual(formState, defaultState)) return;
    updateModuleInitialState(projectId, tileId, formState);
  }, [projectId, tileId, defaultState, formState]);

  React.useEffect(() => {
    (async () => {
      const res: any = await fetchTileInstanceSecret(projectId, tileId);
      setAuthURL(res.authURL);
      setSecret(res.secret);
    })();
  }, [projectId, tileId]);

  const updateFormState = (field: any) => {
    setFormState({ ...formState, ...field });
  };

  const canSubmit = (formState: any) => {
    return authURL !== formState.authURL
      || secret !== formState.secret;
  };

  const toggleSecret = () => {
    setShowSecret(!showSecret);
  };

  const onSubmit = (form: any) => {
    const cleanForm = {
      authURL: ensureHTTPProtocol(form.authURL),
      secret: form.secret,
    };
    updateTileInstanceSecret(projectId, tileId, cleanForm);
  };

  return (
    <div>
      <Label>url</Label>
      <Input
        defaultValue={formState.url}
        fluid
        onBlur={(e: any) => {
          const url = e.target.value;
          updateFormState({ url });
        }}
        placeholder="enter a url"
      />
      <br />
      <Form
        key={JSON.stringify({ authURL, secret })}
        initialValues={{ authURL, secret }}
        onSubmit={onSubmit}
        style={{ display: "flex" }}
      >
        {
          ({ formState }: any) => (
            <>
              <div style={{ marginBottom: ".5rem" }}>
                <Field>
                  <Label>auth url</Label>
                  <Form.Text
                    validateOnChange
                    validate={isValidURL}
                    size="small"
                    type="text"
                    id="authURL"
                    field="authURL"
                  />
                </Field>
                <Field>
                  <Label>module secret</Label>
                  <Form.Text
                    size="small"
                    type={showSecret ? "text" : "password"}
                    id="secret"
                    field="secret"
                    actionPosition="left"
                    action={{
                      icon: "eye",
                      onClick: toggleSecret,
                      type: "button",
                      primary: true,
                    }}
                  />
                </Field>
              </div>
              <ModuleOptionsButton
                primary
                disabled={!canSubmit(formState.values) || formState.invalid}
              >
                save
              </ModuleOptionsButton>
            </>
          )
        }
      </Form>
    </div>
  );
}

export default IframeTileEditor;
