import { SELECT_MODULE, RESET_PROJECT_EDITOR } from "../actions/projectEditor.actions";

const initialState = {
  selectedModule: null,
};

/**
 * This reducer tracks the currently selected module
 * @param state
 * @param action
 */
function projectEditorReducer(state: any = initialState, action: any) {

  if (action.type === RESET_PROJECT_EDITOR) return initialState;

  if (action.type === SELECT_MODULE) {
    const { moduleId } = action.payload;
    return {
      ...state,
      selectedModule: moduleId,
    };
  }

  return state;
}

export default projectEditorReducer;
