import get from "lodash.get";
import uniqBy from "lodash.uniqby";
import { getNodeState } from "@au-re/weld";

import nodeDataTypes, { nodeDataTypesDetails, nodeDataTypesControls } from "../../constants/types/nodeDataTypes";
import { nodeTypeDefaults } from "../../constants/types/nodeTypes";
import { mapToArray } from "../../helpers/utils";
import { getCurrentProjectNodes, getCurrentProjectModules } from "./projects.selectors";

/**
 * returns the current graph state
 * @param state
 */
export function getGraphState(state: any) {
  return get(state, `project.graphState`, {});
}

/**
 * given a selector (i.e from weld, provide the correct path to the state slice)
 * @param selector
 */
export function onGraphState(selector: (graphState: any) => any) {
  return (store: any) => selector(getGraphState(store));
}

function schemaToNodeType(inputSchema: any, outputSchema: any) {
  const filedIdMap: any = {};
  const inputFields = inputSchema.map((val: any, idx: number) => {
    filedIdMap[val.id] = idx;
    return {
      id: val.id,
      name: val.label,
      dataType: (nodeDataTypes as any)[val.type] || nodeDataTypes.any,
      controlType: get(nodeDataTypesControls, `[${val.type}].controlType`),
      controlProps: get(nodeDataTypesControls, `[${val.type}].controlProps`),
      input: true,
    };
  });

  // sometimes, input and output fields will have the same ids,
  // this way ramen will treat them as the same field
  outputSchema.forEach((val: any) => {
    if (filedIdMap[val.id] || filedIdMap[val.id] === 0) {
      inputFields[filedIdMap[val.id]].output = true;
    }
  });

  const outputFields = outputSchema.map((val: any) => {
    return {
      id: val.id,
      name: val.label,
      dataType: (nodeDataTypes as any)[val.type] || nodeDataTypes.any,
      output: true,
    };
  });

  return uniqBy(inputFields.concat(outputFields), "id");
}

/**
 * Return the ramen schema given a project
 * 1. for each modules, check if the modules nodeType is CUSTOM_NODE
 *  1.1 if it is we use the modules inputSchema/outputSchema to generate a ramen nodeType
 * 2. we add default nodeTypes such as lifecycle functions
 * 3. we add the default dataTypes
 * @param state
 */
export function getRamenSchemaFromProject(state: any) {
  const nodes = getCurrentProjectNodes(state);
  const modules = getCurrentProjectModules(state);

  const customModules = mapToArray(nodes)
    .filter((node: any) => node.isCustomNode)
    .map((node) => ({ id: node.id, ...modules[node.id] }));

  const schemaModuleTypes = customModules
    .map((moduleType: any) => {
      let inputSchemaDefinition = {};
      let outputSchemaDefinition = {};

      try {
        // somehow, get(moduleType, `inputSchema`, "{}") returns "" when schema is undefined
        inputSchemaDefinition = JSON.parse(get(moduleType, `inputSchema`) || "{}");
        outputSchemaDefinition = JSON.parse(get(moduleType, `outputSchema`) || "{}");
      } catch (error) {
        /* */
      }

      const inputSchema = get(inputSchemaDefinition, `schema`, []);
      const outputSchema = get(outputSchemaDefinition, `schema`, []);
      return {
        id: nodes[moduleType.id].nodeType,
        definition: {
          name: moduleType.typeName,
          icon: "",
          fields: schemaToNodeType(inputSchema, outputSchema),
        },
      };
    })
    .reduce((acc: any, { id, definition }: any) => {
      acc[id] = definition;
      return acc;
    }, {});

  return {
    nodeTypes: {
      ...schemaModuleTypes,
      ...nodeTypeDefaults,
    },
    dataTypes: nodeDataTypesDetails,
  };
}

function getConnectionsFromSubscribers(originId: string, subscribers: any = {}) {
  const connections: any = [];

  Object.keys(subscribers).forEach((sub: any) => {
    const map = get(subscribers[sub], "map", {});
    Object.keys(map).forEach((originField) => {
      const connectionMap = Object.keys(map[originField]);

      connectionMap.forEach((targetField) => {
        connections.push({
          originNode: originId,
          originField,
          targetNode: sub,
          targetField,
        });
      });
    });
  });
  return connections;
}

/**
 * Return the graph structure from a given project
 * @param state
 */
export function getRamenGraphFromProject(state: any) {
  const projectNodes = getCurrentProjectNodes(state);
  const modules = getCurrentProjectModules(state);

  let connections: any = [];

  const nodes = mapToArray(projectNodes).map((graphNode) => {
    const { id } = graphNode;
    const nodeConnections = getConnectionsFromSubscribers(id, graphNode.subscribers);
    connections = connections.concat(nodeConnections);

    const nodeName = get(modules, `${graphNode.id}.label`)
      || get(nodeTypeDefaults, `[${graphNode.nodeType}].name`)
      || id;

    const nodeType = get(graphNode, `nodeType`);

    const node = {
      id,
      x: get(graphNode, "location.x", 20),
      y: get(graphNode, "location.y", 20),
      type: `${nodeType}`,
      name: nodeName,
      icon: get(nodeTypeDefaults, `[${graphNode.nodeType}].icon`, ""),
    };

    return node;
  });

  return { nodes, connections };
}

/**
 * Return the graph state from the current project
 * @param state
 */
export function getRamenGraphStateFromProject(state: any) {
  const { nodes } = getGraphState(state);
  const nodeStates: any = {};

  Object.keys(nodes).forEach((key) => {
    const nodeState = onGraphState(getNodeState(key))(state);
    nodeStates[key] = Object.keys(nodeState).reduce((acc: any, cur) => {
      acc[cur] = {
        value: nodeState[cur],
        checked: nodeState[cur],
      };
      return acc;
    }, {});
  });

  return nodeStates;
}
