import firebase from "firebase/app";
import request from "superagent";

import { auth } from "../configureFirebase";
import { requestStart, requestSuccess, requestError } from "../../helpers/utils";
import paths from "../../constants/paths";

/**
 * Enable persistence for the logged in user (user stays logged in after closing the tab)
 * @param {*} persist
 */
function setPersistence(persist = false) {
  const persistence = persist
    ? firebase.auth.Auth.Persistence.LOCAL
    : firebase.auth.Auth.Persistence.SESSION;
  return auth.setPersistence(persistence);
}

/**
 * Sign in a user with email and password
 * @param {*} email
 * @param {*} password
 * @param {*} remember
 */
export async function signIn(email = "", password = "", remember = false) {
  requestStart();
  try {
    await setPersistence(remember);
    await auth.signInWithEmailAndPassword(email, password);
    requestSuccess();
    return true;
  } catch (error) {
    requestError({ error, showNotification: true, notification: error.message });
    throw error;
  }
}

/**
 * Sign out a user from the platform
 */
export async function signOut() {
  await auth.signOut();
}

/**
 * send an email to reset a user password
 *
 * @param {*} email
 */
export async function sendPasswordResetEmail(email: string) {
  requestStart();
  await auth.sendPasswordResetEmail(email);
  requestSuccess({ notification: "we have sent you an email to reset your password" });
}

/**
 * reset the user password
 *
 * @param {*} oobCode
 * @param {*} repeatPassword
 */
export async function resetPassword(oobCode: string, repeatPassword: string) {
  requestStart();
  await auth.confirmPasswordReset(oobCode, repeatPassword);
  requestSuccess({ notification: "your password has been updated" });
}

/**
 * verify a code to ensure it was sent to a user to reset their password
 *
 * @param {*} oobCode
 */
export async function verifyPasswordResetCode(oobCode: string | null) {
  requestStart();
  try {
    const res = await auth.verifyPasswordResetCode(oobCode);
    requestSuccess();
    return res;
  } catch (error) {
    requestError({ error });
    throw error;
  }
}

/**
 * the action code is applied when verifying an email
 *
 * @param {*} oobCode
 */
export async function applyActionCode(oobCode: string | null) {
  requestStart();
  try {
    const res = await auth.applyActionCode(oobCode);
    requestSuccess({
      notification: "your email has been verified",
    });
    return res;
  } catch (error) {
    requestError({ error });
    throw error;
  }
}

/**
 * re-authentication is necessary to edit certain properties such as password/email
 *
 * @param {*} password
 * @param {*} email
 */
export async function reauthenticate(password: string, email = auth.currentUser.email) {
  const credential = firebase.auth.EmailAuthProvider.credential(email, password);
  return auth.currentUser.reauthenticateAndRetrieveDataWithCredential(credential);
}

/**
 * create an access token to authenticate against a backend
 *
 * @param {*} moduleId
 */
export async function createAccessToken(moduleId: string, projectId?: string) {
  requestStart();
  try {
    const idToken = await auth.currentUser.getIdToken(true);
    const res = await request
      .get(paths.MOSAIK_ACCESS_TOKEN_SERVICE || "")
      .query({ projectId, moduleId })
      .set("Authorization", `Bearer ${idToken}`);
    requestSuccess();

    if (!res.body.token) {
      throw new Error("missing accessToken");
    }

    return ({ accessToken: res.body.token });
  } catch (error) {
    requestError({ notification: `couldn't login to remote: ${error.message}` });
    return {};
  }
}
