import React from "react";
import { Button, theme, Icon, colors } from "@au-re/mosaik-elements";
import styled from "styled-components";
import { getSortedVersions } from "../../../helpers/utils";

const VersionBox = styled.div`
  display: flex;
  padding: .5rem 1rem;
  border: 1px solid ${theme.borderColor};
  flex: 1;
  justify-content: space-between;
`;

const VersionBackground = styled.div`
  width: 100%;
  display: flex;

  .ui.button {
    width: 110px;
  }

  :not(:nth-of-type(2)) {
    ${VersionBox} {
      border-top: none;
    }
  }
`;

const Line = styled.div`
  background-color: rgba(0,0,0,.14);
  margin-left: 9px;
  width: 2px;
  position: absolute;
  top: -29px;
  height: 57px;
  left: 10px;
`;

const Circle: any = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ active, published }: any) => {
    if (active) return colors.green;
    if (published) return colors.primary;
    return "transparent";
  }};
  border-radius: 20px;
  align-self: center;
  border: 1px solid ${theme.borderColor};
`;

const CircleContainer = styled.div`
  padding: 1rem;
  justify-content: center;
  display: flex;
  position: relative;
`;

function VersionInfo(props: any) {
  const { name, isPublished, isSaved, isCurrent, date, onPublishVersion, onSetCurrentVersion } = props;

  const publishedIcon = isPublished ? "check" : "cloud upload";
  const publishedText = isPublished ? "published" : "publish";
  const currentIcon = isCurrent ? "check" : "arrow down";
  const useButtonText = isCurrent ? "used" : "use";

  return (
    <VersionBackground>
      <CircleContainer>
        <Circle active={isCurrent} published={isPublished} />
        <Line />
      </CircleContainer>
      <VersionBox>
        <div>
          <h4>{name}</h4>
          <p>{date}</p>
        </div>
        <div>
          <Button
            icon
            labelPosition="left"
            size="mini"
            positive={isCurrent}
            active={isCurrent}
            onClick={() => onSetCurrentVersion(name)}
          >
            <Icon name={currentIcon} />
            {useButtonText}
          </Button>
          <Button
            icon
            labelPosition="left"
            size="mini"
            primary
            disabled={!isSaved}
            active={isPublished}
            onClick={() => onPublishVersion(name)}
          >
            <Icon name={publishedIcon} />
            {publishedText}
          </Button>
        </div>
      </VersionBox>
    </VersionBackground>
  );
}

const Background = styled.div`
  width: 100%;
`;

function AssetVersionList(props: any) {
  const { versions = [], lastModifiedDate, currentVersion, isTouched } = props;
  const { onSaveDraft, onPublishVersion, onSetCurrentVersion } = props;
  return (
    <Background>
      <VersionBackground style={{ marginBottom: "2rem" }}>
        <VersionBox style={{ borderTop: "none" }}>
          <div>
            <h4>Draft</h4>
            <p>
              last modified:
              {lastModifiedDate}
            </p>
          </div>
          <div>
            <Button
              disabled={!isTouched}
              icon
              labelPosition="left"
              size="mini"
              onClick={onSaveDraft}
              positive
            >
              <Icon name="save" />
              save
            </Button>
          </div>
        </VersionBox>
      </VersionBackground>
      {
        getSortedVersions(versions).map((version: any) => {
          return (
            <VersionInfo
              key={version.name}
              name={version.name}
              isSaved={version.isSaved}
              isPublished={version.isPublished}
              isCurrent={version.name === currentVersion}
              date={version.date}
              onPublishVersion={onPublishVersion}
              onSetCurrentVersion={onSetCurrentVersion}
            />
          );
        })
      }
    </Background>
  );
}

export default AssetVersionList;
