import React from "react";
import { Sidebar } from "@au-re/mosaik-elements";
import noop from "lodash.noop";

import { ModuleBackground, ModuleIcon, ModuleLabel, ModuleCategoryContainer } from "./ModuleList.styles";
import { tileCategoryDetails } from "../../../constants/types/tileCategories";

function ModuleItem(props: any) {
  const { src, label, onClick = noop } = props;
  return (
    <ModuleBackground onClick={onClick}>
      <ModuleIcon src={src} alt="" />
      <ModuleLabel>{label}</ModuleLabel>
    </ModuleBackground>
  );
}

function sortByCategories(items: any) {
  const map: any = {};

  items.forEach((item: any) => {
    const itemCategory = item.category || "default";
    if (!map[itemCategory]) map[itemCategory] = [];
    map[itemCategory].push(item);
  });

  return map;
}

function ModuleList(props: any) {
  const { onSelection = noop, modules = [], disabled } = props;

  const map = sortByCategories(modules);

  if (!modules.length) {
    return <p>no modules available..</p>;
  }

  return (
    <div>
      {
        Object.keys(map).map((category: any) => (
          <div key={tileCategoryDetails[category].name}>
            <Sidebar.Submenu.Headline
              label={tileCategoryDetails[category].name}
              icon={tileCategoryDetails[category].icon}
            />
            <ModuleCategoryContainer>
              {
                map[category].map((tile: any) => (
                  <ModuleItem
                    key={tile.id}
                    onClick={(e: any) => {
                      if (!disabled) onSelection(tile.id);
                      e.stopPropagation();
                    }}
                    src={tile.thumbnailURL}
                    label={tile.title}
                  />
                ))
              }
            </ModuleCategoryContainer>
          </div>
        ))
      }
    </div>
  );
}

export default ModuleList;
