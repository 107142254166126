import React from "react";
import get from "lodash.get";
import noop from "lodash.noop";
import isEmpty from "lodash.isempty";
import { Modal } from "@au-re/mosaik-elements";

import ModuleList from "../ModuleList/ModuleList";
import { ModuleCardImage, ModuleCardTitle, ModuleCardDescription, ModuleCardBackground } from "./ModuleCard.styles";
import { moduleTypeDetails } from "../../../constants/types/moduleTypes";

function ModuleListModal(props: any) {
  const { modules, open, onSelection, type, close = noop } = props;
  return (
    <Modal
      size="small"
      dimmer="inverted"
      closeOnDimmerClick
      open={open}
      onClose={close}
    >
      <Modal.Header>
        Select a
        {get(moduleTypeDetails[type], "name")}
      </Modal.Header>
      <Modal.Content>
        <ModuleList
          modules={modules}
          onSelection={(moduleId: string) => {
            onSelection(moduleId);
            close();
          }}
        />
      </Modal.Content>
    </Modal>
  );
}

function ModuleCard(props: any) {
  const { module: _module, modules = [], onSelection = noop, type, withModal } = props;
  const [isModalOpen, setModalOpen] = React.useState(false);
  return (
    <ModuleCardBackground style={props.style} onClick={(e: any) => { setModalOpen(true); }}>
      {withModal && (
        <ModuleListModal
          type={type}
          modules={modules}
          close={() => { setModalOpen(false); }}
          open={isModalOpen}
          onSelection={onSelection}
        />
      )}
      {
        isEmpty(module) && (
          <p>
            no
            {get(moduleTypeDetails[type], "name")}
            selected
          </p>
        )
      }
      {
        _module && (
          <>
            <ModuleCardImage alt="" src={_module.thumbnailURL} />
            <div>
              <ModuleCardTitle>{_module.title}</ModuleCardTitle>
              <ModuleCardDescription>{_module.description}</ModuleCardDescription>
            </div>
          </>
        )
      }
    </ModuleCardBackground>
  );
}

export default ModuleCard;
