import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

import collections from "../constants/database/collections";
import { hasRequestedTileMetaList, getTilesMetaAsList } from "../state-management/selectors/tiles.selectors";
import { getCurrentUserId } from "../state-management/selectors/user.selectors";

/**
 * Subscribe to the meta data of all tiles this user has access to
 */
function useTileList() {
  const userId = useSelector(getCurrentUserId);
  useFirestoreConnect(() => {
    if (!userId) {
      return [
        {
          collection: collections.TILES_META,
          where: ["isPublic", "==", true],
          storeAs: "tiles_meta_public",
        }];
    }
    return [
      {
        collection: collections.TILES_META,
        where: [`permissions.${userId}`, ">", ""],
        storeAs: "tiles_meta_shared",
      },
      {
        collection: collections.TILES_META,
        where: ["isPublic", "==", true],
        storeAs: "tiles_meta_public",
      }];
  });

  const tiles = useSelector(getTilesMetaAsList);
  const hasFetched = useSelector(hasRequestedTileMetaList);

  return { tiles, hasFetched };
}

export default useTileList;
