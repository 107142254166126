import React from "react";
import { SubHeader, Button, Icon, Input, Container } from "@au-re/mosaik-elements";
import styled from "styled-components";
import escapeRegExp from "lodash.escaperegexp";
import filter from "lodash.filter";
import { useSelector } from "react-redux";

import BasePageLayout from "../../layouts/BasePageLayout/BasePageLayout";
import ItemGrid from "../../elements/ItemGrid/ItemGrid";

import { filterCategory } from "../../../helpers/utils";
import { getCurrentUserId } from "../../../state-management/selectors/user.selectors";
import useNavigation from "../../../hooks/useNavigation";
import { createTile } from "../../../state-management/requests/tiles.requests";
import useTileList from "../../../hooks/useTileList";

const SearchInput = styled.div`
  .ui.icon.input > input {
    border-radius: 2rem;
  }
`;

function TileListPage() {

  const userId = useSelector(getCurrentUserId);
  const { tiles } = useTileList();
  const { goToTileEditor, goToTilePreview } = useNavigation();

  const [tab, selectTab] = React.useState(userId ? "my" : "explore");
  const [items, selectItems] = React.useState(filterCategory(tab, userId, tiles));

  const canEdit = !!userId;

  // if the tiles update remotely, update the displayed list
  React.useEffect(() => {
    selectItems(filterCategory(tab, userId, tiles));
  }, [userId, tiles, tab]);

  // if the user is not logged in, default to the explore tab
  React.useEffect(() => {
    if (!userId) selectTab("explore");
    if (userId) selectTab("my");
  }, [userId]);

  const onSearchChange = (e: any) => {
    const userInput = e.target.value;

    if (!userInput) return selectItems(filterCategory(tab, userId, tiles));

    const re = new RegExp(escapeRegExp(userInput), "i");
    const isMatch = (result: any) =>
      re.test(result.title) ||
      re.test(result.category) ||
      re.test(result.description);

    selectItems(filter(filterCategory(tab, userId, tiles), isMatch));
  };

  return (
    <BasePageLayout noFooter>
      <SubHeader>
        <SubHeader.Menu>
          {canEdit && (
            <SubHeader.Item
              active={tab === "my"}
              onClick={() => selectTab("my")}
            >
              My Tiles
            </SubHeader.Item>
          )}
          <SubHeader.Item
            active={tab === "explore"}
            onClick={() => selectTab("explore")}
          >
            Explore
          </SubHeader.Item>
        </SubHeader.Menu>
        {canEdit &&
          (
            <Button
              onClick={async () => {
                const tileId = await createTile();
                goToTileEditor(tileId);
              }}
              primary
              size="small"
              icon
              labelPosition="left"
            >
              <Icon name="plus" />
              create new tile
            </Button>
          )}
        <SearchInput className="ui search">
          <Input
            icon="search"
            placeholder="Search ..."
            onChange={onSearchChange}
          />
        </SearchInput>
      </SubHeader>
      <Container>
        {!items.length && "there are currently no tiles"}
        <ItemGrid
          onItemEdit={goToTileEditor}
          onItemSelection={goToTilePreview}
          userId={userId}
          items={items}
        />
      </Container>
    </BasePageLayout>
  );
}

export default TileListPage;
