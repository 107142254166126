import React from "react";
import { Popup } from "@au-re/mosaik-elements";
import styled from "styled-components";

const DescriptionPopup = styled(Popup)`
  &&&& {
    background: #212A38;
    color: white;
    ::before {
      background: #212A38;
    }
  }
`;

export default ({ trigger, description }: any) => {
  if (!description) return trigger;
  return (
    <DescriptionPopup
      basic
      size="mini"
      content={description}
      trigger={trigger}
    />
  );
};
