import React from "react";
import noop from "lodash.noop";
import isEmpty from "lodash.isempty";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { useGraphData } from "@au-re/weld";

import { onGraphState } from "../../../../state-management/selectors/graph.selectors";

/**
 * the request handler will be invoked when data is passed to the action
 * @param onPublish
 */
function makeRequestHandler(publish: (data: any) => void) {
  let initialized = false;
  return (data: any = {}) => {

    // avoid triggering when the defaults are loaded
    if (!initialized) {
      initialized = true;
      return;
    }

    const { method, url, headers, body } = data;

    if (!url || !method) return;

    let parsedBody = {};
    let parsedHeaders = {};

    try {
      parsedBody = JSON.parse(body || "{}");
      parsedHeaders = JSON.parse(headers || "{}");

      if (!isEmpty(parsedBody)) {
        axios({
          method,
          url,
          headers: parsedHeaders,
          data: parsedBody,
        })
          .then((response) => publish({ response }))
          .catch((error) => console.log(error));
      } else {
        axios({
          method,
          url,
          headers: parsedHeaders,
        })
          .then((response) => publish({ response }))
          .catch((error) => console.log(error));
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };
}

/**
 * An Action that performs HTTP Requests
 * @param {*} props
 */
function HTTPRequestAction(props: any) {
  const { action = {}, onPublish = noop } = props;

  // on first load, create a request handler
  const handler: any = React.useRef();
  React.useEffect(() => {
    if (onPublish && !handler.current) {
      handler.current = makeRequestHandler(onPublish);
    }
  }, [onPublish]);

  useGraphData(action.id, handler.current, onGraphState);

  return null;
}

export default HTTPRequestAction;
