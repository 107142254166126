import React from "react";

import { fetchUsersWithAccess } from "../state-management/requests/asset.requests";

/**
 * Fetch the users related to an asset, this is called again whenever the asset permission
 * list changes.
 * @param tileId
 */
function useAssetUsers(assetType: string, asset: any) {
  const [assetUsers, setAssetUsers] = React.useState([]);

  const { id, permissions } = asset || {} as any;

  React.useEffect(() => {

    (async () => {
      if (assetType && id) {
        const users: any = await fetchUsersWithAccess(assetType, id);
        setAssetUsers(users);
      }
    })();

  }, [assetType, id, permissions]);

  return assetUsers;
}

export default useAssetUsers;
