import assetTypes from "../types/assetTypes";

const collections = {
  USERS: "users",
  TILES_META: "tiles_meta",
  TILES_DETAIL: "tiles_detail",
  TILES_SECRET: "secrets",
  PROJECTS_META: "projects_meta",
  PROJECTS_DETAIL: "projects_detail",
  PROJECTS_SECRET: "projects_secret",
};

export const asset2Collection = {
  [assetTypes.PROJECTS]: {
    meta: collections.PROJECTS_META,
    details: collections.PROJECTS_DETAIL,
  },
  [assetTypes.TILES]: {
    meta: collections.TILES_META,
    details: collections.TILES_DETAIL,
  },
};

export default collections;
