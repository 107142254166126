import React from "react";
import { Footer, Page, colors } from "@au-re/mosaik-elements";

import Header from "../../elements/Header/Header";

function BasePageLayout(props: any) {
  const { children, inverted, background = "#fff" } = props;
  const { noFooter = false, withEditor = false, centerContent = false } = props;

  return (
    <Page>
      <Page.Body>
        <Header inverted={inverted} withEditor={withEditor} />
        <Page.Content center={centerContent} style={{ background }}>
          {children}
        </Page.Content>
        {
          !noFooter && (
            <Footer>
              <Footer.Title>mosaik</Footer.Title>
              <Footer.Menu>
                <Footer.Link>Terms and Conditions</Footer.Link>
                <Footer.Link>Privacy Policy</Footer.Link>
              </Footer.Menu>
              <Footer.Notice>
                © 2018 made with ❤ at the
                <a href="/" style={{ color: colors.font }}>
                  <strong> Fraunhofer Chalmers Center</strong>
                </a>
              </Footer.Notice>
            </Footer>
          )
        }
      </Page.Body>
    </Page>
  );
}

export default BasePageLayout;
