import React from "react";
import { Provider as ReduxProvider, useSelector } from "react-redux";
import { ReactReduxFirebaseProvider as FirebaseProvider, useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { createFirestoreInstance, actionTypes } from "redux-firestore";

import { configureStore } from "./configureStore";
import { firebase } from "./configureFirebase";
import { getAuth } from "./selectors/auth.selectors";
import collections from "../constants/database/collections";

const store = configureStore();

function Root(props: any) {
  const auth = useSelector(getAuth);
  useFirestoreConnect(() => {
    if (!isLoaded(auth) || isEmpty(auth)) return [];
    return [{ collection: collections.USERS, doc: auth.uid }];
  });
  return props.children;
}

export function Provider(props: any) {
  return (
    <ReduxProvider store={store}>
      <FirebaseProvider
        firebase={firebase}
        config={{
          onAuthStateChanged: (authData: any, firebase: any, dispatch: any) => {
            // Clear redux-firestore state if auth does not exist (i.e logout)
            if (!authData) dispatch({ type: actionTypes.CLEAR_DATA });
          },
        } as any}
        dispatch={store.dispatch}
        createFirestoreInstance={createFirestoreInstance}
      >
        <Root>
          {props.children}
        </Root>
      </FirebaseProvider>
    </ReduxProvider>
  );
}
