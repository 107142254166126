import { Headline, Container } from "@au-re/mosaik-elements";
import React from "react";

import ResetPasswordForm from "../../forms/ResetPasswordForm/ResetPasswordForm";
import BasePageLayout from "../../layouts/BasePageLayout/BasePageLayout";
import { resetPassword } from "../../../state-management/requests/auth.requests";
import useNavigation from "../../../hooks/useNavigation";

function ResetPasswordPage(props: any) {
  const { oobCode } = props;
  const { goToHome } = useNavigation();

  return (
    <BasePageLayout centerContent>
      <Container narrow>
        <Headline as="h1">Reset Password</Headline>
        <ResetPasswordForm
          onSubmit={async (form: any) => {
            await resetPassword(oobCode, form.repeatPassword);
            goToHome();
          }}
        />
      </Container>
    </BasePageLayout>
  );
}

export default ResetPasswordPage;
