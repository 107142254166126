import { db } from "../configureFirebase";
import collections from "../../constants/database/collections";
import { requestStart, requestSuccess, requestError } from "../../helpers/utils";

/**
 * Update a secret
 *
 * @param {string} moduleId
 * @param {object} values
 */
export async function updateTileInstanceSecret(
  projectId: string | null,
  moduleId: string | null,
  values: any,
) {
  if (!projectId || !moduleId) throw new Error("missing identifiers");

  requestStart({
    showProgress: true,
  });

  const update: any = {};
  if (values.secret) update.secret = values.secret;
  if (values.authURL) update.authURL = values.authURL;

  try {
    await db
      .collection(collections.PROJECTS_SECRET)
      .doc(projectId)
      .collection("tiles")
      .doc(moduleId)
      .set(update);
    requestSuccess();
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Display a secret
 *
 * @param {string} moduleId
 */
export async function fetchTileInstanceSecret(projectId: string, moduleId: string | null) {
  if (!projectId || !moduleId) throw new Error("missing identifiers");
  const ref = await db
    .collection(collections.PROJECTS_SECRET)
    .doc(projectId)
    .collection("tiles")
    .doc(moduleId)
    .get();
  return ref.data();
}

/**
 * Update a secret
 *
 * @param {string} moduleId
 * @param {object} values
 */
export async function updateSecret(moduleId: string | null, values: any) {
  if (!moduleId) throw new Error("missing identifiers");
  const update: any = {};
  if (values.secret) update.secret = values.secret;
  if (values.authURL) update.authURL = values.authURL;

  return db.collection(collections.TILES_SECRET).doc(moduleId).update(update);
}

/**
 * Display a secret
 *
 * @param {string} moduleId
 */
export async function fetchSecret(moduleId: string) {
  const ref = await db.collection(collections.TILES_SECRET).doc(moduleId).get();
  return ref.data();
}

/**
 * update a flag indicating if this tile has remote auth enabled.
 *
 * Remote auth allows the users of the tile to log in to the tile
 * backend as mosaik users.
 *
 * @param {*} tileId
 * @param {boolean} enable
 */
export function setWithRemoteAuth(tileId: string, enable: boolean) {
  return db.collection(collections.TILES_DETAIL)
    .doc(tileId)
    .update({
      withRemoteAuth: enable,
    });
}
