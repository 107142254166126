import React from "react";
import { Dropdown, Headline, Image, List } from "@au-re/mosaik-elements";
import noop from "lodash.noop";
import permissions, { permissionDetails } from "../../../../../constants/types/permissionTypes";

const permissionDropdownOptions = [
  {
    icon: permissionDetails[permissions.VIEW].icon,
    text: permissionDetails[permissions.VIEW].name,
    value: permissions.VIEW,
  },
  {
    icon: permissionDetails[permissions.EDIT].icon,
    text: permissionDetails[permissions.EDIT].name,
    value: permissions.EDIT,
  },
  {
    icon: permissionDetails[permissions.OWNER].icon,
    text: permissionDetails[permissions.OWNER].name,
    value: permissions.OWNER,
  },
  { icon: "trash", text: "remove", value: null },
];

const UserRow = (props: any) => {
  const { photoURL, displayName, email } = props;
  const { permission = permissions.VIEW, onEdit = noop, inverted, readOnly } = props;
  return (
    <Headline as="h4" inverted={inverted}>
      <Image circular src={photoURL} />
      <Headline.Content>
        {displayName}
        <Headline.Subheader>{email}</Headline.Subheader>
      </Headline.Content>
      {
        readOnly
          ? <div style={{ float: "right" }}>{permissionDetails[permission].name}</div>
          : (
            <Dropdown
              inverted={inverted}
              onChange={(_: any, data: any) => onEdit({ email, permission: data.value })}
              style={{ float: "right" }}
              pointing
              floating
              defaultValue={permission}
              options={permissionDropdownOptions}
            />
          )
      }
    </Headline>
  );
};

const UserList = (props: any) => {
  const { users = [], inverted = false, onShare = noop, readOnly } = props;
  return (
    <List>
      {
        users.map((user: any) => (
          <UserRow
            readOnly={readOnly}
            inverted={inverted}
            key={user.email}
            onEdit={onShare}
            photoURL={user.photoURL}
            displayName={user.displayName}
            permission={user.permission}
            email={user.email}
          />
        ))
      }
    </List>
  );
};

export default UserList;
