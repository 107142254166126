import React from "react";
import { Container, RichText, Image, Icon } from "@au-re/mosaik-elements";
import styled from "styled-components";
import get from "lodash.get";
import { tileCategoryDetails } from "../../../constants/types/tileCategories";

const PreviewHeadline = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const HeadlineDescription = styled.div`
  flex: 1;
  margin-left: 1rem;

  h2 {
    font-family: Lato, sans-serif;
    font-weight: normal;
  }
`;

const InfoPanel = styled.div`
  display: flex;
  > * {
    margin-right: 2rem;
  }
`;

const TilePreview = (props: any) => {
  const { thumbnailURL, author, category, description, documentation, ...rest } = props;
  return (
    <Container text {...rest} style={{ overflow: "hidden" }}>
      <PreviewHeadline>
        {thumbnailURL && <Image src={thumbnailURL} size="medium" rounded />}
        <HeadlineDescription>
          <h2>
            {description}
          </h2>
          <InfoPanel>
            {
              author && (
                <div>
                  <Icon name="user" />
                  {author}
                </div>
              )
            }
            <div>
              <Icon name={get(tileCategoryDetails, `[${category}].icon`) as any} />
              {get(tileCategoryDetails, `[${category}].name`)}
            </div>
          </InfoPanel>
        </HeadlineDescription>
      </PreviewHeadline>
      <RichText text={documentation} />
    </Container>
  );
};

export default TilePreview;
