import { Tab, Segment, theme } from "@au-re/mosaik-elements";
import styled from "styled-components";

export const Background = styled.div`
  position: relative;
  padding: 1rem;
  height: 100%;
  background: ${theme.background};

  .Resizer.vertical {
    margin: 0 .25rem;
  }
`;

export const Preview = styled(Segment)`
 &&&& {
  height: 100%;
  width: 100%;
  border:  1px solid ${theme.borderColor};
  box-shadow: none;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;
  background: white;
 }`;

export const EditorPanelWrapper = styled.div`
  height: 100%;
  background: ${theme.background};
`;

export const EditorPanel = styled(Tab)`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${theme.background};

  &&&& .item {
    color: ${theme.textSecondary};
  }

  &&&& .item.active {
    border-color: ${theme.borderColor};
    background: ${theme.backgroundTertiary};
    color: ${theme.textPrimary};
  }

  &&&& .ui.attached.tabular.menu {
    border-color: ${theme.borderColor};
  }

  &&&& .ui.attached.segment {
    border-color: ${theme.borderColor};
  }
`;

export const EditorWrapper = styled(Tab.Pane)`
  height: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  top: -2rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${theme.background};
  opacity: 0.5;
  z-index: 1000;
`;
