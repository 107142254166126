import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { weldReducer, weldMiddleware } from "@au-re/weld";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import themeReducer from "./reducers/theme.reducer";
import { getGraphState } from "./selectors/graph.selectors";
import projectEditorReducer from "./reducers/projectEditor.reducer";
import projectConnectionsReducer from "./reducers/projectConnections.reducer";
import tileEditorReducer from "./reducers/tileEditor.reducer";

export const history = createBrowserHistory();

const initialState = {};

const rootReducer = combineReducers({
  theme: themeReducer,
  router: connectRouter(history),
  tileEditor: tileEditorReducer,
  projectEditor: projectEditorReducer,
  project: combineReducers({
    graphState: weldReducer,
    connections: projectConnectionsReducer,
  }),
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

export function configureStore() {
  const middlewares: any = [
    weldMiddleware({}, getGraphState),
    routerMiddleware(history),
  ];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [
    middlewareEnhancer,
  ];
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const composedEnhancers = composeEnhancers(...enhancers);
  const store = createStore(rootReducer, initialState, composedEnhancers);
  return store;
}
