/* eslint-disable import/no-extraneous-dependencies */
// TEMPORARY FILE, to be move to mosaik-elements

import "brace/mode/javascript";
import "brace/mode/css";
import "brace/mode/html";
import "brace/theme/github";

import { asField } from "@au-re/formalizer";

import React from "react";
import { Input, Button, Modal, CodeEditor, Icon } from "@au-re/mosaik-elements";
import { Form } from "semantic-ui-react";
import ControlPopup from "./ControlPopup";

function StringControl(props: any) {
  const [modalVisible, setModalVisible] = React.useState(false);
  const { fieldState, fieldApi } = props;
  const { value = "" } = fieldState;
  const { setValue, setTouched } = fieldApi;

  const [modalText, setModalText] = React.useState(value);

  return (
    <>
      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <Modal.Header>
          edit field:
          {props.label}
        </Modal.Header>
        <Modal.Content style={{ padding: 0, display: "flex", overflow: "hidden" }}>
          <CodeEditor
            value={modalText}
            height="480px"
            mode="markdown"
            theme="clouds"
            onChange={(text: any) => setModalText(text)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            onClick={() => {
              setValue(modalText);
              setModalVisible(false);
            }}
          >
            <Icon name="save" />
            save
          </Button>
        </Modal.Actions>
      </Modal>
      <Form.Field>
        <ControlPopup
          description={props.description}
          trigger={(
            <label>{props.label}</label>
          )}
        />
        <Input action size="mini" fluid>
          <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={(e) => setTouched(true)}
          />
          <Button size="mini" icon="expand" onClick={() => setModalVisible(true)} />
        </Input>
      </Form.Field>
    </>
  );
}

export default asField(StringControl);
