import { Alert } from "@au-re/mosaik-elements";

import { auth, db, storage } from "../configureFirebase";
import collections from "../../constants/database/collections";
import { requestStart, requestSuccess, requestError, isLt2M, hasCorrectImageFormat, requestTransfer } from "../../helpers/utils";
import { reauthenticate } from "./auth.requests";
import buckets from "../../constants/database/buckets";

const ref = db.collection(collections.USERS);

/**
 * given an email return the user details
 *
 * @param {*} email
 */
export async function getUserIdByEmail(email: string) {
  const userRef = db.collection(collections.USERS).where(`email`, "==", email);
  const querySnapshot = await userRef.get();
  const users: any = [];
  querySnapshot.forEach((user) => users.push(user.id));
  return users[0]; // return first since user emails should be unique
}

/**
 * Create a user
 * @param {*} email
 * @param {*} password
 */
export async function createUser(email: string, password: string) {
  requestStart();
  try {
    await auth.createUserWithEmailAndPassword(email, password);
    await auth.currentUser.sendEmailVerification();
    requestSuccess({ notification: "your profile has been created" });
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Edit display name
 * @param {*} details
 */
export function editDisplayName(displayName: string) {
  const { uid } = auth.currentUser;
  return ref.doc(uid).update({ displayName });
}

/**
 * delete the current user
 *
 * @param {*} password
 */
export async function deleteUser(password: string) {
  requestStart();
  try {
    await reauthenticate(password);
    await auth.currentUser.delete();
    requestSuccess({ notification: "your account was successfully deleted" });
  } catch (error) {
    requestError({ error });
  }
}

/**
 * edit the email of the current user
 *
 * @param {*} newEmail
 * @param {*} password
 */
export async function editUserEmail(newEmail: string, password: string) {
  requestStart();
  const { uid } = auth.currentUser;
  await reauthenticate(password, auth.currentUser.email);
  await auth.currentUser.updateEmail(newEmail);
  await ref.doc(uid).set({ email: newEmail }, { merge: true });
  requestSuccess({ notification: "your email has been updated" });
}

/**
 * edit the password of the current user
 *
 * @param {*} newPassword
 * @param {*} password
 */
export async function editUserPassword(newPassword: string, password: string) {
  requestStart();
  await reauthenticate(password);
  await auth.currentUser.updatePassword(newPassword);
  requestSuccess({ notification: "your email has been updated" });
}

/**
 * delete the profile picture of the current user
 *
 */
export async function deleteProfilePic() {
  const { uid } = auth.currentUser;
  const storageRef = storage.ref(`/${buckets.USERS}/${uid}/images/profile.jpg`);
  await storageRef.delete();
  return db.collection(collections.USERS).doc(uid).set({ photoURL: null }, { merge: true });
}

/**
 * Upload a profile picture for the current user
 *
 * @param {*} file
 */
export function uploadProfilePic(file: any) {
  if (!hasCorrectImageFormat(file)) return Alert.error("You can only upload JPG or PNG files");
  if (!isLt2M(file)) return Alert.error("Image must be smaller than 2MB");

  requestStart();

  const { uid } = auth.currentUser;
  const storageRef = storage.ref(`/${collections.USERS}/${uid}/images/profile.jpg`);
  const task = storageRef.put(file);

  const onSuccess = async () => {
    try {
      const downloadURL = await storageRef.getDownloadURL();
      await db.collection(collections.USERS).doc(uid)
        .set({ photoURL: downloadURL }, { merge: true });
      requestSuccess();
    } catch (error) {
      requestError({ error });
    }
  };

  task.on("state_changed", requestTransfer, requestError, onSuccess);
}
