import assetTypes from "./types/assetTypes";
import renderTypes from "./types/renderTypes";
import editorTypes from "./types/editorTypes";

export default {
  BASE_URL: `https://${process.env.REACT_APP_AUTH_DOMAIN}`,
  DOCUMENTATION_URL: "https://mosaik-fcc.gitbook.io/mosaik/",
  HOME: "/",
  ASSET: `/:renderType(${renderTypes.EDITOR}|${renderTypes.PREVIEW})/:assetType(${assetTypes.PROJECTS}|${assetTypes.TILES})/:assetId`,
  LIST: `/:assetType(${assetTypes.PROJECTS}|${assetTypes.TILES})`,
  EDITOR: `/:renderType(${renderTypes.EDITOR}|${renderTypes.PREVIEW})/:assetType(${assetTypes.PROJECTS}|${assetTypes.TILES})/:assetId/:editor(${editorTypes.DOCS}|${editorTypes.GRAPH}|${editorTypes.DASHBOARD}|${editorTypes.SOURCE})`,
  PAGE: `/:renderType(${renderTypes.EDITOR}|${renderTypes.PREVIEW})/:assetType(${assetTypes.PROJECTS}|${assetTypes.TILES})/:assetId/:editor(${editorTypes.DOCS}|${editorTypes.GRAPH}|${editorTypes.DASHBOARD}|${editorTypes.SOURCE})/page/:pageId`,
  PROJECTS: `/:assetType(${assetTypes.PROJECTS})`,
  PROJECT_PREVIEW: `/:renderType(${renderTypes.PREVIEW})/:assetType(${assetTypes.PROJECTS})/:assetId`,
  PROJECT_PREVIEW_PAGE: `/:renderType(${renderTypes.PREVIEW})/:assetType(${assetTypes.PROJECTS})/:assetId/dashboard/page/:pageId`,
  PROJECT_EDITOR: `/:renderType(${renderTypes.EDITOR})/:assetType(${assetTypes.PROJECTS})/:assetId`,
  PROJECT_EDITOR_GRAPH: `/:renderType(${renderTypes.EDITOR})/:assetType(${assetTypes.PROJECTS})/:assetId/graph`,
  PROJECT_EDITOR_DASHBOARD: `/:renderType(${renderTypes.EDITOR})/:assetType(${assetTypes.PROJECTS})/:assetId/dashboard`,
  PROJECT_EDITOR_PAGE: `/:renderType(${renderTypes.EDITOR})/:assetType(${assetTypes.PROJECTS})/:assetId/dashboard/page/:pageId`,
  TILES: `/:assetType(${assetTypes.TILES})`,
  TILE_PREVIEW: `/:renderType(${renderTypes.PREVIEW})/:assetType(${assetTypes.TILES})/:assetId`,
  TILE_EDITOR: `/:renderType(${renderTypes.EDITOR})/:assetType(${assetTypes.TILES})/:assetId`,
  TILE_EDITOR_SOURCE: `/:renderType(${renderTypes.EDITOR})/:assetType(${assetTypes.TILES})/:assetId/source`,
  TILE_EDITOR_DOCUMENTATION: `/:renderType(${renderTypes.EDITOR})/:assetType(${assetTypes.TILES})/:assetId/docs`,
  SIGN_IN: "/signin",
  REGISTER: "/register",
  REQUEST_NEW_PASSWORD: "/password/forgot",
  SETTINGS: "/settings",
  RESET_PASSWORD: "/auth",
  MOSAIK_TILE_SERVICE: process.env.REACT_APP_TILE_UPLOAD_SERVICE,
  MOSAIK_ACTION_SERVICE: process.env.REACT_APP_ACTION_UPLOAD_SERVICE,
  MOSAIK_TILE_DB: process.env.REACT_APP_TILE_STORAGE,
  MOSAIK_ACTION_DB: process.env.REACT_APP_ACTION_STORAGE,
  MOSAIK_ACCESS_TOKEN_SERVICE: process.env.REACT_APP_ACCESS_TOKEN_SERVICE,
  MOSAIK_ASSET_TOKEN_SERVICE: process.env.REACT_APP_ASSET_TOKEN_SERVICE,
};
