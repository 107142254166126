import "brace/mode/markdown";
import "brace/theme/clouds";

import isEmpty from "lodash.isempty";
import { CodeEditor, RichText, SplitPane, Container, Label } from "@au-re/mosaik-elements";
import React from "react";
import styled from "styled-components";
import useNavigation from "../../../../../hooks/useNavigation";
import { updateTileDocumentation } from "../../../../../state-management/requests/tiles.requests";
import useTileData from "../../../../../hooks/useTileData";

const Background = styled.div`
  display: flex;
`;

const DocumentationEditor = (props: any) => {
  const { assetId } = useNavigation();
  const { tile } = useTileData(assetId);

  if (isEmpty(tile)) return null;

  return (
    <Background {...props}>
      <SplitPane split="vertical" defaultSize={480}>
        <div style={{ height: "100%" }}>
          <CodeEditor
            tabSize={2}
            fontSize={14}
            mode="markdown"
            theme="clouds"
            height="100%"
            value={tile.documentation}
            debounceChangePeriod={1000}
            onChange={(text: string) => updateTileDocumentation(tile.id, text)}
          />
        </div>
        <Container style={{ padding: "0 1rem" }}>
          <Label attached="top left">preview</Label>
          <RichText text={tile.documentation} />
        </Container>
      </SplitPane>
    </Background>
  );
};

export default DocumentationEditor;
