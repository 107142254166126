import get from "lodash.get";
import orderBy from "lodash.orderby";
import { getCurrentUserId } from "./user.selectors";
import permissions from "../../constants/types/permissionTypes";
import { mapToArray } from "../../helpers/utils";

/**
 * Return the tiles meta as list (sorted by title)
 * @param state
 */
export function getTilesMetaAsList(state: any) {
  const tiles = {
    ...get(state, `firestore.data.tiles_meta_public`, {}),
    ...get(state, `firestore.data.tiles_meta_shared`, {}),
  };

  return orderBy(mapToArray(tiles), ["title"], ["asc"]);
}

/**
 * Return all data to the tile in tile editor
 * @param id
 */
export function getCurrentTile(state: any) {
  // read from the ordered list, because it contains the id of the document
  const metaData = get(state, `firestore.ordered.tile_preview_meta[0]`);
  const detailsData = get(state, `firestore.ordered.tile_preview_detail[0]`);
  // somehow a redux-firestore failed request still stores something under the object
  // we check if the apiVersion field is set to bypass this issue
  if (!metaData || !detailsData) return null;
  if (!metaData.apiVersion || !detailsData.apiVersion) return null;
  return {
    ...metaData,
    ...detailsData,
  };
}

/**
 * Return all data to a given tile by id
 * @param id
 */
export function getTileById(id: string) {
  return (state: any) => {
    const metaData = get(state, `firestore.data.tiles_meta[${id}]`, {});
    const detailsData = get(state, `firestore.data.tiles_detail[${id}]`, {});
    return {
      id,
      ...metaData,
      ...detailsData,
    };
  };
}

/**
 * Return true if all data to a tile is fetched
 * @param id
 */
export function hasRequestedTileDetails(state: any) {
  return get(state, `firestore.status.requested.tile_preview_meta`) &&
    get(state, `firestore.status.requested.tile_preview_detail`);
}

/**
 * Return true if all data to tile meta is fetched
 * @param id
 */
export function hasRequestedTileMetaList(state: any) {
  return get(state, `firestore.status.requested.tiles_meta`);
}

/**
 * Return true if a tile can be edited
 * @param id
 */
export function canEditCurrentTile(id: string) {
  return (state: any) => {
    const userId = getCurrentUserId(state);
    const tile = getCurrentTile(id)(state);
    return (
      tile.permissions[userId] === permissions.OWNER ||
      tile.permissions[userId] === permissions.EDIT);
  };
}
