import get from "lodash.get";

/**
 * Return the current state of a user by id from the redux store
 * @param state
 */
export function getUserById(id: string) {
  return (state: any) => {
    return get(state, `firestore.data.users[${id}]`, null);
  };
}

/**
 * Return the current user
 * @param state
 */
export function getCurrentUser(state: any) {
  const currentUserId = get(state, "firebase.auth.uid", "");
  return get(state, `firestore.data.users[${currentUserId}]`, null);
}

/**
 * Return the current user id or null if not logged in
 * @param state
 */
export function getCurrentUserId(state: any) {
  return get(state, "firebase.auth.uid", null);
}
