import React from "react";
import { Sidebar, theme } from "@au-re/mosaik-elements";
import styled from "styled-components";
import get from "lodash.get";
import { useSelector } from "react-redux";

import packageJSON from "../../../../../../package.json";

import AssetDetailMenu from "../../../../menus/AssetDetailMenu/AssetDetailMenu";
import DangerZoneMenu from "../../../../menus/DangerZoneMenu/DangerZoneMenu";
import TileSecretMenu from "./menus/TileSecretMenu/TileSecretMenu";
import ParameterEditorMenu from "./menus/ParameterEditorMenu/ParameterEditorMenu";

import { updateAssetCategory, updateAssetDescription } from "../../../../../state-management/requests/asset.requests";
import ThemeToggle from "../../../../elements/ThemeToggle/ThemeToggle";
import useNavigation from "../../../../../hooks/useNavigation";
import { deleteTile } from "../../../../../state-management/requests/tiles.requests";
import { getCurrentUserId } from "../../../../../state-management/selectors/user.selectors";
import tileCategories, { tileCategoryDetails } from "../../../../../constants/types/tileCategories";
import assetTypes from "../../../../../constants/types/assetTypes";
import permissions from "../../../../../constants/types/permissionTypes";
import { getCurrentTile } from "../../../../../state-management/selectors/tiles.selectors";

export const tileEditorMenus = {
  INTERACTIONS: "INTERACTIONS",
  INFO: "INFO",
  OPTIONS: "OPTIONS",
  SOURCE: "SOURCE",
  DOCUMENTATION: "DOCUMENTATION",
};

const VersionNumber = styled.div`
  color: ${theme.textSecondary};
  opacity: .6;
  font-size: 10px;
`;

const menu = {
  [tileEditorMenus.INFO]: "Info",
  [tileEditorMenus.OPTIONS]: "Options",
};

const categoryDropdownOptions = [
  {
    key: tileCategories.VISUALIZATION,
    text: tileCategoryDetails[tileCategories.VISUALIZATION].name,
    value: tileCategories.VISUALIZATION,
    icon: { name: tileCategoryDetails[tileCategories.VISUALIZATION].icon },
  },
  {
    key: tileCategories.INTERFACE,
    text: tileCategoryDetails[tileCategories.INTERFACE].name,
    value: tileCategories.INTERFACE,
    icon: { name: tileCategoryDetails[tileCategories.INTERFACE].icon },
  }];

function TileEditorSidebar() {
  const tile = useSelector(getCurrentTile);
  const {
    assetId,
    goToDocumentation,
    goToTileList,
    goToProjectList } = useNavigation();
  const userId = useSelector(getCurrentUserId);
  const [title, setTitle] = React.useState(tileEditorMenus.OPTIONS);

  const isOwner = get(tile, `permissions[${userId}]`) === permissions.OWNER;

  return (
    <Sidebar id="sidebar" defaultMenu={menu[tileEditorMenus.INFO]} title={title}>
      <Sidebar.Menu>
        <Sidebar.Icons>
          <Sidebar.Item
            nav
            onClick={() => setTitle(menu[tileEditorMenus.INFO])}
            canOpen
            id={tileEditorMenus.INFO}
            popupMessage={menu[tileEditorMenus.INFO]}
            icon="info circle"
          />
          <Sidebar.Item
            nav
            onClick={() => setTitle(menu[tileEditorMenus.OPTIONS])}
            canOpen
            id={tileEditorMenus.OPTIONS}
            popupMessage={menu[tileEditorMenus.OPTIONS]}
            icon="cog"
          />
        </Sidebar.Icons>

        <Sidebar.MenuContent id={tileEditorMenus.INFO}>
          <AssetDetailMenu
            open
            category={tile.category}
            categories={categoryDropdownOptions}
            description={tile.description}
            onCategoryChange={(category: string) =>
              updateAssetCategory(assetTypes.TILES, assetId, category)}
            onDescriptionChange={(description: string) =>
              updateAssetDescription(assetTypes.TILES, assetId, description)}
          />
        </Sidebar.MenuContent>

        <Sidebar.MenuContent id={tileEditorMenus.OPTIONS}>
          <ParameterEditorMenu
            asset={tile}
          />
          {isOwner && <TileSecretMenu asset={tile} />}
          {isOwner && (
            <DangerZoneMenu
              title="Delete Tile"
              onDelete={() => {
                deleteTile(assetId);
                goToTileList();
              }}
            />
          )}
        </Sidebar.MenuContent>
      </Sidebar.Menu>

      <Sidebar.Icons>
        <Sidebar.Item
          onClick={goToProjectList}
          popupMessage="Projects"
          text="Projects"
          icon="home"
        />
        <Sidebar.Item
          onClick={goToTileList}
          popupMessage="Tiles"
          text="Tiles"
          icon="th"
        />
        <Sidebar.Item
          onClick={goToDocumentation}
          popupMessage="Documentation"
          text="Documentation"
          icon="question circle"
        />
      </Sidebar.Icons>
      <Sidebar.Footer>
        <div>
          <ThemeToggle />
          <VersionNumber>
            mosaik version
            {packageJSON.version}
          </VersionNumber>
        </div>
      </Sidebar.Footer>
    </Sidebar>
  );
}

export default TileEditorSidebar;
