import React from "react";
import { Sidebar, TextArea, Dropdown } from "@au-re/mosaik-elements";
import noop from "lodash.noop";

function AssetDetailMenu(props: any) {
  const { open, description, category, categories } = props;
  const { onDescriptionChange = noop, onCategoryChange = noop } = props;

  return (
    <Sidebar.Submenu
      open={open}
      id="asset-details-menu"
      title="Details"
    >
      <Sidebar.Submenu.Headline label="category" icon="info circle" />
      <Dropdown
        inverted
        fluid
        defaultValue={category}
        placeholder="Select Category"
        selection
        onChange={(e: any, { value }: any) => onCategoryChange(value)}
        options={categories}
      />
      <Sidebar.Submenu.Headline label="description" icon="text cursor" />
      <TextArea
        defaultValue={description}
        style={{ minHeight: 140, resize: "none" }}
        inverted
        onBlur={(e: any) => onDescriptionChange(e.target.value)}
      />
    </Sidebar.Submenu>
  );
}

export default AssetDetailMenu;
