import IframeTileEditor from "../../components/project/tiles/IframeTile/IframeTileEditor";
import IframeTile from "../../components/project/tiles/IframeTile/IframeTile";
import integratedTiles from "../types/integratedTiles";
import tileCategories from "../types/tileCategories";

const integratedTileComponents = {
  [integratedTiles.IFRAME_TILE]: {
    id: integratedTiles.IFRAME_TILE,
    component: IframeTile,
    editor: IframeTileEditor,
    category: tileCategories.BUILT_IN,
    editorName: "Embedded Website Parameters",
    icon: "world",
    title: "Embedded Website",
    description: "Embed an external website into Mosaik",
    thumbnailURL: "https://storage.cloud.google.com/mosaik-dev-44b4c.appspot.com/static/launcher-icon.png",
  },
};

export default integratedTileComponents;
