import validate from "validate.js";

export const exists = (field: string) =>
  validate.single(field, { presence: { allowEmpty: false } });

export const isValidEmail = (field: string) =>
  validate.single(field, { email: true });

export const isValidURL = (field: string) =>
  validate.single(field, { url: { allowLocal: true } });

export const isValidPassword = (password = "") => {
  const hasMinLength = password.length >= 7;
  const containsNumber = /\d/.test(password);
  const containsLetter = /[a-z]/.test(password);

  if (hasMinLength && containsLetter && containsNumber) return;
  if (!hasMinLength) return "the password is too short";
  if (!containsLetter) return "the password should contain at least one letter";
  if (!containsNumber) return "the password should contain at least one number";
  return "the password doesn't match the criteria";
};

export const passwordMatch = (item: any) => (value: any, values: any) =>
  isValidPassword(value) || (value === values[item]
    ? undefined
    : "the passwords you entered don't match");

/**
 * Ensure a value starts with http
 *
 * @param {string} value
 */
export function ensureHTTPProtocol(value = "") {
  if (!value) return value;
  if (value.startsWith("http://") || value.startsWith("https://")) return value.trim();
  return `http://${value.trim()}`;
}
