import HTTPRequestActionEditor from "../../components/project/actions/HTTPRequestAction/HTTPRequestActionEditor";
import CustomActionEditor from "../../components/project/actions/CustomAction/CustomActionEditor";
import HTTPRequestAction from "../../components/project/actions/HTTPRequestAction/HTTPRequestAction";
import CustomAction from "../../components/project/actions/CustomAction/CustomAction";
import actionTypes from "../types/actionTypes";

const actionTypeComponents = {
  [actionTypes.HTTP_REQUEST_ACTION]: {
    id: actionTypes.HTTP_REQUEST_ACTION,
    component: HTTPRequestAction,
    editor: HTTPRequestActionEditor,
    editorName: "HTTP Request Editor",
    icon: "http",
    title: "Http Request",
    description: "define a custom HTTP request",
    thumbnailURL: "https://storage.cloud.google.com/mosaik-dev-44b4c.appspot.com/static/launcher-icon.png",
  },
  [actionTypes.CUSTOM_ACTION]: {
    id: actionTypes.CUSTOM_ACTION,
    component: CustomAction,
    editor: CustomActionEditor,
    editorName: "Source Editor",
    icon: "code",
    title: "Custom Action",
    description: "run custom javascript code",
    thumbnailURL: "https://storage.cloud.google.com/mosaik-dev-44b4c.appspot.com/static/launcher-icon.png",
  },
};

export default actionTypeComponents;
