import styled from "styled-components";

export const ModuleIcon = styled.img`
  height: 5rem;
  width: 5rem;
  background: white;
  border-radius: 6px;
  align-self: center;
  object-fit: cover;
  transition: transform .15s ease-in-out;
`;

export const ModuleLabel = styled.div`
  color: #96A1A9;
  text-align: center;
  font-size: .9rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ModuleBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 6rem;
  overflow: hidden;
  border-radius: 4px;
  padding-top: .25rem;
  margin: .25rem;
  cursor: pointer;
  :hover {
    ${ModuleIcon} {
      transform: scale(1.05);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const ModuleCategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 320px;
  overflow: auto;
`;
