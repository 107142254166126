import React from "react";
import styled from "styled-components";
import { Button, Form } from "@au-re/mosaik-elements";
import get from "lodash.get";

import { isValidEmail } from "../../../helpers/validation";
import permissions from "../../../constants/types/permissionTypes";

const hasRequiredValues = ({ values = {} }: any) => values.email && values.permission;

const ShareAssetForm = ({ loading, onSubmit, className }: any) =>
  (
    <Form
      className={className}
      id="share-asset-form"
      initialValues={{ permission: permissions.VIEW }}
      onSubmit={onSubmit}
    >
      {({ formState }: any) => {
        return (
          <>
            <div style={{ marginRight: "12px", flex: 1 }}>
              <Form.Text
                size="small"
                withErrorMessage
                action={(
                  <Form.Dropdown
                    style={{ minWidth: "120px" }}
                    button
                    pointing
                    floating
                    defaultValue={permissions.VIEW}
                    field="permission"
                    id="permission"
                    values={[
                      { icon: "eye", label: "can view", id: permissions.VIEW },
                      { icon: "edit", label: "can edit", id: permissions.EDIT },
                    ]}
                  />
                )}
                fluid
                icon="mail"
                field="email"
                id="email"
                placeholder="email you want to share to"
                errors={get(formState, "errors.email")}
                validateOnChange
                validate={isValidEmail}
              />
            </div>
            <Button
              style={{ height: "36px", width: "80px", padding: 0 }}
              size="small"
              loading={loading}
              disabled={formState.invalid || !hasRequiredValues(formState)}
              primary
              type="submit"
            >
              Invite
            </Button>
          </>
        );
      }}
    </Form>
  );

export default styled(ShareAssetForm)`
  display: flex;
  flex-direction: row;
`;
