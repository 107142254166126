import { SET_TILE_SOURCE } from "../actions/tileEditor.actions";

const initialState = {
  source: {
    html: "",
    css: "",
    js: "",
  },
};

/**
 *
 * @param state
 * @param action
 */
function tileEditorReducer(state: any = initialState, action: any) {

  if (action.type === SET_TILE_SOURCE) {
    const { source } = action.payload;
    return {
      ...state,
      source: {
        ...state.source,
        ...source,
      },
    };
  }

  return state;
}

export default tileEditorReducer;
