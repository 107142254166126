const dataPersistenceTypes = {
  NONE: "NONE",
  LOCAL: "LOCAL",
  CLOUD: "CLOUD",
};

export const dataPersistenceDetails = {
  [dataPersistenceTypes.NONE]: {
    name: "No Persistence",
    icon: "close",
  },
  [dataPersistenceTypes.LOCAL]: {
    name: "Local Storage",
    icon: "database",
  },
  [dataPersistenceTypes.CLOUD]: {
    name: "Cloud Storage",
    icon: "cloud",
  },
};

export default dataPersistenceTypes;
