import React from "react";
import { SquareCard, Grid } from "@au-re/mosaik-elements";
import noop from "lodash.noop";
import get from "lodash.get";
import permissions from "../../../constants/types/permissionTypes";
import { tileCategoryDetails } from "../../../constants/types/tileCategories";

function ItemGrid(props: any) {
  const { userId, items = [] } = props;
  const { onItemSelection = noop, onItemEdit = noop } = props;

  return (
    <Grid container>
      {
        items.map((item: any) => {
          const permission = get(item, `permissions[${userId}]`, null);
          const readOnly = permission === permissions.VIEW || permission === null;

          return (
            <Grid.Column
              key={item.id}
              widescreen={3}
              largeScreen={3}
              computer={4}
              tablet={4}
              mobile={8}
            >
              <SquareCard
                readOnly={readOnly}
                onCardSelection={() => onItemSelection(item.id)}
                onCardEdit={() => onItemEdit(item.id)}
                title={item.title}
                description={item.description}
                icon={get(tileCategoryDetails, `[${item.category}].icon`, "")}
                subtitle={get(tileCategoryDetails, `[${item.category}].name`, "") as any}
                src={item.thumbnailURL}
              />
            </Grid.Column>
          );
        })
      }
    </Grid>
  );
}

export default ItemGrid;
