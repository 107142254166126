import { theme } from "@au-re/mosaik-elements";
import styled from "styled-components";

export const ModuleCardImage = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 4px;
  background: ${theme.backgroundTertiary};
  display: inline-block;
  object-fit: cover;
  margin-right: 1rem;
`;

export const ModuleCardTitle = styled.div`
  font-size: 1.25rem;
  color: ${theme.textPrimary};
  margin-bottom: .5rem;
`;

export const ModuleCardDescription = styled.div`
  font-size: .8rem;
  color: ${theme.textSecondary};
`;

export const ModuleCardBackground = styled.div`
  display: flex;
  height: calc(3rem + 8px);
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  background: ${theme.backgroundSecondary};

  :hover {
    opacity: .8;
  }
`;
