const tileCategories = {
  VISUALIZATION: "VISUALIZATION",
  INTERFACE: "INTERFACE",
  BUILT_IN: "BUILT_IN",
};

export const tileCategoryDetails = {
  [tileCategories.BUILT_IN]: {
    icon: "boxes",
    name: "Built In",
  },
  [tileCategories.VISUALIZATION]: {
    icon: "chart bar",
    name: "Visualization",
  },
  [tileCategories.INTERFACE]: {
    icon: "sliders horizontal",
    name: "Interface",
  },
  default: {
    icon: "boxes",
    name: "Default",
  },
};

export default tileCategories;
