import React from "react";
import { Modal, Button, Input, Divider, Checkbox } from "@au-re/mosaik-elements";
import noop from "lodash.noop";

import paths from "../../../constants/paths";
import { makeLocation } from "../../../helpers/utils";
import UserList from "./components/UserList/UserList";
import ShareAssetForm from "../../forms/ShareAssetForm/ShareAssetForm";
import { updateAssetAccessToken, updateAssetPermissionByEmail, makeAssetPublic } from "../../../state-management/requests/asset.requests";
import renderTypes from "../../../constants/types/renderTypes";

function ShareAssetModal(props: any) {
  const { open, trigger, title, asset, assetType, assetUsers, readOnly = true } = props;
  const { onClose = noop } = props;

  const [loading, setLoading] = React.useState(false);

  if (!asset) return null;

  const handleSubmission = async ({ email, permission }: any) => {
    setLoading(true);
    await updateAssetPermissionByEmail(assetType, asset.id, email.toLowerCase(), permission);
    setLoading(false);
  };

  const pathOptions = { renderType: renderTypes.PREVIEW, assetType, assetId: asset.id };

  return (
    <Modal
      size="tiny"
      trigger={trigger}
      open={open}
      onClose={onClose}
      closeOnEscape
      closeOnDimmerClick
    >
      <Modal.Header style={{ display: "flex", justifyContent: "space-between" }}>
        {title || `Share ${asset.title}`}
        <Checkbox
          defaultChecked={asset.isPublic}
          onChange={(e: any) => makeAssetPublic(assetType, asset.id, e.target.checked)}
          label="Make Visible"
          inverted
          size="small"
        />
      </Modal.Header>
      <Modal.Content style={{ display: "flex", flexDirection: "column" }}>
        <ShareAssetForm loading={loading} onSubmit={handleSubmission} />
        <div style={{ marginBottom: "1rem" }}>
          share with link
          <Input
            value={asset.accessToken
              ? `${paths.BASE_URL}${makeLocation(paths.PROJECT_PREVIEW, pathOptions)}?token=${asset.accessToken}`
              : ""}
            style={{ width: "100%" }}
            readOnly={readOnly}
            label={(
              <Button
                primary
                onClick={() => updateAssetAccessToken(assetType, asset.id)}
                icon="refresh"
              />
            )}
          />
        </div>
        <Divider />
        <UserList readOnly={readOnly} users={assetUsers} onShare={handleSubmission} />
      </Modal.Content>
    </Modal>
  );
}

export default ShareAssetModal;
