import React from "react";
import { Button, Icon } from "@au-re/mosaik-elements";
import { ThemeContext } from "styled-components";
import { useDispatch } from "react-redux";
import modes from "../../../constants/themes";
import { setMode } from "../../../state-management/actions/theme.actions";

function ThemeToggle(props: any) {
  const { mode } = React.useContext(ThemeContext);
  const dispatch = useDispatch();
  return (
    <Button.Group size="mini" style={{ height: "2.4rem" }}>
      <Button
        secondary={mode === modes.DARK}
        active={mode === modes.LIGHT}
        icon
        onClick={() => dispatch(setMode(modes.LIGHT))}
      >
        <Icon name="sun" />
      </Button>
      <Button
        secondary={mode === modes.DARK}
        active={mode === modes.DARK}
        icon
        onClick={() => dispatch(setMode(modes.DARK))}
      >
        <Icon name="moon" />
      </Button>
    </Button.Group>
  );
}

export default ThemeToggle;
