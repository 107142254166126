import React from "react";
import { Checkbox, Sidebar, Dropdown } from "@au-re/mosaik-elements";
import get from "lodash.get";
import { useSelector } from "react-redux";

import ModuleCard from "../../../../../../elements/ModuleCard/ModuleCard";
import { updateTileEditor, getTileVersions } from "../../../../../../../state-management/requests/tiles.requests";
import { getTileById } from "../../../../../../../state-management/selectors/tiles.selectors";
import useTileList from "../../../../../../../hooks/useTileList";
import assetTypes from "../../../../../../../constants/types/assetTypes";
import { getSortedVersions, getVersionOptions } from "../../../../../../../helpers/utils";

function ParameterEditorMenu(props: any) {
  const { asset } = props;
  const { isCustom, tileId, versionId } = get(asset, "editor", {});

  const tileEditor = useSelector(getTileById(tileId));
  const { tiles } = useTileList();

  const onVersionUpdate = (_: any, { value }: any) => {
    updateTileEditor(asset.id, { versionId: value });
  };

  const [versions, setVersions] = React.useState([]);

  React.useEffect(() => {
    if (tileId) {
      const unsub = getTileVersions(tileId, setVersions);
      return () => unsub && unsub();
    }
  }, [tileId]);

  return (
    <Sidebar.Submenu
      id="parameter-editor-menu"
      title="Parameter Editor"
      headerComponent={(
        <Checkbox
          defaultChecked={isCustom}
          onChange={(e: any) => {
            updateTileEditor(asset.id, { isCustom: e.target.checked });
          }}
          label="Use Custom Editor"
          size="small"
        />
      )}
    >
      {isCustom && (
        <>
          <ModuleCard
            type={assetTypes.TILES}
            withModal
            module={tileEditor}
            modules={tiles}
            onSelection={(tileId: string) =>
              updateTileEditor(asset.id, { tileId })}
          />
          <Sidebar.Submenu.Headline label="version" />
          <Dropdown
            key={tileId}
            placeholder="Select Version"
            fluid
            selection
            defaultValue={versionId}
            onChange={onVersionUpdate}
            options={getVersionOptions(getSortedVersions(versions))}
          />
        </>
      )}
    </Sidebar.Submenu>
  );
}

export default ParameterEditorMenu;
