import { SET_MODE } from "../actions/theme.actions";
import themes from "../../constants/themes";

const initialState = {
  mode: localStorage.getItem("mosaik.mode") || themes.LIGHT,
};

function themeReducer(state: any = initialState, action: any) {

  if (action.type === SET_MODE) {
    const newMode = action.payload.mode;
    localStorage.setItem("mosaik.mode", newMode);
    return { mode: newMode };
  }

  return state;
}

export default themeReducer;
