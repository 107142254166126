const nodeDataTypes = {
  number: "number",
  string: "string",
  boolean: "boolean",
  datetime: "datetime",
  colorhex: "colorhex",
  enum: "enum",
  object: "object",
  any: "any",
};

const number = {
  color: "#29abe1",
  validTargets: [
    nodeDataTypes.number,
    nodeDataTypes.any,
  ],
};

const numberControl = {
  controlType: "InputControl",
  controlProps: {
    type: "number",
    readOnly: true,
  },
};

const string = {
  color: "#f8932b",
  validTargets: [
    nodeDataTypes.enum,
    nodeDataTypes.string,
    nodeDataTypes.any,
  ],
};

const stringControl = {
  controlType: "InputControl",
  controlProps: {
    readOnly: true,
  },
};

const boolean = {
  color: "#893168",
  validTargets: [
    nodeDataTypes.boolean,
    nodeDataTypes.any,
  ],
};

const booleanControl = {
  controlType: "InputControl",
  controlProps: {
    type: "checkbox",
    readOnly: true,
  },
};

const colorhex = {
  color: "#93e1d8",
  validTargets: [
    nodeDataTypes.colorhex,
    nodeDataTypes.string,
    nodeDataTypes.any,
  ],
};

const colorhexControl = {
  controlType: "InputControl",
  controlProps: {
    type: "color",
    readOnly: true,
  },
};

const mozEnum = {
  color: "#303036",
  validTargets: [
    nodeDataTypes.string,
    nodeDataTypes.any,
  ],
};

const mozEnumControl = {
  controlType: null,
  controlProps: {},
};

const mozObject = {
  color: "#6de096",
  validTargets: [
    nodeDataTypes.any,
    nodeDataTypes.object,
  ],
};

const mozObjectControl = {
  controlType: null,
  controlProps: {},
};

const datetime = {
  color: "#ad71f7",
  validTargets: [
    nodeDataTypes.any,
    nodeDataTypes.datetime,
    nodeDataTypes.string,
  ],
};

const datetimeControl = {
  controlType: "InputControl",
  controlProps: {
    type: "datetime-local",
  },
};

const any = {
  color: "#f760a6",
  validTargets: [
    nodeDataTypes.any,
  ],
};

const anyControl = {
  controlType: null,
  controlProps: {},
};

export const nodeDataTypesDetails = {
  [nodeDataTypes.number]: number,
  [nodeDataTypes.string]: string,
  [nodeDataTypes.boolean]: boolean,
  [nodeDataTypes.colorhex]: colorhex,
  [nodeDataTypes.datetime]: datetime,
  [nodeDataTypes.enum]: mozEnum,
  [nodeDataTypes.object]: mozObject,
  [nodeDataTypes.any]: any,
};

export const nodeDataTypesControls = {
  [nodeDataTypes.number]: numberControl,
  [nodeDataTypes.string]: stringControl,
  [nodeDataTypes.boolean]: booleanControl,
  [nodeDataTypes.colorhex]: colorhexControl,
  [nodeDataTypes.datetime]: datetimeControl,
  [nodeDataTypes.enum]: mozEnumControl,
  [nodeDataTypes.object]: mozObjectControl,
  [nodeDataTypes.any]: anyControl,
};

export default nodeDataTypes;
