import styled from "styled-components";

const EditorPageLayout = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: grid;

  #header {
    grid-area: header;
  }

  #sidebar {
    grid-area: sidebar;
  }

  #content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
  }

  grid-template-rows: auto  1fr;
  grid-template-columns: auto  1fr;
  grid-template-areas:
    "header header"
    "sidebar content";
`;

export default EditorPageLayout;
