import shortid from "shortid";
import request from "superagent";
import get from "lodash.get";
import moment from "moment";

import { defaultTileDetails, defaultTileMeta } from "../../constants/defaults";
import { requestStart, requestSuccess, requestError, readBlob } from "../../helpers/utils";
import paths from "../../constants/paths";
import { auth, db, storage } from "../configureFirebase";
import collections, { asset2Collection } from "../../constants/database/collections";
import buckets from "../../constants/database/buckets";
import assetTypes from "../../constants/types/assetTypes";

/**
 * Create a tile
 *
 * - a cloud function is triggered that will create all needed resources
 *
 */
export async function createTile() {
  requestStart();
  try {
    if (!auth.currentUser) throw new Error("user not logged in");
    const { uid } = auth.currentUser;
    const metaCollection = asset2Collection[assetTypes.TILES].meta;
    const detailsCollection = asset2Collection[assetTypes.TILES].details;
    const defaultMeta = defaultTileMeta(uid);
    const defaultDetails = defaultTileDetails();
    const id = shortid.generate();

    await Promise.all([
      db.collection(metaCollection).doc(id).set(defaultMeta),
      db.collection(detailsCollection).doc(id).set(defaultDetails),
    ]);
    requestSuccess();
    return id;
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Delete a Tile
 *
 * 1. remove all corresponding tile sources
 * 2. remove documents
 *
 * @param {*} id
 */
export async function deleteTile(id: string) {
  requestStart();
  try {
    if (!auth.currentUser) throw new Error("user not logged in");
    const metaCollection = asset2Collection[assetTypes.TILES].meta;
    await db.collection(metaCollection).doc(id).delete();
    requestSuccess();
    return id;
  } catch (error) {
    requestError({ error });
  }
}

/**
 * The parameter editor object contains information about the tiles parameter editor.
 * The definition for the default parameter editor,
 *
 * @param {*} tileId
 * @param {*} definition
 */
export async function updateTileEditor(tileId: string, options: any) {
  requestStart({ showProgress: false });
  try {
    if (!tileId) throw new Error("missing id");

    const update: any = {};
    if (options.isCustom !== undefined) update["editor.isCustom"] = options.isCustom;
    if (options.tileId !== undefined) update["editor.tileId"] = options.tileId;
    if (options.versionId !== undefined) update["editor.versionId"] = options.versionId;

    await db.collection(collections.TILES_DETAIL).doc(tileId).update(update);
    requestSuccess();
  } catch (error) {
    requestError({ error });
  }
}


/**
 * The parameter editor object contains information about the tiles parameter editor.
 * The definition for the default parameter editor,
 *
 * @param {*} tileId
 * @param {*} definition
 */
export async function updateTileInputSchema(tileId: string, inputSchema: any = []) {
  requestStart({ showProgress: false });
  try {
    if (!tileId) throw new Error("missing id");

    const update: any = {};
    update.draftInputSchema = inputSchema;
    update.isTouched = true;

    await db.collection(collections.TILES_DETAIL).doc(tileId).update(update);
    requestSuccess();
  } catch (error) {
    requestError({ error });
  }
}

/**
 * The parameter editor object contains information about the tiles parameter editor.
 * The definition for the default parameter editor,
 *
 * @param {*} tileId
 * @param {*} definition
 */
export async function updateTileOutputSchema(tileId: string, outputSchema: any = []) {
  requestStart({ showProgress: false });
  try {
    if (!tileId) throw new Error("missing id");

    const update: any = {};
    update.draftOutputSchema = outputSchema;
    update.isTouched = true;

    await db.collection(collections.TILES_DETAIL).doc(tileId).update(update);
    requestSuccess();
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Update the documentation of a tile
 * @param {*} tileId
 * @param {*} documentation
 */
export function updateTileDocumentation(tileId: string, documentation: string) {
  if (!tileId) throw new Error("missing id");
  return db.collection(collections.TILES_DETAIL).doc(tileId).update({ documentation });
}

/**
 * Return the source code from the selected version, the source code is stored in cloud storage to
 * avoid document size limitations in firebase
 * @param tileId
 * @param versionId
 */
async function getTileVersionSource(tileId: string, versionId: string) {
  try {
    const detailsCollection = asset2Collection[assetTypes.TILES].details;
    const tileRef = db.collection(detailsCollection).doc(tileId);
    const versionRef = tileRef.collection("versions").doc(versionId);
    const versionData = await versionRef.get();
    const sourceDataRequests = [];

    sourceDataRequests.push(request.get(get(versionData.data(), "sourceHTML", "")).responseType("blob")
      .then((res) => readBlob(res.body)));
    sourceDataRequests.push(request.get(get(versionData.data(), "sourceJS", "")).responseType("blob")
      .then((res) => readBlob(res.body)));
    sourceDataRequests.push(request.get(get(versionData.data(), "sourceCSS", "")).responseType("blob")
      .then((res) => readBlob(res.body)));

    const result: any = await Promise.all(sourceDataRequests);

    return {
      html: result[0],
      js: result[1],
      css: result[2],
    };
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Publish a tile: make it available for use by others
 *
 * @param {*} tileId
 * @param {*} data
 */
export async function publishTileVersion(tileId: string, versionId: string) {
  requestStart();
  try {
    const detailsCollection = asset2Collection[assetTypes.TILES].details;
    const metaCollection = asset2Collection[assetTypes.TILES].meta;
    const tileRef = db.collection(detailsCollection).doc(tileId);
    const metaTileRef = db.collection(metaCollection).doc(tileId);
    const versionRef = tileRef.collection("versions").doc(versionId);

    // fetch the source code from the selected version
    const source = await getTileVersionSource(tileId, versionId);

    await request
      .post(`${paths.MOSAIK_TILE_SERVICE}/${tileId}/${versionId}`)
      .set("Accept", "application/json")
      .send(source);

    // specify that this version is published
    versionRef.update({
      isPublished: true,
    });

    // specify that this tile has a published version
    metaTileRef.update({
      hasPublishedVersion: true,
    });

    requestSuccess({
      notification: "tile successfully published",
    });
  } catch (error) {
    requestError({ error });
  }
}

/**
 * get the source code for a tile
 *
 * @param {*} tile
 */
export async function getTileSource(source: any) {
  requestStart();
  try {
    const { html, js, css } = source;
    const promises = [];

    promises.push(request.get(html).responseType("blob")
      .then((res) => readBlob(res.body)));
    promises.push(request.get(js).responseType("blob")
      .then((res) => readBlob(res.body)));
    promises.push(request.get(css).responseType("blob")
      .then((res) => readBlob(res.body)));

    const result = await Promise.all(promises);
    requestSuccess();
    return result;
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Upload the source code for a tile. For each value, upload it to a file (js, css, html) then
 * update the tile_details with the download URL for the file.
 *
 * @param {*} tileId
 * @param {*} source
 */
export async function saveTileDraft(tileId: string, source: any) {
  const htmlRef = storage.ref(`/${buckets.TILES}/${tileId}/source/draft/tile.html`);
  const jsRef = storage.ref(`/${buckets.TILES}/${tileId}/source/draft/tile.js`);
  const cssRef = storage.ref(`/${buckets.TILES}/${tileId}/source/draft/tile.css`);

  const promises = [];

  if (source.html || source.html === "") promises.push(htmlRef.putString(source.html));
  if (source.js || source.js === "") promises.push(jsRef.putString(source.js));
  if (source.css || source.css === "") promises.push(cssRef.putString(source.css));

  // set the draft to touched
  const detailsCollection = asset2Collection[assetTypes.TILES].details;
  const tileRef = db.collection(detailsCollection).doc(tileId);
  tileRef.update({
    isTouched: true,
  });

  return Promise.all(promises);
}

/**
 * save the draft state of the tile to a new version
 * @param tileId
 */
export async function saveTileVersion(tileId: string) {
  requestStart();
  try {
    const detailsCollection = asset2Collection[assetTypes.TILES].details;
    const tileRef = db.collection(detailsCollection).doc(tileId);
    const draftData = await tileRef.get();

    const versionId = `v${get(draftData.data(), "numVersions", 0)}`;
    const versionRef = tileRef.collection("versions").doc(versionId);

    // increment the number of versions
    tileRef.update({
      isTouched: false,
      currentVersionId: versionId,
      numVersions: get(draftData.data(), "numVersions", 0) + 1,
    });

    // save new code version
    await versionRef.set({
      date: moment().format("MMMM Do, YYYY • h:mm a"),
      isPublished: false,
      inputSchema: get(draftData.data(), "draftInputSchema", {}),
      outputSchema: get(draftData.data(), "draftOutputSchema", {}),
      dependencies: [],
    });

    requestSuccess({ notification: "new version saved" });
  } catch (error) {
    requestError({ error });
  }
}

/**
 * get all versions of a given tile
 * @param tileId
 */
export function getTileVersions(tileId: string, onSnapshot: any) {
  try {
    const detailsCollection = asset2Collection[assetTypes.TILES].details;
    return db.collection(detailsCollection)
      .doc(tileId)
      .collection("versions")
      .onSnapshot((snapshot: any) => {
        const res: any = [];
        snapshot.forEach((doc: any) => {
          res.push({
            id: doc.id,
            name: doc.id,
            ...doc.data(),
          });
        });
        onSnapshot(res);
      });
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Set the current version of the tile, replace the draft code with the new version
 * @param tileId
 * @param versionId
 */
export async function setTileCurrentVersion(tileId: string, versionId: string) {
  requestStart();
  try {
    const detailsCollection = asset2Collection[assetTypes.TILES].details;
    const tileRef = db.collection(detailsCollection).doc(tileId);
    const versionRef = tileRef.collection("versions").doc(versionId);
    const versionData = await versionRef.get();

    // update the draft with the version data
    await tileRef.update({
      isTouched: false,
      currentVersionId: versionId,
      draftInputSchema: get(versionData.data(), "inputSchema", {}),
      draftOutputSchema: get(versionData.data(), "outputSchema", {}),
      draftDependencies: get(versionData.data(), "dependencies", {}),
    });

    const source: any = await getTileVersionSource(tileId, versionId);

    // replace the draft code with the code from the selected version
    const htmlRef = storage.ref(`/${buckets.TILES}/${tileId}/source/draft/tile.html`);
    const jsRef = storage.ref(`/${buckets.TILES}/${tileId}/source/draft/tile.js`);
    const cssRef = storage.ref(`/${buckets.TILES}/${tileId}/source/draft/tile.css`);

    const promises = [];

    promises.push(htmlRef.putString(source.html));
    promises.push(jsRef.putString(source.js));
    promises.push(cssRef.putString(source.css));

    await Promise.all(promises);

    requestSuccess({ notification: `version ${versionId} selected` });
  } catch (error) {
    requestError({ error });
  }
}
