import get from "lodash.get";
import orderBy from "lodash.orderby";
import moduleTypes from "../../constants/types/moduleTypes";
import { mapToArray } from "../../helpers/utils";

/**
 * Return the project meta
 * @param state
 */
export function getProjectsMeta(state: any) {
  return get(state, `firestore.data.projects_meta`, {});
}

/**
 * Return the project meta as list (sorted by title)
 * @param state
 */
export function getProjectsMetaAsList(state: any) {
  return orderBy(get(state, `firestore.ordered.projects_meta`, []), ["title"], ["asc"]);
}

/**
 * Return all data of a project
 * @param id
 */
export function getCurrentProject(state: any) {
  // read from the ordered list, because it contains the id of the document
  const metaData = get(state, `firestore.ordered.project_preview_meta[0]`);
  const detailsData = get(state, `firestore.ordered.project_preview_detail[0]`);
  // somehow a redux-firestore failed request still stores something under the object
  // we check if the apiVersion field is set to bypass this issue
  if (!metaData || !detailsData) return null;
  if (!metaData.apiVersion || !detailsData.apiVersion) return null;
  return {
    ...metaData,
    ...detailsData,
  };
}

/**
 * Return the Id of the current project
 * @param id
 */
export function getCurrentProjectId(state: any) {
  const metaData = get(state, `firestore.ordered.project_preview_meta[0]`);
  return metaData.id;
}

/**
 * Return the project graph as used by Weld
 * @param id
 */
export function getCurrentProjectGraph(state: any = {}) {
  const nodes = getCurrentProjectNodes(state);
  return {
    nodes: Object.keys(nodes)
      .map((key) => {
        return {
          id: key,
          initialState: JSON.parse(get(nodes, `[${key}].initialState`, "{}")),
          subscribers: nodes[key].subscribers,
          triggers: nodes[key].triggers,
        };
      })
      .reduce((acc: any, cur: any) => {
        const { id, ...rest } = cur;
        return { ...acc, [id]: rest };
      }, {}),
  };
}

/**
 * Return the project graph as used by Weld
 * @param id
 */
export function getCurrentProjectActions(projectModules: any = {}) {
  return Object.keys(projectModules)
    .filter((key) => projectModules[key].moduleType === moduleTypes.ACTION)
    .map((key) => {
      return {
        id: key,
        ...projectModules[key],
      };
    });
}

/**
 * Return all modules of the current project
 * @param id
 */
export function getCurrentProjectModules(state: any) {
  return get(getCurrentProject(state), "modules", {});
}

/**
 * Return all tiles of the current project
 * @param id
 */
export function getCurrentProjectTiles(state: any) {
  return mapToArray(get(getCurrentProject(state), "modules", {}))
    .filter((projectModule) => projectModule.moduleType === moduleTypes.TILE);
}

/**
 * Return all nodes of the current project
 * @param id
 */
export function getCurrentProjectNodes(state: any) {
  return get(getCurrentProject(state), "nodes", {});
}

/**
 * Given an id, returns a module of the current project
 * @param nodeId
 */
export function getCurrentProjectModuleById(nodeId: string | null) {
  return (state: any) => {
    if (!nodeId) return null;
    const detailsData = get(state, `firestore.data.project_preview_detail`, {});
    return get(detailsData, `modules[${nodeId}]`, {});
  };
}

/**
 * Return true if all the project data has been fetched
 * @param id
 */
export function hasRequestedProjectDetails(state: any) {
  return get(state, `firestore.status.requested.project_preview_meta`) &&
    get(state, `firestore.status.requested.project_preview_detail`);
}

/**
 * Return the current persistence level of the project
 * @param id
 */
export function getCurrentProjectPersistence(state: any) {
  return get(state, `firestore.data.project_preview_detail.persistence`);
}
