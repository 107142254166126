import { Headline, Container } from "@au-re/mosaik-elements";
import React from "react";

import SignInForm from "../../forms/SignInForm/SignInForm";
import BasePageLayout from "../../layouts/BasePageLayout/BasePageLayout";
import useNavigation from "../../../hooks/useNavigation";
import { signIn } from "../../../state-management/requests/auth.requests";

function SignInPage() {
  const [formApi, setFormApi] = React.useState();
  const [pending, setPending] = React.useState();
  const { goToProjectList } = useNavigation();

  const signInUser = async (form: any) => {
    try {
      setPending(true);
      await signIn(form.email, form.password, form.remember);
      goToProjectList();
    } catch (error) {

      if (error.code === "auth/user-not-found") {
        formApi.setError("email", ["this email doesn't match any existing user"]);
      }

      if (error.code === "auth/wrong-password") {
        formApi.setError("password", ["wrong password"]);
      }
      setPending(false);
    }
  };

  return (
    <BasePageLayout centerContent>
      <Container narrow>
        <Headline as="h1">Sign In</Headline>
        <SignInForm
          getApi={setFormApi}
          loading={pending}
          onSubmit={signInUser}
        />
      </Container>
    </BasePageLayout>
  );
}

export default SignInPage;
