import styled from "styled-components";
import { Input } from "@au-re/mosaik-elements";

export const DependencyRowStyled = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;

  * {
    margin: 0 .5rem;
  }

  ${Input} {
    flex: 1;
  }
`;

export const DependencyList = styled.div`
  display: flex;
  flex-direction: column;

  .ui.button {
    align-self: center;
  }
`;
