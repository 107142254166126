import React from "react";
import { Sidebar, Button, Headline, theme } from "@au-re/mosaik-elements";
import styled from "styled-components";
import noop from "lodash.noop";

import DeletePopup from "../../modals/DeleteModal/DeleteModal";

const HeadlineContent = styled(Headline.Content)`
  color: ${theme.textPrimary};
`;

const HeadlineSubheader = styled(Headline.Subheader)`
  color: ${theme.textSecondary} !important;
`;

function DangerZoneMenu(props: any) {
  const { open, title, onDelete = noop } = props;
  const [isDeletePopupVisible, setDeletePopupVisible] = React.useState(false);
  return (
    <Sidebar.Submenu
      id="danger-zone-menu"
      open={open}
      title="Danger Zone"
    >
      <DeletePopup
        title={title}
        open={isDeletePopupVisible}
        onDelete={onDelete}
        onClose={() => setDeletePopupVisible(false)}
      />
      <Headline as="h4" style={{ display: "flex", marginTop: "2rem" }}>
        <HeadlineContent style={{ marginRight: "1rem" }}>
          {title}
          <HeadlineSubheader style={{ fontFamily: "Lato", marginTop: "1rem" }}>
            Once deleted, it will be gone forever. Please be certain.
          </HeadlineSubheader>
        </HeadlineContent>
        <Button
          style={{ height: "2.8rem" }}
          size="small"
          color="red"
          onClick={() => setDeletePopupVisible(true)}>
          Delete
        </Button>
      </Headline>
    </Sidebar.Submenu>
  );
}

export default DangerZoneMenu;
