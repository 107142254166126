import { Sidebar } from "@au-re/mosaik-elements";
import React from "react";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";

import EditorPageLayout from "../../layouts/EditorPageLayout/EditorPageLayout";
import useNavigation from "../../../hooks/useNavigation";
import useTileData from "../../../hooks/useTileData";
import { getThemeMode } from "../../../state-management/selectors/theme.selectors";
import useAssetUsers from "../../../hooks/useAssetUsers";
import assetTypes from "../../../constants/types/assetTypes";
import TileEditor from "../../tile/TileEditor/TileEditor";
import useAssetRestriction from "../../../hooks/useAssetRestriction";

/**
 * In this page users can edit the source code, documentation and options of their tiles
 *
 * @param {*} props
 */
const TileEditorPage = () => {
  const { assetId, goToTileList } = useNavigation();
  const { tile, hasFetched }: any = useTileData(assetId);

  useAssetUsers(assetTypes.TILES, tile);

  useAssetRestriction(tile, hasFetched, true, goToTileList);

  if (!hasFetched || !tile) return null;

  return (
    <EditorPageLayout>
      <TileEditor />
    </EditorPageLayout>
  );
};

function TileEditorPageContainer() {
  const mode = useSelector(getThemeMode);
  return (
    <ThemeProvider theme={{ mode }}>
      <Sidebar.Provider>
        <TileEditorPage />
      </Sidebar.Provider>
    </ThemeProvider>
  );
}

export default TileEditorPageContainer;
