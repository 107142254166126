import { matchPath } from "react-router-dom";
import get from "lodash.get";
import qs from "query-string";
import { useSelector } from "react-redux";

import { makeLocation } from "../helpers/utils";
import paths from "../constants/paths";
import { history } from "../state-management/configureStore";
import renderTypes from "../constants/types/renderTypes";
import assetTypes from "../constants/types/assetTypes";
import editorTypes from "../constants/types/editorTypes";

/**
 * The useNavigation Hook provides helper functions to navigate to pages as well as the data stored
 * in the URL
 */
function useNavigation() {
  const location = useSelector((state: any) => state.router.location);

  const assetMatch = matchPath(location.pathname, {
    path: paths.ASSET,
    strict: true,
    exact: true,
  });

  const editorMatch = matchPath(location.pathname, {
    path: paths.EDITOR,
    strict: true,
    exact: true,
  });

  const listMatch = matchPath(location.pathname, {
    path: paths.LIST,
    strict: true,
    exact: true,
  });

  const pageMatch = matchPath(location.pathname, {
    path: paths.PAGE,
    strict: true,
    exact: true,
  });

  const match = assetMatch || listMatch || editorMatch || pageMatch;

  const params = {
    assetId: get(match, "params.assetId", null),
    assetType: get(match, "params.assetType", null),
    pageId: get(match, "params.pageId", null),
    editor: get(match, "params.editor", null),
    accessToken: qs.parse(location.search, { ignoreQueryPrefix: true } as any).token || null,
  };

  function goToProjectEditor(id: string) {
    const options = {
      renderType: renderTypes.EDITOR,
      assetType: assetTypes.PROJECTS,
      assetId: id,
    };
    history.push(makeLocation(paths.PROJECT_EDITOR, options));
  }

  function goToProjectPagePreview(projectId: string, pageId: string) {
    const options = {
      renderType: renderTypes.PREVIEW,
      assetType: assetTypes.PROJECTS,
      assetId: projectId,
      pageId,
    };
    history.push(makeLocation(paths.PROJECT_PREVIEW_PAGE, options));
  }

  function goToProjectPageEditor(projectId: string, pageId: string) {
    const options = {
      renderType: renderTypes.EDITOR,
      assetType: assetTypes.PROJECTS,
      assetId: projectId,
      pageId,
    };
    history.push(makeLocation(paths.PROJECT_EDITOR_PAGE, options));
  }

  function goToProjectPreview(id: string) {
    const options = {
      renderType: renderTypes.PREVIEW,
      assetType: assetTypes.PROJECTS,
      assetId: id,
    };
    history.push(makeLocation(paths.PROJECT_PREVIEW, options));
  }

  function goToProjectGraphEditor(id: string) {
    const options = {
      renderType: renderTypes.EDITOR,
      assetType: assetTypes.PROJECTS,
      assetId: id,
      editor: editorTypes.GRAPH,
    };
    history.push(makeLocation(paths.PROJECT_EDITOR_GRAPH, options));
  }

  function goToProjectDashboard(id: string) {
    const options = {
      renderType: renderTypes.EDITOR,
      assetType: assetTypes.PROJECTS,
      assetId: id,
      editor: editorTypes.DASHBOARD,
    };
    history.push(makeLocation(paths.PROJECT_EDITOR_DASHBOARD, options));
  }

  function goToTileSource(id: string) {
    const options = {
      renderType: renderTypes.EDITOR,
      assetType: assetTypes.TILES,
      assetId: id,
      editor: editorTypes.SOURCE,
    };
    history.push(makeLocation(paths.TILE_EDITOR_SOURCE, options));
  }

  function goToTileDocs(id: string) {
    const options = {
      renderType: renderTypes.EDITOR,
      assetType: assetTypes.TILES,
      assetId: id,
      editor: editorTypes.DOCS,
    };
    history.push(makeLocation(paths.TILE_EDITOR_DOCUMENTATION, options));
  }

  function goToTileEditor(id?: string) {
    const options = { renderType: renderTypes.EDITOR, assetType: assetTypes.TILES, assetId: id };
    history.push(makeLocation(paths.TILE_EDITOR, options));
  }

  function goToTilePreview(id: string) {
    const options = { renderType: renderTypes.PREVIEW, assetType: assetTypes.TILES, assetId: id };
    history.push(makeLocation(paths.TILE_PREVIEW, options));
  }

  function goToDocumentation() {
    window.open(paths.DOCUMENTATION_URL, "_blank");
  }

  function goToSettings() {
    history.push(paths.SETTINGS);
  }

  function goToHome() {
    history.push(paths.HOME);
  }

  function goToTileList() {
    history.push(makeLocation(paths.TILES, { assetType: assetTypes.TILES }));
  }

  function goToProjectList() {
    history.push(makeLocation(paths.PROJECTS, { assetType: assetTypes.PROJECTS }));
  }

  return {
    ...params,
    history,
    location,
    match,
    goToDocumentation,
    goToHome,
    goToProjectDashboard,
    goToProjectEditor,
    goToProjectGraphEditor,
    goToProjectList,
    goToProjectPageEditor,
    goToProjectPagePreview,
    goToProjectPreview,
    goToSettings,
    goToTileDocs,
    goToTileEditor,
    goToTileList,
    goToTilePreview,
    goToTileSource,
  };
}

export default useNavigation;
