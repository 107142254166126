const permissions = {
  OWNER: "OWNER",
  EDIT: "EDIT",
  VIEW: "VIEW",
};

export const permissionDetails = {
  [permissions.VIEW]: {
    icon: "eye",
    name: "can view",
  },
  [permissions.EDIT]: {
    icon: "edit",
    name: "can edit",
  },
  [permissions.OWNER]: {
    icon: "id badge",
    name: "owner",
  },
};


export default permissions;
