import React from "react";
import { Avatar, Popup, Menu, Icon, colors } from "@au-re/mosaik-elements";
import { Link } from "react-router-dom";
import styled from "styled-components";

import paths from "../../../constants/paths";
import { signOut } from "../../../state-management/requests/auth.requests";

const MenuHeadline = styled.div`
  text-transform: none;
  font-size: 1.1rem;

  p {
    margin-top: 2px;
    font-size: .9rem;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

function UserAvatar(props: any) {
  const { user } = props;
  if (!user) return null;
  return (
    <Popup
      position="bottom right"
      trigger={(
        <Avatar
          style={{ color: colors.font, borderColor: colors.font }}
          name={user.displayName || user.email}
          src={user.photoURL}
        />
      )}
    >
      <Menu text vertical>
        <Menu.Item header style={{ padding: 0, marginTop: 0 }}>
          <MenuHeadline>
            {user.displayName}
            <p>{user.email}</p>
          </MenuHeadline>
        </Menu.Item>
        <Menu.Item as={Link} to={paths.SETTINGS}>
          <Icon name="cog" />
          Account Settings
        </Menu.Item>
        <Menu.Item onClick={signOut}>
          <Icon name="log out" />
          Log Out
        </Menu.Item>
      </Menu>
    </Popup>
  );
}

export default UserAvatar;
