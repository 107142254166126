import React from "react";
import noop from "lodash.noop";
import { Modal, Button, Headline } from "@au-re/mosaik-elements";

function DeleteModal(props: any) {
  const { open, resourceId, resourceType, trigger, title } = props;
  const { onClose = noop, onDelete = noop } = props;

  return (
    <Modal
      size="tiny"
      trigger={trigger}
      open={open}
      onClose={onClose}
      closeOnEscape
      closeOnDimmerClick
    >
      <Modal.Header style={{ display: "flex", justifyContent: "space-between" }}>
        {title || `Delete ${resourceId}`}
      </Modal.Header>
      <Modal.Content style={{ display: "flex" }}>
        <Headline as="h4">
          {resourceType && `Delete this ${resourceType}`}
          <Headline.Subheader style={{ fontWeight: "normal" }}>
            Once deleted, there is no going back.
            Please be certain.
          </Headline.Subheader>
        </Headline>
        <Button
          style={{ marginLeft: "24px", maxHeight: "3rem" }}
          color="red"
          onClick={(props) => {
            onDelete(props);
            onClose();
          }}
        >
          Delete
        </Button>
      </Modal.Content>
    </Modal>
  );
}

export default DeleteModal;
