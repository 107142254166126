import React from "react";
import { Dimmer, Loader, Message } from "@au-re/mosaik-elements";

import noop from "lodash.noop";
import { useGraphData } from "@au-re/weld";

import paths from "../../../constants/paths";
import useMosaikConnection from "../../../hooks/useMosaikConnection";
import useRemoteAuth from "../../../hooks/useRemoteAuth";
import { onGraphState } from "../../../state-management/selectors/graph.selectors";
import { UnauthorizedMessage, Iframe, DimmerContainer } from "./Tile.styles";

/**
 * The Tile component wraps the iframe with the module code.
 * It manages the communication to and from the tile.
 *
 * @param {*} props
 */
function Tile(props: any) {
  const { id, typeId, versionId, srcDoc, dimmed, authorized, withRemoteAuth } = props;
  const { onConnection = noop, onPublish = noop, onStateChange = noop } = props;

  const iframe = React.useRef(null);
  const [loading, setLoading] = React.useState(true);

  const remoteAuthId = withRemoteAuth ? typeId : null;
  const { setData, setAuth } =
    useMosaikConnection(iframe.current, onConnection, onPublish, onStateChange);

  useGraphData(id, setData, onGraphState);

  useRemoteAuth(remoteAuthId, setAuth);

  if (!authorized) {
    return (
      <UnauthorizedMessage negative>
        <Message.Header>Unauthorized</Message.Header>
        <p>You do not have permission to view this tile</p>
      </UnauthorizedMessage>
    );
  }

  if (!typeId) {
    return (
      <UnauthorizedMessage negative>
        <Message.Header>Missing Source</Message.Header>
        <p>Make sure to assign a source to this tile</p>
      </UnauthorizedMessage>
    );
  }

  return (
    <DimmerContainer dimmed>
      <Dimmer inverted active={loading || dimmed} />
      <Loader active={loading} />
      {
        !srcDoc && (
          <Iframe
            onLoad={() => setLoading(false)}
            ref={iframe}
            sandbox="allow-same-origin allow-scripts allow-forms"
            title="tile"
            src={`${paths.MOSAIK_TILE_DB}/${typeId}/${versionId}/index.html`}
          />
        )
      }
      {
        srcDoc && (
          <Iframe
            onLoad={() => setLoading(false)}
            ref={iframe}
            title="tile"
            srcDoc={srcDoc}
          />
        )
      }
    </DimmerContainer>
  );
}

export default Tile;
