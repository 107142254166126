import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import TileEditorHeader from "./components/TileEditorHeader/TileEditorHeader";
import TileEditorSidebar from "./components/TileEditorSidebar/TileEditorSidebar";
import paths from "../../../constants/paths";
import DocumentationEditor from "./components/DocumentationEditor/DocumentationEditor";
import TileCodeEditor from "./components/CodeEditor/CodeEditor";

function TileEditor() {
  return (
    <>
      <TileEditorSidebar />
      <TileEditorHeader />
      <div id="content">
        <Switch>
          <Route
            exact
            path={paths.TILE_EDITOR_SOURCE}
            component={TileCodeEditor}
          />
          <Route
            exact
            path={paths.TILE_EDITOR_DOCUMENTATION}
            component={DocumentationEditor}
          />
          <Redirect
            exact
            from={paths.TILE_EDITOR}
            to={paths.TILE_EDITOR_SOURCE}
          />
        </Switch>
      </div>
    </>
  );
}

export default TileEditor;
