import React from "react";
import { Route, useHistory } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";

import paths from "../../../constants/paths";
import { getAuth } from "../../../state-management/selectors/auth.selectors";

/**
 * A Route that can only be accessed given certain conditions
 *
 * @param {*} props
 */
function AuthRoute(props: any) {
  const {
    condition = () => true,
    component: Component,
    failureRedirect = paths.HOME,
    ...rest
  } = props;

  const auth = useSelector(getAuth);
  const history = useHistory();
  const loaded = isLoaded(auth);
  const authorized = condition(!isEmpty(auth));

  React.useEffect(() => {
    if (loaded && !authorized) history.push(failureRedirect);
  }, [loaded, authorized, history, failureRedirect]);

  if (loaded && authorized) {
    return <Route {...rest} component={Component} />;
  }

  return null;
}

export default AuthRoute;
