import "brace/mode/json";
import "brace/theme/clouds";

import isEqual from "lodash.isequal";
import React from "react";
import { Input, Dropdown, CodeEditor } from "@au-re/mosaik-elements";

import styled from "styled-components";
import { getNodeInitialState } from "@au-re/weld";
import { useSelector } from "react-redux";
import { getSelectedModule } from "../../../../state-management/selectors/projectEditor.selectors";
import { onGraphState } from "../../../../state-management/selectors/graph.selectors";
import { updateModuleInitialState } from "../../../../state-management/requests/project.requests";
import { getCurrentProjectId } from "../../../../state-management/selectors/projects.selectors";

const methods = {
  get: "get",
  post: "post",
  put: "put",
  patch: "patch",
  delete: "delete",
};

const Label = styled.label`
  display: block;
  margin-top: 1rem;
`;

function HTTPRequestActionEditor() {
  const projectId = useSelector(getCurrentProjectId);
  const actionId = useSelector(getSelectedModule);
  const initialState = useSelector(onGraphState(getNodeInitialState(actionId || "")));

  const defaultState = {
    url: initialState.url || "",
    method: initialState.method || methods.get,
    headers: initialState.headers || "{}",
    body: initialState.body || "{}",
  };

  const [formState, setFormState] = React.useState(defaultState);

  // when the formState changes update the module initial state
  React.useEffect(() => {
    if (isEqual(formState, defaultState)) return;
    updateModuleInitialState(projectId, actionId || "", formState);
  }, [projectId, actionId, defaultState, formState]);

  const updateFormState = (field: any) => {
    setFormState({ ...formState, ...field });
  };

  const handleJSONUpdate = (field: any, json: any) => {
    try {
      JSON.parse(json);
      updateFormState({ [field]: json });
    } catch (error) { /* */ }
  };

  return (
    <div>
      <Label>url</Label>
      <Input
        defaultValue={formState.url}
        fluid
        onBlur={(e: any) => {
          const url = e.target.value;
          updateFormState({ url });
        }}
        placeholder="enter a url"
        labelPosition="left"
        label={(
          <Dropdown
            onChange={(_: any, { value }: any) => updateFormState({ method: value })}
            defaultValue={formState.method}
            options={
              Object
                .keys(methods)
                .map((method) => ({ value: method, text: method, key: method }))
            }
          />
        )}
      />
      <Label>headers</Label>
      <CodeEditor
        value={formState.headers}
        tabSize={2}
        fontSize={14}
        theme="clouds"
        mode="json"
        height="200px"
        onChange={(json: any) => handleJSONUpdate("headers", json)}
      />
      <Label>body</Label>
      <CodeEditor
        value={formState.body}
        tabSize={2}
        fontSize={14}
        theme="clouds"
        mode="json"
        height="200px"
        onChange={(json: any) => handleJSONUpdate("body", json)}
      />
    </div>
  );
}

export default HTTPRequestActionEditor;
