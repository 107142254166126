import * as React from "react";
import { Sidebar, Form, Button, Checkbox } from "@au-re/mosaik-elements";
import styled from "styled-components";
import { isValidURL, ensureHTTPProtocol } from "../../../../../../../helpers/validation";
import { fetchSecret, setWithRemoteAuth, updateSecret } from "../../../../../../../state-management/requests/secret.requests";

const Field = styled.div`
  display: flex;
  margin-bottom: .45rem;
  justify-content: space-between;
  align-items: center;
`;

const FieldLabel = styled.label`
  font-size: .8rem;
  font-weight: 700;
`;

const ModuleOptionsButton = styled(Button)`
  &&&& {
    width: 120px;
    margin: auto;
    display: block;
  }
`;

function TileSecretMenu(props: any) {
  const { asset } = props;
  const [showSecret, setShowSecret] = React.useState(false);
  const [authURL, setAuthURL] = React.useState(null);
  const [secret, setSecret] = React.useState(null);

  React.useEffect(() => {
    // fetch the secret
    (async () => {
      const values = await fetchSecret(asset.id);
      if (!values) return;
      setAuthURL(values.authURL);
      setSecret(values.secret);
    })();
  }, [asset.id]);

  const canSubmit = (formState: any) => {
    return authURL !== formState.authURL
      || secret !== formState.secret;
  };

  const toggleSecret = () => {
    setShowSecret(!showSecret);
  };

  const onSubmit = (form: any) => {
    const cleanForm = {
      authURL: ensureHTTPProtocol(form.authURL),
      secret: form.secret,
    };
    updateSecret(asset.id, cleanForm);
  };

  return (
    <Sidebar.Submenu
      id="module-secret"
      title="Module Secret"
      headerComponent={(
        <Checkbox
          defaultChecked={asset.withRemoteAuth}
          onChange={(e: any) => setWithRemoteAuth(asset.id, e.target.checked)}
          label="Mosaik Auth"
          inverted
          size="small"
        />
      )}
    >
      <Form
        key={JSON.stringify({ authURL, secret })}
        initialValues={{ authURL, secret }}
        onSubmit={onSubmit}
        style={{ display: "flex" }}
      >
        {
          ({ formState }: any) => (
            <>
              <div style={{ marginBottom: ".5rem" }}>
                <Field>
                  <FieldLabel>auth url</FieldLabel>
                  <Form.Text
                    validateOnChange
                    validate={isValidURL}
                    size="small"
                    type="text"
                    id="authURL"
                    field="authURL"
                  />
                </Field>
                <Field>
                  <FieldLabel>module secret</FieldLabel>
                  <Form.Text
                    size="small"
                    type={showSecret ? "text" : "password"}
                    id="secret"
                    field="secret"
                    actionPosition="left"
                    action={{
                      icon: "eye",
                      onClick: toggleSecret,
                      type: "button",
                      primary: true,
                    }}
                  />
                </Field>
              </div>
              <ModuleOptionsButton
                primary
                disabled={!canSubmit(formState.values) || formState.invalid}
              >
                save
              </ModuleOptionsButton>
            </>
          )
        }
      </Form>
    </Sidebar.Submenu>
  );
}

export default TileSecretMenu;
