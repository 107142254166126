export const RESET_PROJECT_EDITOR = "@@MOSAIK/RESET_PROJECT_EDITOR";
export const SET_MODULE_CONNECTION = "@@MOSAIK/SET_MODULE_CONNECTION";
export const SELECT_MODULE = "@@MOSAIK/SELECT_MODULE";

export function resetProjectEditor() {
  return { type: RESET_PROJECT_EDITOR };
}

export function setModuleConnection(moduleId: string, isConnected: boolean) {
  const payload = { moduleId, isConnected };
  return { type: SET_MODULE_CONNECTION, payload };
}

export function selectModule(moduleId: string | null) {
  const payload = { moduleId };
  return { type: SELECT_MODULE, payload };
}
