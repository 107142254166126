import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import isEmpty from "lodash.isempty";

import collections from "../constants/database/collections";
import { getCurrentTile, hasRequestedTileDetails } from "../state-management/selectors/tiles.selectors";
import integratedTileComponents from "../constants/components/integratedTileComponents";
import integratedTiles from "../constants/types/integratedTiles";

/**
 * Subscribe to the data (meta/detail) of a tile with tileId
 * @param tileId
 */
function useTileData(tileId: string) {
  const isIntegratedTile = (integratedTiles as any)[tileId];
  useFirestoreConnect(() => {
    if (!tileId || isIntegratedTile) return [];
    return [
      {
        collection: collections.TILES_META,
        doc: tileId,
        storeAs: "tile_preview_meta",
      },
      {
        collection: collections.TILES_DETAIL,
        doc: tileId,
        storeAs: "tile_preview_detail",
      }];
  });

  const tile = useSelector(getCurrentTile);
  const hasFetched = useSelector(hasRequestedTileDetails);

  const currentTile = isEmpty(tile) ? null : {
    id: tileId,
    ...(isIntegratedTile ? integratedTileComponents[tileId] : tile),
  };

  if (isIntegratedTile) {
    return {
      tile: currentTile,
      hasFetched: true,
      isIntegratedTile: true,
    };
  }

  return { tile: currentTile, hasFetched };
}

export default useTileData;
