import React from "react";

import ResetPasswordPage from "../../pages/ResetPasswordPage/ResetPasswordPage";
import { applyActionCode, verifyPasswordResetCode } from "../../../state-management/requests/auth.requests";
import useNavigation from "../../../hooks/useNavigation";

function AuthActions() {
  const [email, setEmail] = React.useState();
  const { goToHome, location } = useNavigation();
  const params = new URLSearchParams(location.search);
  const oobCode = params.get("oobCode");
  const mode = params.get("mode");

  React.useEffect(() => {
    (async () => {

      if (mode === "verifyEmail") {
        applyActionCode(oobCode);
      }

      if (mode === "resetPassword") {
        try {
          const res = await verifyPasswordResetCode(oobCode);
          setEmail(res);
        } catch (error) {
          goToHome();
        }
      }
    })();
  }, [mode, oobCode, goToHome]);

  if (mode === "resetPassword" && email) {
    return <ResetPasswordPage oobCode={oobCode} />;
  }

  return null;
}

export default AuthActions;
