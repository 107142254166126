import React from "react";
import noop from "lodash.noop";
import { useGraphData } from "@au-re/weld";

import paths from "../../../../constants/paths";
import useMosaikConnection from "../../../../hooks/useMosaikConnection";
import { onGraphState } from "../../../../state-management/selectors/graph.selectors";

/**
 * The Action component wraps an iframe with module code.
 * It manages the communication to and from the Action.
 *
 * @param {*} props
 */
function CustomAction(props: any) {
  const { action = {}, onConnection = noop, onPublish = noop } = props;
  const iframe = React.useRef(null);

  const { setData }: any = useMosaikConnection(iframe.current, onConnection, onPublish);

  useGraphData(action.id, setData, onGraphState);

  return (
    <iframe
      style={{ display: "none" }}
      ref={iframe}
      sandbox="allow-same-origin allow-scripts"
      title="action"
      src={`${paths.MOSAIK_ACTION_DB}/${action.id}/index.html`}
    />
  );
}

export default CustomAction;
