import React from "react";
import { useSelector } from "react-redux";
import { getCurrentUserId } from "../state-management/selectors/user.selectors";
import permissions from "../constants/types/permissionTypes";

function canEditAsset(asset: any, userId: string) {
  return userId && asset &&
    (asset.permissions[userId] === permissions.OWNER ||
      asset.permissions[userId] === permissions.EDIT);
}

function useAssetRestriction(
  asset: any, isFetched: boolean, editorOnly: boolean, onAccessFail: () => void) {

  const userId = useSelector(getCurrentUserId);
  React.useEffect(() => {
    if (isFetched && (!asset || (editorOnly && !canEditAsset(asset, userId)))) {
      onAccessFail();
    }
  }, [asset, isFetched, onAccessFail, userId, editorOnly]);
}

export default useAssetRestriction;
