import React from "react";
import { SubHeader, Button, Icon, Input, Container } from "@au-re/mosaik-elements";
import styled from "styled-components";
import escapeRegExp from "lodash.escaperegexp";
import filter from "lodash.filter";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

import BasePageLayout from "../../layouts/BasePageLayout/BasePageLayout";
import { filterCategory } from "../../../helpers/utils";
import ItemGrid from "../../elements/ItemGrid/ItemGrid";
import useNavigation from "../../../hooks/useNavigation";
import { getCurrentUserId } from "../../../state-management/selectors/user.selectors";
import collections from "../../../constants/database/collections";
import { getProjectsMetaAsList } from "../../../state-management/selectors/projects.selectors";
import { createProject } from "../../../state-management/requests/project.requests";

const SearchInput = styled.div`
  .ui.icon.input > input {
    border-radius: 2rem;
  }
`;

function ProjectListPage() {
  const userId = useSelector(getCurrentUserId);

  useFirestoreConnect(() => {
    if (!userId) {
      return [
        {
          collection: collections.PROJECTS_META,
          where: ["isPublic", "==", true],
        }];
    }
    return [
      {
        collection: collections.PROJECTS_META,
        where: [`permissions.${userId}`, ">", ""],
        storeAs: "projects_meta",
      },
      {
        collection: collections.PROJECTS_META,
        where: ["isPublic", "==", true],
        storeAs: "projects_meta",
      }];
  });

  const projects = useSelector(getProjectsMetaAsList);
  const { goToProjectEditor, goToProjectPreview } = useNavigation();

  const [tab, selectTab] = React.useState(userId ? "my" : "explore");
  const [items, selectItems] = React.useState(filterCategory(tab, userId, projects));

  const canEdit = !!userId;

  // if the projects update remotely, update the displayed list
  React.useEffect(() => {
    selectItems(filterCategory(tab, userId, projects));
  }, [tab, userId, projects]);

  // if the user is not logged in, default to the explore tab
  React.useEffect(() => {
    if (!userId) selectTab("explore");
    if (userId) selectTab("my");
  }, [userId]);

  const onSearchChange = (e: any) => {
    const userInput = e.target.value;

    if (!userInput) return selectItems(filterCategory(tab, userId, projects));

    const re = new RegExp(escapeRegExp(userInput), "i");
    const isMatch = (result: any) =>
      re.test(result.title) ||
      re.test(result.category) ||
      re.test(result.description);

    selectItems(filter(filterCategory(tab, userId, projects), isMatch));
  };

  const onCreateProject = async () => {
    const id = await createProject();
    goToProjectEditor(id || "");
  };

  return (
    <BasePageLayout noFooter>
      <SubHeader>
        <SubHeader.Menu>
          {
            canEdit && (
              <SubHeader.Item
                active={tab === "my"}
                onClick={() => selectTab("my")}
              >
                My Projects
              </SubHeader.Item>
            )
          }
          <SubHeader.Item
            active={tab === "explore"}
            onClick={() => selectTab("explore")}
          >
            Explore
          </SubHeader.Item>
        </SubHeader.Menu>
        {
          canEdit && (
            <Button
              onClick={onCreateProject}
              primary
              size="small"
              icon
              labelPosition="left"
            >
              <Icon name="plus" />
              create new project
            </Button>
          )
        }
        <SearchInput className="ui search">
          <Input
            icon="search"
            placeholder="Search ..."
            onChange={onSearchChange}
          />
        </SearchInput>
      </SubHeader>
      <Container>
        {!items.length && "there are currently no projects"}
        <ItemGrid
          onItemEdit={goToProjectEditor}
          onItemSelection={goToProjectPreview}
          userId={userId}
          items={items}
        />
      </Container>
    </BasePageLayout>
  );
}

export default ProjectListPage;
