import * as shortid from "shortid";
import { lifeCycleTriggers } from "@au-re/weld";

import permissions from "./types/permissionTypes";
import tileCategories from "./types/tileCategories";
import projectLayoutTypes from "./types/projectLayoutTypes";
import dataPersistenceTypes from "./types/dataPersistenceTypes";
import nodeTypes from "./types/nodeTypes";

export const projectApiVersion = "3";
export const tileApiVersion = "3";

export const defaultGraphNodeLocation = {
  x: 20,
  y: 200,
};

export const defaultDashboardLocation = (id: string) => {
  return {
    i: id,
    x: 2,
    y: Infinity,
    w: 2,
    h: 8,
  };
};

export const defaultProjectMeta = (userId: string) => {
  return {
    apiVersion: projectApiVersion,
    title: "My Project",
    isPublic: false,
    category: "",
    permissions: {
      [userId]: permissions.OWNER,
    },
  };
};

const defaultInputSchema = {
  schema: [
    {
      type: "object",
      id: "input",
      label: "Input",
    },
  ],
};

const defaultOutputSchema = {
  schema: [
    {
      type: "object",
      id: "result",
      label: "Result",
    },
  ],
};

export const defaultProjectModule = (label: string) => {
  return {
    label,
  };
};

export const defaultCustomAction = () => {
  return {
    source: defaultActionSource(),
    inputSchema: JSON.stringify(defaultInputSchema, null, "\t"),
    outputSchema: JSON.stringify(defaultOutputSchema, null, "\t"),
  };
};

export const defaultProjectNode = () => {
  return {
    location: defaultGraphNodeLocation,
    initialState: "{}",
    subscribers: {},
    triggers: [],
  };
};


export const defaultProjectDetails = () => {
  return {
    apiVersion: projectApiVersion,
    persistence: dataPersistenceTypes.NONE,
    nodes: {
      [lifeCycleTriggers.ON_LOAD]: {
        nodeType: nodeTypes[lifeCycleTriggers.ON_LOAD],
        location: defaultGraphNodeLocation,
      },
    },
    modules: {},
    layout: projectLayoutTypes.DASHBOARD,
    layouts: {
      [projectLayoutTypes.DASHBOARD]: {
        pages: {
          [shortid.generate()]: {
            title: "Page 1",
            idx: 0,
          },
        },
      },
    },
  };
};

export const defaultTileMeta = (userId: string) => {
  return {
    apiVersion: tileApiVersion,
    title: "My Tile",
    isPublic: false,
    category: tileCategories.VISUALIZATION,
    permissions: { [userId]: permissions.OWNER },
  };
};

export const defaultTileDetails = () => {
  return {
    apiVersion: tileApiVersion,
    documentation: "",
    isTouched: false,
    currentVersionId: null,
    editor: {
      isCustom: false,
      tileId: null,
    },
    draftDependencies: [],
    draftInputSchema: defaultTileSchema(),
    draftOutputSchema: defaultTileSchema(),
  };
};

export const defaultActionSource = () => {
  return `/*
  An action allows you to run logic without the need for a visible tile in your dashboard.

  You can use an action to map incompatible data formats, fetch remote data and more.
  When done, call the connection.publish() function with the data you want to publish.

  By default we simply return the input we received.
*/
mosaik.connect((data, event) => {

  // run some logic here

  const { input } = data;
  event.connection.publish({ result: input });
});
`;
};

export const defaultTileSchema = () =>
  (JSON.stringify({ schema: [] }));
