import { Progress, Alert } from "@au-re/mosaik-elements";
import * as Sentry from "@sentry/browser";
import sortBy from "lodash.sortby";

import permissions from "../constants/types/permissionTypes";

/**
 * given a path "/:key1/asd/:key2(asd)", create a string /xyz/asd/foo for {key1: xyz, key2:foo}
 * @param path
 * @param props
 */
export function makeLocation(path: string, props: any) {
  path = path.replace(/\(.*?\)/g, "");
  Object.keys(props).forEach((key) => {
    path = path.replace(`:${key}`, props[key]);
  });
  return path;
}

const defaultError = (error: any) => `something went wrong: ${error.message}`;

/**
 * starts a request
 *
 * @param {*} options
 */
export function requestStart(options: any = {}) {
  const { showProgress = true, notification } = options;
  showProgress && Progress.start();
  notification && Alert.info(notification);
}

/**
 * display an error notification if something goes wrong with a request
 * log the issue to sentry if we do no know what went wrong
 *
 * @param {*} options
 */
export function requestError(options: any = {}) {
  const { showNotification = true, notification = defaultError(options.error), error } = options;
  if (showNotification) Alert.error(notification);
  if (!notification) Sentry.captureException(error);
  Progress.done();
}

/**
 * display a success notification when a request terminates
 *
 * @param {*} options
 */
export function requestSuccess(options: any = {}) {
  const { notification } = options;
  notification && Alert.success(notification);
  Progress.done();
}

/**
 * update the loading bar based on the progress of a transfer
 *
 * @param {*} snapshot
 */
export function requestTransfer(snapshot: any) {
  const percentage = (snapshot.bytesTransferred / snapshot.totalBytes);
  Progress.set(percentage - 0.1);
}

/**
 * only display the items belonging to one category, "my" || "public"
 * @param {*} tab
 * @param {*} user
 * @param {*} tiles
 */
export function filterCategory(tab: string, userId: string, assets: any) {
  if (tab === "my" && userId) {
    return assets.filter((asset: any) => (
      asset.permissions[userId] === permissions.OWNER ||
      asset.permissions[userId] === permissions.EDIT ||
      asset.permissions[userId] === permissions.VIEW));
  }
  return assets.filter((asset: any) => (asset.isPublic));
}

/**
 * convert a blob into text
 *
 * @param {*} blob
 */
export function readBlob(blob: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsText(blob);
  });
}

/**
 * ensure an upload has supported image type format
 * @param file
 */
export const hasCorrectImageFormat = (file: any) => {
  const isJPG = file.type === "image/jpeg";
  const isPNG = file.type === "image/png";
  const isGIF = file.type === "image/gif";
  return isJPG || isPNG || isGIF;
};

/**
 * limit uploads to 2M size
 * @param file
 */
export const isLt2M = (file: any) => file.size < 2 * 1024 * 1024;

/**
 * Transforms an array into a map of its elements with their id as keys
 *
 * e.g.
 * [{id: "", ...}] => { [id]: ...}
 *
 * @param {*} array
 * @param {*} options
 */
export const arrayToMap = (array: any, options: any = {}) => {
  const map = array.reduce((map: any, item: any, idx: number) => {
    const { id, ...rest } = item;
    const newItem = { ...rest };
    if (options.withIndex) newItem.idx = idx;
    map[id || idx] = newItem;
    return map;
  }, {});
  return map;
};

/**
 * Transforms a map into an array including their keys as id fields
 *
 * { [id]: ...} => [{id: "", ...}]
 *
 * @param {*} map
 */
export const mapToArray = (map: any) => {
  const array = Object.keys(map).map((key) => ({ id: key, ...map[key] }));
  return array;
};

/**
 * not just delete an item but also fix the idx values for the other items
 *
 * @param {string} itemId
 * @param {object} map
 */
export function deleteItemInSortedList(itemId: string, map: any = {}) {
  delete map[itemId];

  const sortedItems = sortBy(Object.keys(map)
    // sort the keys, order them by ascending idx
    .map((key) => ({ id: key, ...map[key] })), "idx")
    // replace the idx with the new value (order matters here)
    .map((item: any, idx: number) => ({ ...item, idx }));

  // transform array into object for better firestore support
  return arrayToMap(sortedItems);
}

/**
 * return an array of items sorted by index
 * @param {*} items
 */
export function getSorted(items: any = {}) {
  return sortBy(Object.keys(items).map((key: string) => ({ id: key, ...items[key] })), "idx");
}

const localStorageBasePath = "mosaik";

/**
 * write to the local store
 * @param {*} id
 * @param {*} data
 */
export function writeToLocalStorage(id: string, data: any) {
  try {
    localStorage.setItem(`${localStorageBasePath}/${id}`, JSON.stringify(data));
  } catch (error) {
    requestError({ error });
  }
}

/**
 * read from the local store
 * @param {*} id
 */
export function readFromLocalStorage(id: string) {
  const data: any = localStorage.getItem(`${localStorageBasePath}/${id}`);
  try {
    // this is very confusing, a single call to parse should suffice?
    const result = JSON.parse(JSON.parse(data));
    return result;
  } catch (error) {
    return data;
  }
}

/**
 * sort them inverse lexicographically
 *
 * @param versions
 */
export function getSortedVersions(versions: any[]) {
  return sortBy(versions, (version: any) => {
    const number = parseInt(version.id.substr(1), 10);
    return number;
  }).reverse();
}

/**
 * remove unpublished versions,
 * return a format suitable for drop-downs
 *
 * @param versions
 */
export function getVersionOptions(versions: any[]) {
  return versions
    .filter((version: any) => version.isPublished)
    .map((version: any = {}) => {
      return {
        key: version.id,
        text: version.id,
        value: version.id,
      };
    });
}
