const moduleTypes = {
  TILE: "TILE",
  ACTION: "ACTION",
};

export const moduleTypeDetails = {
  [moduleTypes.TILE]: {
    icon: "th",
    name: "tile",
    plural: "tiles",
  },
  [moduleTypes.ACTION]: {
    icon: "code",
    name: "action",
    plural: "actions",
  },
};

export default moduleTypes;
