import React from "react";
import mosaik from "@au-re/mosaik-events";
import get from "lodash.get";
import noop from "lodash.noop";

function useMosaikConnection(
  target: any, onConnection = noop, onPublish = noop, onStateChange = noop) {

  const [connection, setConnection] = React.useState(null);
  const [isConnecting, setConnecting] = React.useState(false);

  const connected = !!connection;
  const close = get(connection, "close", noop);
  const setData: any = get(connection, "setData");
  const setAuth = get(connection, "setAuth");

  // on connection change, let the parent component know
  const connectedRef = React.useRef(false);
  React.useEffect(() => {
    if (connected === connectedRef.current) return;
    connectedRef.current = connected;
    onConnection(connection);
  }, [connection, connected, connectedRef, onConnection]);

  // on load, connect to the target
  React.useEffect(() => {
    if (connected || !target || isConnecting) return;
    setConnecting(true);

    (async () => {
      // pass the data emitted by the tile to the graph
      const conn: any = await mosaik.connect({
        onPublish,
        onStateChange,
      }, { target });
      setConnection(conn);
      setConnecting(false);
    })();

    return () => {
      if (typeof close === "function") {
        close();
      }
    };
  }, [isConnecting, connected, target, onConnection, onPublish, onStateChange, close]);

  return {
    connected, close, setData, setAuth,
  };
}

export default useMosaikConnection;
