import "brace/mode/javascript";
import "brace/mode/css";
import "brace/mode/html";
import "brace/theme/github";

import noop from "lodash.noop";
import React from "react";
import { Button, Modal, CodeEditor } from "@au-re/mosaik-elements";

/** A modal used to edit code */

function CodeModal(props: any) {
  const { open = true, close = noop, code = "", onSave, Preview = null } = props;
  const { title, mode = "javascript" } = props;
  const [_code, setCode] = React.useState(code);

  React.useEffect(() => {
    setCode(props.code || "");
  }, [props.code]);

  return (
    <Modal size="large" open={open}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content style={{ padding: 0, display: "flex", overflow: "hidden" }}>
        <CodeEditor
          tabSize={2}
          fontSize={14}
          style={{ flex: 2 }}
          height="480px"
          width="100%"
          mode={mode}
          theme="clouds"
          value={_code}
          onChange={(val: any) => setCode(val)}
        />
        {Preview}
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={() => {
            close();
            setCode(props.code || "");
          }}
        >
          cancel
        </Button>
        <Button
          onClick={() => {
            onSave(_code);
            close();
          }}
          positive
          icon="checkmark"
          labelPosition="right"
          content="save"
        />
      </Modal.Actions>
    </Modal>
  );
}

export default CodeModal;
