import { Alert } from "@au-re/mosaik-elements";
import shortid from "shortid";
import get from "lodash.get";

import { db, storage, auth } from "../configureFirebase";
import { hasCorrectImageFormat, isLt2M, requestStart, requestSuccess, requestError, requestTransfer } from "../../helpers/utils";
import { getUserIdByEmail } from "./user.requests";
import collections, { asset2Collection } from "../../constants/database/collections";
import permissions from "../../constants/types/permissionTypes";

/**
 * Update the title of an asset
 *
 * @param {string} assetType
 * @param {string} id
 * @param {string} title
 */
export async function updateAssetTitle(assetType: string, id: string, title: string) {
  if (!assetType || !id) throw new Error("missing props");
  const metaCollection = asset2Collection[assetType].meta;
  await db.collection(metaCollection).doc(id).update({ title });
}

/**
 * Upload a thumbnail for an asset
 *
 * @param {*} assetType
 * @param {*} assetId
 * @param {*} file
 * @param {*} options
 */
export function uploadAssetThumbnail(assetType: string, assetId: string, file: any) {
  if (!hasCorrectImageFormat) return Alert.error("You can only upload JPG, GIF or PNG files");
  if (!isLt2M) return Alert.error("Image must be smaller than 2MB");

  requestStart();

  const metaCollection = asset2Collection[assetType].meta;
  const ref = storage.ref(`/${assetType}/${assetId}/images/thumbnail.jpg`);
  const task = ref.put(file);
  const metaRef = db.collection(metaCollection).doc(assetId);

  const onSuccess = async () => {
    try {
      const downloadURL = await ref.getDownloadURL();
      await metaRef.set({ thumbnailURL: downloadURL }, { merge: true });
      requestSuccess({
        notification: "new thumbnail uploaded",
      });
    } catch (error) {
      requestError({ error });
    }
  };

  task.on("state_changed", requestTransfer, requestError, onSuccess);
}

/**
 * Set the resource to public (accessible for everyone)
 *
 * TODO: verification of ownership should not happen in the client
 *
 * @param {string} assetType
 * @param {string} id
 * @param {boolean} isPublic
 */
export async function makeAssetPublic(assetType: string, id: string, isPublic: boolean) {
  requestStart();
  try {
    if (!assetType || !id) throw new Error("missing props");
    if (!auth.currentUser) throw new Error("user not logged in");

    const { uid } = auth.currentUser;
    const metaCollection = asset2Collection[assetType].meta;
    const ref = db.collection(metaCollection).doc(id);
    const doc = await ref.get();
    const data: any = doc.data();

    if (data.permissions[uid] !== permissions.OWNER) {
      throw new Error("You need to be an owner to edit access");
    }

    await ref.set({ isPublic }, { merge: true });
    requestSuccess();
    return true;
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Updating permissions for an asset
 *
 * - only an owner can update permission rights
 * - there must always be at least one owner per resource
 *
 * @param {*} assetType
 * @param {*} id
 * @param {*} email
 * @param {*} permission
 */
async function updateAssetPermissions(
  assetType: string, id: string, userId: string, permission: any = null) {

  if (!assetType || !id) throw new Error("missing props");
  if (!auth.currentUser) throw new Error("user not logged in");

  const { uid } = auth.currentUser;
  const metaCollection = asset2Collection[assetType].meta;
  const ref = db.collection(metaCollection).doc(id);
  const doc = await ref.get();
  const data: any = doc.data();

  if (data.permissions[uid] !== permissions.OWNER) {
    throw new Error("You need to be an owner to edit permissions");
  }

  const otherPermissions = { ...data.permissions };
  delete otherPermissions[userId];

  // can only revoke owner privileges if there is at least another owner
  if (permission !== permissions.OWNER
    && uid === userId
    && !Object.values(otherPermissions).includes(permissions.OWNER)) {
    throw new Error("There needs to be at least one owner for this project");
  }

  await ref.update({ [`permissions.${userId}`]: permission });
  return true;
}

/**
 * Update the permissions to an asset by a user given an email
 *
 * @param {*} assetType
 * @param {*} id
 * @param {*} email
 * @param {*} permission
 */
export async function updateAssetPermissionByEmail(
  assetType: string, id: string, email: string, permission = null) {

  requestStart();
  try {
    const userId = await getUserIdByEmail(email);
    if (!userId) throw new Error("no_such_user");
    const res = await updateAssetPermissions(assetType, id, userId, permission);
    requestSuccess();
    return res;
  } catch (error) {
    requestError({ error, showNotification: true });
  }
}

/**
 * Update an access token that can be used to share the project by link
 *
 * @param {*} projectId
 */
export async function updateAssetAccessToken(assetType: string, id: string) {
  if (!assetType || !id) throw new Error("missing props");
  const metaCollection = asset2Collection[assetType].meta;
  const projectRef = db.collection(metaCollection).doc(id);
  return projectRef.update({ accessToken: shortid.generate() });
}

/**
 * Returns the users an asset has been shared with (and their permissions)
 *
 * @param {string} assetType
 * @param {string} id
 */
export async function fetchUsersWithAccess(assetType: any, id: any) {
  if (!assetType || !id) throw new Error("missing props");

  const metaCollection = asset2Collection[assetType].meta;
  const userRef = db.collection(collections.USERS);
  const ref = db.collection(metaCollection);
  const resource: any = await ref.doc(id).get();
  const users: any = [];
  const permissions: any = [];

  Object.keys(get(resource.data(), "permissions", [])).forEach((key) => {
    users.push(userRef.doc(key).get());
    permissions.push(resource.data().permissions[key]);
  });

  return Promise.all(users).then((users) => users
    .map((u: any, idx: number) => ({
      permission: permissions[idx],
      id: u.id,
      ...u.data(),
    }))
    .filter((u) => u.permission));
}

/**
 * Update the description of an asset
 *
 * @param {string} assetType
 * @param {string} id
 * @param {string} description
 */
export async function updateAssetDescription(assetType: string, id: string, description: string) {
  requestStart();
  try {
    if (!assetType || !id) throw new Error("missing props");
    const metaCollection = asset2Collection[assetType].meta;
    await db.collection(metaCollection).doc(id).update({ description });
    requestSuccess();
  } catch (error) {
    requestError({ error });
  }
}

/**
 * Update the category of an asset
 *
 * @param {string} assetType
 * @param {string} id
 * @param {string} category
 */
export async function updateAssetCategory(assetType: string, id: string, category: string) {
  requestStart();
  try {
    if (!assetType || !id) throw new Error("missing props");
    const metaCollection = asset2Collection[assetType].meta;
    await db.collection(metaCollection).doc(id).update({ category });
    requestSuccess();
  } catch (error) {
    requestError({ error });
  }
}
