import React from "react";
import { Page, colors } from "@au-re/mosaik-elements";
import { useSelector } from "react-redux";

import Header from "../../elements/Header/Header";
import TilePreview from "../../tile/TilePreview/TilePreview";
import { getCurrentUserId } from "../../../state-management/selectors/user.selectors";
import useNavigation from "../../../hooks/useNavigation";
import useTileData from "../../../hooks/useTileData";
import permissions from "../../../constants/types/permissionTypes";
import useAssetRestriction from "../../../hooks/useAssetRestriction";

const defaultDescription = "No description has been added yet";

// a page showing tile details
function TilePreviewPage() {
  const userId = useSelector(getCurrentUserId);
  const { assetId, goToTileList, goToTileEditor } = useNavigation();
  const { tile, hasFetched }: any = useTileData(assetId);

  useAssetRestriction(tile, hasFetched, false, goToTileList);

  if (!hasFetched || !tile) return null;

  // TODO: replace with selector
  const canEdit =
    userId && tile.permissions &&
    (tile.permissions[userId] === permissions.OWNER ||
      tile.permissions[userId] === permissions.EDIT);

  return (
    <Page>
      <Page.Body>
        <Header
          title={tile.title}
          goToEditor={() => goToTileEditor(tile.id)}
          withEditor={canEdit}
          noFooter
        />
        <Page.Content style={{
          background: colors.background,
          borderTop: `1px solid ${colors.border}`,
        }}
        >
          <TilePreview
            thumbnailURL={tile.thumbnailURL}
            description={tile.description || defaultDescription}
            category={tile.category}
            documentation={tile.documentation}
          />
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default TilePreviewPage;
