import React from "react";
import { useSelector } from "react-redux";

import { getCurrentUserId } from "../state-management/selectors/user.selectors";
import { createAccessToken } from "../state-management/requests/auth.requests";

/**
 * logic that allows a module to authenticate itself with mosaik to its backend
 *
 * @param {*} moduleId
 * @param {*} onAuthChange
 */
function useRemoteAuth(moduleId: string, setAuth?: (options: any) => void, projectId?: string) {
  const userId = useSelector(getCurrentUserId);

  // connection if remote auth is enabled, auth to the module backend
  React.useEffect(() => {
    if (!setAuth) return;
    if (moduleId && userId) {
      (async () => {
        const res = await createAccessToken(moduleId, projectId);

        if (!res.accessToken) return;

        setAuth({
          accessToken: res.accessToken,
          moduleId,
          currentUser: userId,
        });
      })();
    }
  }, [setAuth, moduleId, userId, projectId]);
}

export default useRemoteAuth;
