import get from "lodash.get";

export function getModuleConnection(nodeId: string) {
  return (state: any) => {
    return get(state, `projectEditor.connections[${nodeId}]`, false);
  };
}

export function getSelectedModule(state: any): string | null {
  return get(state, `projectEditor.selectedModule`, null);
}
