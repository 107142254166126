import { SET_MODULE_CONNECTION, RESET_PROJECT_EDITOR } from "../actions/projectEditor.actions";

const initialState = {};

/**
 * This reducer tracks the currently connected modules
 * @param state
 * @param action
 */
function projectConnectionsReducer(state: any = initialState, action: any) {

  if (action.type === RESET_PROJECT_EDITOR) return initialState;

  if (action.type === SET_MODULE_CONNECTION) {
    const { moduleId, isConnected } = action.payload;
    return {
      ...state,
      [moduleId]: isConnected,
    };
  }

  return state;
}

export default projectConnectionsReducer;
